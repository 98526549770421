<template>
    <board :span="24"
           :span_responsive="[ 24, 24 ]" title="Create Appointment" height="auto"
           v-if="$store.state.permissions.view_appointment">
        <div class="col-md-12 margin-bottom-10 p-0" v-if="$store.state.permissions.add_appointment">
            <el-button @click="createModal=true" class="save-updates-button pull-right">
                {{ $root.translate('add_new_appointment') }}
            </el-button>
        </div>
        <b-modal v-model="createModal" id="create_appointment_modal" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ $root.translate('create_appointment') }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <createAppointment></createAppointment>
            </div>
        </b-modal>
        <b-modal v-model="editModal" id="create_appointment_modal" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> Edit Appointment </h4>
            </div>
            <div class="col-md-12 p-0">
                <createAppointment :appointment="selectedAppointment"></createAppointment>
            </div>
        </b-modal>
        <b-modal v-model="viewModal" size="xl" hide-footer id="view_appointment_modal"
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> View Appointment</h4>
            </div>
            <viewAppointment :selectedAppointment="selectedAppointment"></viewAppointment>
        </b-modal>

        <b-modal v-model="statusModal" size="md" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ $root.translate('status') }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-12">
                        <label class="col-md-3 col-form-label title-label">Status :</label>
                        <div class="col-md-1">
                            <el-radio v-model="selectedAppointment.status" label="Upcoming">Upcoming</el-radio>
                            <el-radio v-model="selectedAppointment.status" label="Pending Approval">Pending Approval
                            </el-radio>
                            <el-radio v-model="selectedAppointment.status" label="In Progress">In Progress</el-radio>
                            <el-radio v-model="selectedAppointment.status" label="Executed">Executed</el-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <el-button class="clear-button"
                           @click="closeModal"> Close
                </el-button>

                <el-button class="save-updates-button pull-right"
                           @click="saveStatus"> Save Updates
                </el-button>
            </div>
        </b-modal>

        <div class="col-md-12 with-dropdown-filter-button">
            <div class="grid-filter-trigger" @click="filterTrigger">
                <i :class="floating_filter?'fas fa-filter':'far fa-filter'"></i>
            </div>
        </div>

        <ag-grid-vue :pagination="true"
                     :localeText="$store.state.translations"
                     :enableRtl="$store.state.ag_grid_rtl"
                     :grid-options="gridOptions"
                     rowHeight="60"
                     :maxBlocksInCache="10"
                     :cacheBlockSize="10"
                     @grid-ready="onGridReady"
                     :floatingFilter="floating_filter"
                     :frameworkComponents="frameworkComponents"
                     :paginationPageSize="10"
                     class="ag-theme-material"
                     :columnDefs="columnDefs"
                     :rowData="appointments">
        </ag-grid-vue>
    </board>
</template>

<script>
import board from "@/components/CommonComponents/Board";
import createAppointment from "@/components/appointment/create";
import {AgGridVue} from 'ag-grid-vue/main';
import optionsCell from '@/components/CommonComponents/agGrid/AgGridActionsCell.vue';
import viewAppointment from './view'

export default {
    data() {
        return {
            statusModal: false,
            createModal: false,
            viewModal: false,
            editModal: false,
            appointments: [],
            selectedAppointment: {},
            floating_filter: false,
            frameworkComponents: null,
            gridApi: {},
            gridOptions: {
                rowModelType: 'serverSide',
                serverSideStoreType: 'partial',
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                },
                components: {
                    'optionsCell': this.optionsCell,
                    'cancelAppointment': this.cancelAppointment,
                    'statusField': this.statusField,
                }
            },
            columnDefs: [],
        }
    },
    mounted() {
        this.columnDefs = [
            {
                "headerName": this.$root.translate('branch'),
                "field": 'branch',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.branch
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.branch
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('department'),
                "field": 'department',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.department
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.department
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('doctor'),
                "field": 'doctor',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.doctor
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.doctor
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('date'),
                "field": 'date_field',
                "cellRenderer": function (params) {
                    if (params.data)
                        return params.data.date_field;
                },
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.date_field
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.date_field
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('option'),
                "field": 'doctor_options',
                "cellRenderer": function (params) {
                    if (params.data)
                        return params.data.doctor_options;
                },
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.doctor_options
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.doctor_options
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('status'),
                "field": 'status',
                cellRenderer: 'statusField',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.status
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.status
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                headerName: this.$root.translate('action'),
                cellRendererFramework: optionsCell,
                cellRendererParams: {
                    props: [
                        {
                            value: 'view',
                            type: 'action',
                            onClick: this.viewAppointment
                        },
                    ]
                }
            }
        ]
        if (this.$store.state.permissions.edit_appointment) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'edit',
                    type: 'action',
                    onClick: this.editAppointment
                }
            )
        }
        if (this.$store.state.permissions.cancel_appointment) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'cancel',
                    type: 'action',
                    onClick: this.cancelAppointment
                }
            )
        }

        if (this.$store.state.permissions.change_task_status) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'update_status',
                    type: 'action',
                    onClick: this.updateStatus
                }
            )
        }
        if (this.$store.state.permissions.delete_appointment) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'archive',
                    type: 'action',
                    onClick: this.archiveAppointment
                }
            )
        }

        this.$forceUpdate()
    },
    components: {
        board,
        AgGridVue,
        createAppointment,
        viewAppointment
    },
    methods: {
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            let datasource = this.dataSource();
            params.api.setServerSideDatasource(datasource);
        },
        dataSource() {
            let that = this;
            return {
                getRows(params) {
                    let filter = {};
                    if (params.request.filterModel) {
                        let keys = Object.keys(params.request.filterModel)
                        keys.forEach(key => {
                            filter[key] = params.request.filterModel[key].filter
                        })
                    }
                    let gridSort = []
                    if (params.request.sortModel) {
                        gridSort = params.request.sortModel
                    }
                    let api = "/appointment" + "?filter=" + JSON.stringify(filter) + "&sort=" + JSON.stringify(gridSort)

                    that.$store.dispatch('getAction', api).then(response => {
                        if (response.code === 200) {
                            that.tasks = response.data
                            setTimeout(function () {
                                params.successCallback(response.data.data, response.data.total);
                            }, 100)

                        }
                    })
                }
            }
        },
        closeModal() {
            this.statusModal = false
        },
        saveStatus() {
            let task = {
                status: this.selectedAppointment.status,
                api: "/appointment/" + this.selectedAppointment.id,
                json_object: true
            }
            this.$store.dispatch("postAction", task).then(response => {
                if (response.code === 200) {
                    let count = 0
                    for (let i = 0; i < this.appointments.length; i++) {
                        if (response.data.id === this.appointments[i].id) {
                            this.appointments[i] = response.data
                            this.$forceUpdate()
                        }
                        if (this.appointments[i].status !== 'Done') {
                            count++
                        }
                        this.$store.state.appointmentCount = count
                    }
                    this.gridOptions.api.redrawRows();
                    this.closeModal()
                }
            })
        },
        archiveAppointment(params, data) {
            this.$swal({
                icon: 'warning',
                text: 'Do you want to delete an appointment with' + data.doctor,
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    let request = {
                        archived: true,
                        api: "/appointment/" + data.id,
                        json_object: true
                    }
                    this.$store.dispatch('postAction', request).then(response => {
                        if (response.code === 200) {
                            let datasource = this.dataSource();
                            this.gridOptions.api.setServerSideDatasource(datasource);
                            this.$bvModal.hide('create_appointment_modal');
                        }
                    })
                }
            })
        },
        editAppointment(params, data) {
            this.selectedAppointment = data
            this.editModal = true
        },
        updateStatus(option, data, key, type) {
            this.selectedAppointment = data
            this.statusModal = true
            console.log(option, data, key, type)
        },
        viewAppointment(params, data) {
            console.log(params, data)
            this.selectedAppointment = data
            this.viewModal = true
        },
        statusField(params) {
            if (!params.data) {
                return
            }
            if (params.data.status === 'Executed') {
                return '<button type="button" class="el-button el-button--success" style="width: 130px;"><span style="color: white">' + params.data.status + '</span></button>'
            } else if (params.data.status === 'Cancelled') {
                return '<button type="button" class="el-button el-button--danger" style="width: 130px;"><span>' + params.data.status + '</span></button>'
            } else {
                return '<button type="button" class="el-button el-button--warning" style="width: 130px;"><span style="color: white">' + params.data.status + '</span></button>'
            }
        },
        sendReminder() {

        },
        cancelAppointment(params, data) {
            if (data.request_status === 'Cancelled')
                return
            this.$swal({
                icon: 'warning',
                text: 'Do you want to cancel this?',
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    let request = {
                        id: data.id,
                        api: "/appointment/cancel",
                        json_object: true
                    }
                    this.$store.dispatch('updateAction', request).then(response => {
                        if (response.code === 200) {
                            this.$store.state.appointmentCount--
                            for (let i = 0; i < this.appointments.length; i++) {
                                if (this.appointments[i].id === data.id) {
                                    this.appointments[i].status = "Cancelled"
                                    this.gridOptions.api.redrawRows();
                                    this.$forceUpdate();
                                }
                            }
                        }
                    })
                }
            })
        },
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
    },
}
</script>
<style lang="scss">
.margin-bottom-10 {
    margin-bottom: 10px;
}
</style>