<template>
    <board :span="24"
           :span_responsive="[ 24, 24 ]" :title="boardTitle" height="auto">
        <div class="row" style="width: 100%">
            <div class="col-md-3" style="text-align: center;">
                <div class="col-md-12 p-0">
                    <a href="javascript:;" @click="$router.push('/branch/'+$route.params.branch_id+'/patients/create')">
                        <img src="/images/appointment-icon.png" alt="Create Patient" title="Create Patient"
                             height="100%"
                             width="200px">
                    </a>
                </div>
                <div class="col-md-12 p-0">
                    <h4>Create Patient</h4>
                </div>
            </div>
            <div class="col-md-3" style="text-align: center;">
                <div class="col-md-12 p-0">
                    <a href="javascript:;"
                       @click="$router.push('/branch/'+$route.params.branch_id+'/patients/create?lang=ar')">
                        <img src="/images/appointment-icon.png" alt="إنشاء ملف" title="إنشاء ملف"
                             height="100%"
                             width="200px">
                    </a>
                </div>
                <div class="col-md-12 p-0">
                    <h4>إنشاء ملف</h4>
                </div>
            </div>
            <div class="col-md-3" style="text-align: center;">
                <div class="col-md-12 p-0">
                    <a href="javascript:;" @click="$router.push('/branch/'+$route.params.branch_id+'/patients')">
                        <img src="/images/appointment-icon.png" alt="Patients" title="List Patients"
                             height="100%"
                             width="200px">
                    </a>
                </div>
                <div class="col-md-12 p-0">
                    <h4>List Patients</h4>
                </div>
            </div>
        </div>
    </board>
</template>
<script>

import board from "@/components/CommonComponents/Board";

export default {
    data() {
        return {
            boardTitle: '',
            branchList: []
        }
    },
    components: {
        board
    }
}
</script>