<template>
    <div class="text-center">
        <el-dropdown trigger="click">
            <el-button icon="fas fa-ellipsis-v" type="plain" size="mini"></el-button>
            <el-dropdown-menu slot="dropdown" >
                <el-dropdown-item
                        v-for="(option,key) in params.props"
                        :key="key"
                        @click.native.prevent="onClick(option,params.node.data,params.rowIndex)">
                    <i v-if="option.icon" :class="option.icon"></i>
                    <icon v-else-if="option.svg_icon" :icon="option.svg_icon" size="19"></icon>
                    {{option.value?$root.translate(option.value):''}}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        methods: {
            onClick(option, data, key) {
                option.onClick(option.value, data, key, option.type);
            }
        }
    });
</script>
<style scoped lang="scss">
    .el-dropdown-menu {
        i {
            font-size: 15px;
        }

        icon {
            margin-right: 8px;
        }
    }
</style>
