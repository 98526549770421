<template>
    <div class="row">
        <div class="col-md-2">
            <div class="icon-container" style="text-align: center">
                <img width="150px" height="200px" :src="fileURL!==''?fileURL:'/icons/user-default.svg'" alt="">
            </div>
            <div class="col-md-12">
                <el-upload
                    style="text-align: center"
                    :on-change="changeProfile"
                    :show-file-list="false"
                    :multiple="false"
                    ref="cv_uploader"
                    class="upload-demo"
                    action="#"
                    :auto-upload="false"
                    accept="image/*">
                    <el-button size="small" type="primary">Upload Profile</el-button>
                </el-upload>
            </div>
        </div>
        <div class="col-md-10">
            <div class="form-group" v-if="refreshForm">
                <div class="col-md-12" :class="{'has-error': errors.has('name')}">
                    <label class="col-md-4 col-form-label title-label">Full Name : <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="name" v-model="model.name" v-validate="'required'"></el-input>
                        <span class="help-block" v-if="errors.has('name')">
                        {{ $root.translate('this_field_is_required') }}
                    </span>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="refreshForm">
                <div class="col-md-12" :class="{'has-error': errors.has('speciality')}">
                    <label class="col-md-4 col-form-label title-label">Speciality : <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="speciality" v-model="model.speciality" v-validate="'required'"></el-input>
                        <span class="help-block" v-if="errors.has('speciality')">
                        {{ $root.translate('this_field_is_required') }}
                    </span>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="refreshForm">
                <div class="col-md-12" :class="{'has-error': errors.has('email')}">
                    <label class="col-md-4 col-form-label title-label">Email : <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="email" v-model="model.email" v-validate="'required|email'"></el-input>
                        <span class="help-block" v-if="errors.has('email')">
                            {{ errors.first('email') }}
                    </span>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="refreshForm">
                <div class="col-md-12">
                    <label class="col-md-4 col-form-label title-label">Branch :</label>
                    <div class="col-md-8">
                        <multiselect name="branch" v-model="model.branch" :options="branches"
                                     placeholder="Select branch.."
                                     label="title"></multiselect>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="refreshForm">
                <div class="col-md-12" :class="{'has-error': errors.has('password')}">
                    <label class="col-md-4 col-form-label title-label">Password : <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="password" type="password" v-model="model.password" v-validate="'required|min:8'"
                                  ref="password"></el-input>
                        <span class="help-block" v-if="errors.has('password')">
                        {{
                                model.password === '' ? $root.translate('this_field_is_required') : 'Password must be at least 8 characters'
                            }}
                    </span>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="refreshForm">
                <div class="col-md-12" :class="{'has-error': errors.has('confirm_password')}">
                    <label class="col-md-4 col-form-label title-label">Confirm Password : <em>*</em></label>
                    <div class="col-md-8">
                        <el-input v-validate="'required|confirmed:password'" name="confirm_password" type="password"
                                  v-model="model.confirm_password"></el-input>
                        <span class="help-block" v-if="errors.has('confirm_password')">
                        {{
                                model.confirm_password === '' ? $root.translate('this_field_is_required') : "Confirm password doesn't match password"
                            }}
                    </span>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
<!--                <el-button class="clear-button" v-if="model.id"-->
<!--                           @click="closeModal"> Deactivate-->
<!--                </el-button>-->

                <el-button class="save-updates-button pull-right"
                           @click="createUser"> Save Updates
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import * as VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
    fieldsBagName: 'veeFields'
});
export default {
    data() {
        return {
            refreshForm: true,
            fileURL:'',
            model: {
                name: '',
                email: '',
                password: '',
                confirm_password: '',
                branch: '',
            },
            branches: [],
            fileList:[]
        }
    },
    mounted() {
        this.$store.dispatch('getAction', "/branch").then(response => {
            if (response.code === 200) {
                this.branches = response.data
            }
        })
    },
    methods: {

        changeProfile(file, fileList) {
            console.log(file, fileList);
            this.fileList = [file]
            this.fileURL=URL.createObjectURL(file.raw)
            this.$forceUpdate()
        },

        createUser() {
            this.$validator.validate('name', this.model.name);
            this.$validator.validate('email', this.model.email);
            this.$validator.validate('speciality', this.model.speciality);
            this.$validator.validate('password', this.model.password);
            this.$validator.validate('confirm_password', this.model.confirm_password);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['name', 'speciality', 'email', 'password', 'confirm_password']);
                if (valid) {
                    // let request = {
                    //     name: this.model.name,
                    //     speciality: this.model.speciality,
                    //     profile:this.fileList[0],
                    //     email: this.model.email,
                    //     password: this.model.password,
                    //     branch: this.model.branch ? this.model.branch.title : '',
                    //     c_password: this.model.confirm_password,
                    //     api: "/register",
                    //     json_object: true
                    // }
                    let formData=new FormData()
                    formData.set('name', this.model.name)
                    formData.set('speciality', this.model.speciality)
                    formData.set('email', this.model.email)
                    formData.set('password', this.model.password)
                    formData.set('branch', this.model.branch ? this.model.branch.title : '')
                    formData.set('c_password', this.model.confirm_password)
                    formData.set('api', '/register')

                    if(this.fileList.length){
                      formData.append('profile[]', this.fileList[0].raw)
                      formData.append('profileFilesNames[]', this.fileList[0].name)
                    }
                    this.$store.dispatch('postAction', formData).then(response => {
                        if (response.code === 200) {
                            this.$parent.$parent.$parent.$parent.users.push(response.data)
                            this.$swal({
                                icon: 'info',
                                type: 'info',
                                text: 'Successfully created',
                                showCancelButton: false,
                                confirmButtonText: 'OK',
                            });
                            this.model = {
                                name: '',
                                email: '',
                                password: '',
                                confirm_password: '',
                            }
                            this.refreshForm = false
                            this.$nextTick(() => {
                                this.refreshForm = true
                            })

                            this.$bvModal.hide('register_modal_id');
                        }
                    }).catch(() => {
                        this.$swal.fire({
                            icon: 'error',
                            type: 'error',
                            title: 'Registration Failed',
                            text: "Email already exists",
                            allowOutsideClick: true,
                            allowEscapeKey: true
                        });

                        this.model.email = ''
                    });
                }
            });
        },
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
    },
}
</script>
<style lang="scss">
.el-upload--picture-card{
    height: 30px !important;
    i{

        display: block !important;
        text-align: center !important;
    }
}
</style>