<template>
    <board v-if="$store.state.permissions.view_announcements" :span="24" :span_responsive="[ 24, 24 ]" height="auto"
           title="Announcements">
        <div v-if="$store.state.permissions.add_announcements" class="col-md-12 margin-bottom-10 p-0">
            <el-button @click="addNewAnnouncement" class="save-updates-button pull-right">
                Add Announcement
            </el-button>
        </div>
        <div class="col-md-12 with-dropdown-filter-button">
            <div class="grid-filter-trigger" @click="filterTrigger">
                <i :class="floating_filter?'fas fa-filter':'far fa-filter'"></i>
            </div>
        </div>
        <ag-grid-vue :pagination="true"
                     :localeText="$store.state.translations"
                     :enableRtl="$store.state.ag_grid_rtl"
                     :grid-options="gridOptions"
                     rowHeight="60"
                     :maxBlocksInCache="10"
                     :cacheBlockSize="10"
                     @grid-ready="onGridReady"
                     :floatingFilter="floating_filter"
                     :frameworkComponents="frameworkComponents"
                     :paginationPageSize="10"
                     class="ag-theme-material"
                     :columnDefs="columnDefs"
                     :rowData="announcements">
        </ag-grid-vue>
        <b-modal v-model="createModal" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ model.id ? "Update Announcement" : "Create Announcement" }} </h4>
            </div>

            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-6" :class="{'has-error': errors.has('title')}">
                        <label class="col-md-4 col-form-label title-label">Title : <em>*</em></label>
                        <div class="col-md-8">
                            <el-input name="title" v-model="model.title" v-validate="'required'"></el-input>
                            <span class="help-block" v-if="errors.has('title')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Applicant :</label>
                        <div class="col-md-8">
                            {{ user.name }}
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6" :class="{'has-error': errors.has('doctor_name')}">
                        <label class="col-md-4 col-form-label title-label">Add Doctor : <em>*</em></label>
                        <div class="col-md-8">
                            <multiselect name="doctor_name" v-model="model.doctor_name" :options="doctorsList"
                                         v-validate="'required'" label="full_name" track-by="id"
                                         placeholder="Select .."></multiselect>
                            <span class="help-block" v-if="errors.has('doctor_name')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>

                    <div class="col-md-6" :class="{'has-error': errors.has('expired_in')}">
                        <label class="col-md-4 col-form-label title-label"> Expired In : <em>*</em></label>
                        <div class="col-md-8">
                            <el-date-picker
                                name="expired_in"
                                format="dd-MM-yyyy"
                                value-format="dd-MM-yyyy"
                                :picker-options="pickerOptions"
                                v-model="model.expired_in"
                                v-validate="'required'"
                                type="date">
                            </el-date-picker>
                            <span class="help-block" v-if="errors.has('expired_in')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-12">
                        <label class="col-md-2 col-form-label title-label"> Description :</label>
                        <div class="col-md-10">
                            <el-input type="textarea" :autosize="{ minRows: 4}"
                                      v-model="model.description"></el-input>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Creation Date :</label>
                        <div class="col-md-8">
                            {{ new Date().toLocaleString() }}
                        </div>
                    </div>
                    <div class="col-md-6 p-0">
                        <label class="col-md-4 col-form-label title-label">Status :</label>
                        <div class="col-md-8">
                            <el-button type="warning">Upcoming</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <el-button class="clear-button" v-if="model.id && $store.state.permissions.delete_announcements"
                           @click="archiveAnnouncement"> Archive
                </el-button>
                <el-button class="clear-button"
                           @click="cancelDoctorCreation"> Clear
                </el-button>

                <el-button class="save-updates-button pull-right"
                           @click="updateAnnouncement"> {{ model.id ? "Save Updates" : "Create" }}
                </el-button>
            </div>
        </b-modal>
        <b-modal v-model="viewModal" size="xl" hide-footer id="view_announcement_modal"
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> View Announcement </h4>
            </div>
            <viewAnnouncement :selectedAnnouncement="selectedAnnouncement"></viewAnnouncement>
        </b-modal>
    </board>
</template>

<script>
import board from "@/components/CommonComponents/Board";
import {AgGridVue} from "ag-grid-vue";
import optionsCell from '@/components/CommonComponents/agGrid/AgGridActionsCell.vue';
import Vue from "vue";
import viewAnnouncement from './view'

export default {
    data() {
        return {
            selectedAnnouncement: {},
            viewModal: false,
            model: {},
            users: [],
            pickerOptions: {
                disabledDate(time) {
                    const today = new Date()
                    const tomorrow = new Date(today)
                    tomorrow.setDate(tomorrow.getDate() - 1)
                    return time.getTime() < tomorrow || time.getDay() == 5;
                }
            },
            doctorsList: [],
            user: Vue.$cookies.get('user'),
            createModal: false,
            floating_filter: false,
            frameworkComponents: null,
            gridApi: {},
            gridOptions: {
                rowModelType: 'serverSide',
                serverSideStoreType: 'partial',
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                }, components: {
                    'optionsCell': this.optionsCell,
                    'statusField': this.statusField,
                }
            },
            columnDefs: [],
            announcements: []
        }
    },
    mounted() {
        this.columnDefs = [
            {
                "headerName": "#",
                "width": 60,
                "cellRenderer": function (params) {
                    if (params.data)
                        return params.rowIndex + 1;
                },
                "sortable": false,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('title'),
                "field": 'title',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.title
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.title
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Applicant',
                "field": 'applicant',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.applicant
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.applicant
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Doctor Name',
                "field": 'doctor_name',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.doctor_name
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.doctor_name
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('created_at'),
                "field": 'created_at',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.created_at
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.created_at
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Expired In',
                "field": 'expired_in',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.expired_in
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.expired_in
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('status'),
                "field": 'status',
                cellRenderer: 'statusField',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.status
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.status
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                headerName: this.$root.translate('action'),
                field: 'id',
                width: 100,
                cellRendererFramework: optionsCell,
                cellRendererParams: {
                    props: [
                        {
                            value: 'view',
                            type: 'action',
                            onClick: this.view
                        }
                    ]
                },
                "suppressMenu": true
            }
        ]
        if (this.$store.state.permissions.edit_announcements) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'edit',
                    type: 'action',
                    onClick: this.enableEdit
                }
            )
        }
        if (this.$store.state.permissions.send_announcement_reminder) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'send_reminder',
                    type: 'action',
                    onClick: this.sendReminder
                }
            )
        }
        if (this.$store.state.permissions.cancel_announcement) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'cancel',
                    type: 'action',
                    onClick: this.cancelAnnouncementRequest
                }
            )
        }
        if (this.$store.state.permissions.delete_announcements) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'archive',
                    type: 'action',
                    onClick: this.archiveAction
                }
            )
        }

        this.$store.dispatch('getAction', "/users").then(response => {
            if (response.code === 200) {
                this.users = response.data
            }
        })
        this.$store.dispatch('getAction', "/doctor").then(response => {
            if (response.code === 200) {
                this.doctorsList = response.data
            }
        })
    },
    components: {
        board,
        AgGridVue,
        viewAnnouncement
    },
    methods: {
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            let datasource = this.dataSource();
            params.api.setServerSideDatasource(datasource);
        },
        dataSource() {
            let that = this;
            return {
                getRows(params) {
                    let filter = {};
                    if (params.request.filterModel) {
                        let keys = Object.keys(params.request.filterModel)
                        keys.forEach(key => {
                            filter[key] = params.request.filterModel[key].filter
                        })
                    }
                    let gridSort = []
                    if (params.request.sortModel) {
                        gridSort = params.request.sortModel
                    }
                    let api = "/announcements" + "?filter=" + JSON.stringify(filter) + "&sort=" + JSON.stringify(gridSort)

                    that.$store.dispatch('getAction', api).then(response => {
                        if (response.code === 200) {
                            that.announcements = response.data.data
                            setTimeout(function () {
                                params.successCallback(response.data.data, response.data.total);
                            }, 100)

                        }
                    })
                }
            }
        },
        archiveAction(option, data, key, type) {
            console.log(option, data, key, type)
            this.model = data
            this.archiveAnnouncement()
        },
        statusField(params) {
            if(!params.data){
                return
            }
            if (params.data.status === 'Executed') {
                return '<button type="button" class="el-button el-button--success" style="width: 130px;"><span style="color: white">' + params.data.status + '</span></button>'
            } else if (params.data.status === 'Cancelled') {
                return '<button type="button" class="el-button el-button--danger" style="width: 130px;"><span>' + params.data.status + '</span></button>'
            } else {
                return '<button type="button" class="el-button el-button--warning" style="width: 130px;"><span style="color: white">' + params.data.status + '</span></button>'
            }
        },
        sendReminder(option, data, key, type) {
            console.log(option, data, key, type)
            let applicant = this.$root.getObjectByKey('name', data.applicant, this.users)

            let request = {
                type: "announcement",
                id: data.id,
                json_object: true,
                title: data.title,
                applicant_id: applicant.user_id,
                expired_in: data.expired_in,
                api: "/announcements/" + data.id + "/reminder"
            }

            this.$store.dispatch("postAction", request).then(response => {
                if (response.code === 200) {
                    this.$swal({
                        icon: 'info',
                        text: 'Notifications had sent to all users.',
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                    })
                }
            })
        },
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
        cancelAnnouncementRequest(option, data, key, type) {
            if (data.status === "Cancelled") {
                return
            }
            console.log(option, data, key, type)
            this.$swal({
                icon: 'warning',
                text: 'Do you want to cancel ' + data.title + '?',
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    let request = {
                        status: "Cancelled",
                        api: "/announcements/" + data.id,
                        json_object: true
                    }
                    this.$store.dispatch("postAction", request).then(response => {
                        if (response.code === 200) {
                            this.$store.state.announcementsCount--
                            let datasource = this.dataSource();
                            this.gridOptions.api.setServerSideDatasource(datasource);
                        }
                    })
                }
            })
        },
        view(option, data, key, type) {
            console.log(option, data, key, type)
            this.selectedAnnouncement = data
            this.viewModal = true
        },
        enableEdit(option, data, key, type) {
            console.log(option, data, key, type)
            this.model = JSON.parse(JSON.stringify(data))
            if (data.doctor_name) {
                this.model.doctor_name = this.$root.getObjectByKey('full_name', this.model.doctor_name, this.doctorsList)
            }
            this.createModal = true
            this.$forceUpdate();
        },

        addNewAnnouncement() {
            this.createModal = true
            this.model = {}
        },
        archiveAnnouncement() {
            this.$swal({
                icon: 'warning',
                text: 'Do you want to delete this announcement ' + this.model.title,
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    let request = {
                        archived: true,
                        api: "/announcements/" + this.model.id,
                        json_object: true
                    }
                    this.$store.dispatch('postAction', request).then(response => {
                        console.log(response)
                        this.$store.state.announcementsCount--
                        let datasource = this.dataSource();
                        this.gridOptions.api.setServerSideDatasource(datasource);
                    })
                    this.cancelDoctorCreation()
                }
            })
        },
        cancelDoctorCreation() {
            this.model = {}
            this.selectedAnnouncement = {}
            this.createModal = false
            this.$forceUpdate()
        },
        updateAnnouncement() {
            this.$validator.validate('title', this.model.title);
            this.$validator.validate('expired_in', this.model.expired_in);
            this.$validator.validate('doctor_name', this.model.doctor_name);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['title', 'expired_in', 'doctor_name']);
                if (valid) {
                    let form = {
                        title: this.model.title,
                        doctor_name: this.model.doctor_name.full_name ? this.model.doctor_name.full_name : this.model.doctor_name,
                        expired_in: this.model.expired_in,
                        description: this.model.description,
                        json_object: true
                    }

                    let applicant = this.$root.getObjectByKey('name', this.model.applicant, this.users)
                    form.applicant = !this.model.id ? this.user.name : this.model.applicant
                    form.applicant_id = !this.model.id ? this.user.id : applicant.user_id

                    if (this.model.id) {
                        form.api = '/announcements/' + this.model.id
                    } else {
                        form.api = '/announcements'
                    }
                    this.createModal = false
                    this.$store.dispatch("postAction", form).then(response => {
                        if (response.code === 200) {
                            if (!this.model.id) {
                                this.$store.state.announcementsCount++
                            }
                            let datasource = this.dataSource();
                            this.gridOptions.api.setServerSideDatasource(datasource);
                            this.cancelDoctorCreation();
                        }
                    })
                }
            })
        }
    }
}
</script>
