<template>
    <board :span="24" :span_responsive="[ 24, 24 ]" title="Roles Management" height="auto" v-if="$store.state.permissions.view_roles_page">
        <div class="col-md-12 margin-bottom-10 p-0">
            <el-button @click="addNewPermission" class="save-updates-button pull-right">
                {{ $root.translate('add_new_permission') }}
            </el-button>
            <el-button @click="addNewRole" class="save-updates-button pull-right margin-right-10">
                {{ $root.translate('add_new_role') }}
            </el-button>
        </div>
        <div class="col-md-12 with-dropdown-filter-button">
            <div class="grid-filter-trigger" @click="filterTrigger">
                <i :class="floating_filter?'fas fa-filter':'far fa-filter'"></i>
            </div>
        </div>
        <ag-grid-vue :pagination="true"
                     :localeText="$store.state.translations"
                     :enableRtl="$store.state.ag_grid_rtl"
                     :grid-options="gridOptions"
                     rowHeight="60"
                     :floatingFilter="floating_filter"
                     :frameworkComponents="frameworkComponents"
                     :paginationPageSize="20"
                     class="ag-theme-material"
                     :columnDefs="columnDefs"
                     :rowData="roles">
        </ag-grid-vue>
        <b-modal v-model="permissionModal" id="role_permission_modal_id" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ $root.translate('view_permission_for') + ' ' + selectedRole.name }} </h4>
            </div>
            <div class="col-md-12 p-0" v-if="permissionsPerRole.length>0">
                <ag-grid-vue :pagination="true"
                             :localeText="$store.state.translations"
                             :enableRtl="$store.state.ag_grid_rtl"
                             :grid-options="permissionGridOptions"
                             rowHeight="60"
                             :floatingFilter="floating_filter"
                             :frameworkComponents="frameworkComponents"
                             :paginationPageSize="20"
                             class="ag-theme-material"
                             :columnDefs="permissionsColumnDefs"
                             :rowData="permissionsPerRole">
                </ag-grid-vue>
            </div>
            <div class="row col-md-12">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox class="col-md-3 pa-0" :label="permission.id" :key="key"
                                 v-for="(permission,key) in additionalPermissions">
                        {{ permission.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="col-md-12">
                <el-button class="clear-button" @click="close">
                    {{ $root.translate('close') }}
                </el-button>
                <el-button class="save-updates-button pull-right" @click="save">
                    {{ $root.translate('save') }}
                </el-button>
            </div>
        </b-modal>
        <b-modal v-model="createPermissionModal" id="create_permission_modal_id" size="md" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ $root.translate('add_new_permission') }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-12" :class="{'has-error': errors.has('permission_name')}">
                        <label class="col-md-4 col-form-label title-label">Name : <em>*</em></label>
                        <div class="col-md-8">
                            <el-input name="permission_name" v-model="permission_name"
                                      v-validate="'required'"></el-input>
                            <span class="help-block" v-if="errors.has('permission_name')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <el-button class="clear-button" @click="cancelPermissionCreation"><i class=" fa fa-times"></i>
                    {{ $root.translate('cancel') }}</el-button>
                <el-button class="save-updates-button pull-right" @click="createPermission"><i class=" fa fa-save"></i>
                    {{ $root.translate('create') }}</el-button>
            </div>
        </b-modal>

        <b-modal v-model="roleModal" id="role_modal_id" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ $root.translate('add_new_role') }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-12" :class="{'has-error': errors.has('role_name')}">
                        <label class="col-md-4 col-form-label title-label">Name : <em>*</em></label>
                        <div class="col-md-8">
                            <el-input name="role_name" v-model="role_name" v-validate="'required'"></el-input>
                            <span class="help-block" v-if="errors.has('role_name')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <ag-grid-vue :pagination="true"
                             :localeText="$store.state.translations"
                             :enableRtl="$store.state.ag_grid_rtl"
                             :grid-options="permissionGridOptions"
                             rowHeight="60"
                             :floatingFilter="floating_filter"
                             :frameworkComponents="frameworkComponents"
                             :paginationPageSize="20"
                             class="ag-theme-material"
                             :columnDefs="permissionsColumnDefs"
                             :rowData="permissionsPerRole">
                </ag-grid-vue>
            </div>
            <div class="row col-md-12">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox class="col-md-3 pa-0" :label="permission.id" :key="key"
                                 v-for="(permission,key) in additionalPermissions">
                        {{ permission.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="col-md-12">
                <el-button class="clear-button" @click="cancelRoleCreation">
                    {{ $root.translate('cancel') }}
                </el-button>
                <el-button class="save-updates-button pull-right" @click="createRole">
                    {{ $root.translate('create') }}
                </el-button>
            </div>
        </b-modal>
    </board>
</template>

<script>
import board from "@/components/CommonComponents/Board";
import {AgGridVue} from "ag-grid-vue";
import optionsCell from '@/components/CommonComponents/agGrid/AgGridActionsCell.vue';
import Vue from 'vue';

export default {
    data() {
        return {
            additionalPermissions: [],
            checkList: [],
            modules: [
                'Appointment', 'Requests', 'Announcements', 'Tasks', 'Doctors', 'Branches', 'Departments', 'Team'
            ],
            permission_name: '',
            roleModal: false,
            role_name: '',
            refreshTree: true,
            permissionModal: false,
            createPermissionModal: false,
            rolesModal: false,
            roles: [],
            permissions: [],
            selectedRole: {},
            selected: [],
            floating_filter: false,
            frameworkComponents: null,
            defaultProps: {
                children: 'children',
                label: 'name',
                disabled: 'disabled',
            },
            gridOptions: {
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                }, components: {
                    'optionsCell': this.optionsCell,
                }
            },
            columnDefs: [],
            permissionGridOptions: {
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                }
            },
            permissionsColumnDefs: [],
            permissionsPerRole: []
        }
    },
    beforeMount() {

        this.columnDefs = [
            {
                "headerName": "#",
                "width": 60,
                "cellRenderer": function (params) {
                    return params.rowIndex + 1;
                },
                "sortable": false,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('name'),
                "field": 'name',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.name
                },
                valueGetter: params => {
                    return params.data.name
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('created_at'),
                "field": 'created_at',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.created_at
                },
                valueGetter: params => {
                    return params.data.created_at
                },
                "sortable": true,
                "suppressMenu": true
            }
        ]
        this.permissionsColumnDefs = [
            {
                "headerName": "Authority",
                "field": "authority",
                "sortable": false,
                "suppressMenu": false
            },
            {
                headerName: "Add",
                width: "80",
                cellRenderer: function (params) {
                    let input = document.createElement('input');
                    input.type = "checkbox";
                    input.className = "cm-toggle";
                    input.checked = params.node.data['add_' + params.data.authority.toLowerCase()];
                    input.addEventListener('click', function () {
                        params.value = !params.value;
                        params.node.data['add_' + params.data.authority.toLowerCase()] = params.value;
                    });
                    return input;
                }
            },
            {
                headerName: "Edit",
                field: "edit",
                width: "80",
                cellRenderer: function (params) {
                    let input = document.createElement('input');
                    input.type = "checkbox";
                    input.className = "cm-toggle";
                    input.checked = params.node.data['edit_' + params.data.authority.toLowerCase()];
                    input.addEventListener('click', function () {
                        console.log(params)
                        params.value = !params.value;
                        params.node.data['edit_' + params.data.authority.toLowerCase()] = params.value;
                    });
                    return input;
                }
            },
            {
                headerName: "Delete",
                width: "80",
                cellRenderer: function (params) {
                    let input = document.createElement('input');
                    input.type = "checkbox";
                    input.className = "cm-toggle";
                    input.checked = params.node.data['delete_' + params.data.authority.toLowerCase()];
                    input.addEventListener('click', function () {
                        params.value = !params.value;
                        params.node.data['delete_' + params.data.authority.toLowerCase()] = params.value;
                    });
                    return input;
                }
            },
            {
                headerName: "View",
                width: "80",
                cellRenderer: function (params) {
                    let input = document.createElement('input');
                    input.type = "checkbox";
                    input.className = "cm-toggle";
                    input.checked = params.node.data['view_' + params.data.authority.toLowerCase()];
                    input.addEventListener('click', function () {
                        params.value = !params.value;
                        params.node.data['view_' + params.data.authority.toLowerCase()] = params.value;
                    });
                    return input;
                }
            }
        ]
        this.columnDefs.push({
            headerName: this.$root.translate('action'),
            field: 'id',
            width: 100,
            cellRendererFramework: optionsCell,
            cellRendererParams: {
                props: [
                    {
                        icon: 'fas fa-key',
                        value: 'change_permissions',
                        type: 'action',
                        onClick: this.viewPermissionModal
                    },
                    {
                        icon: 'fa fa-trash',
                        value: 'delete',
                        type: 'action',
                        onClick: this.deleteRole
                    }
                ]
            },
            "suppressMenu": true
        })
    },
    mounted() {
        this.$store.dispatch('getAction', "/roles").then(response => {
            if (response.code === 200) {
                this.roles = response.data;
            }
        })
    },
    components: {
        board,
        AgGridVue
    },
    methods: {
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
        deleteRole(option, data, key, type) {
            console.log(option, data, key, type)
            this.$swal({
                icon: 'warning',
                text: 'Do you want to delete ' + data.name + '?',
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('deleteAction', "/roles/" + data.id).then(response => {
                        if (response.code === 200) {
                            this.roles.splice(this.roles.indexOf(data), 1)
                            Vue.$cookies.remove('roles-list')
                        }
                    });
                }
            })
        },
        viewPermissionModal(option, data, key, type) {
            this.additionalPermissions = []
            this.checkList = []
            let permissions = JSON.parse(Vue.$cookies.get('permissions-list'))
            if (!permissions) {
                this.$store.dispatch('getAction', "/permissions").then(response => {
                    console.log(response)
                    if (response.code === 200) {
                        this.permissions = response.data
                        let found = false
                        this.permissions.forEach(permission => {
                            found = false
                            this.modules.forEach(module => {
                                if (permission.slug === 'add_' + module.toLowerCase() || permission.slug === 'edit_' + module.toLowerCase() || permission.slug === 'delete_' + module.toLowerCase() || permission.slug === 'view_' + module.toLowerCase()) {
                                    found = true
                                }
                            })
                            if (!found) {
                                this.additionalPermissions.push(permission)
                            }
                        })
                        Vue.$cookies.set('permissions-list', JSON.stringify(response.data))
                    }
                })
            } else {
                this.permissions = permissions
                let found = false
                this.permissions.forEach(permission => {
                    found = false
                    this.modules.forEach(module => {
                        if (permission.slug === 'add_' + module.toLowerCase() || permission.slug === 'edit_' + module.toLowerCase() || permission.slug === 'delete_' + module.toLowerCase() || permission.slug === 'view_' + module.toLowerCase()) {
                            found = true
                        }
                    })
                    if (!found) {
                        this.additionalPermissions.push(permission)
                    }
                })
            }
            console.log(option, data, key, type)
            this.selectedRole = data

            this.$store.dispatch('getAction', "/roles/" + data.id).then(response => {
                if (response.code === 200) {
                    this.selected = []
                    let data = response.data
                    this.permissionsPerRole = []
                    this.modules.forEach(module => {
                        this.permissionsPerRole.push({authority: module.toLowerCase()})
                        data.forEach(permission => {
                            if (permission.slug === 'add_' + module.toLowerCase() || permission.slug === 'edit_' + module.toLowerCase() || permission.slug === 'delete_' + module.toLowerCase() || permission.slug === 'view_' + module.toLowerCase()) {
                                this.permissionsPerRole[this.permissionsPerRole.length - 1][permission.slug] = true
                            }
                        })
                    })
                    let found = false
                    data.forEach(permission => {
                        found = false
                        this.modules.forEach(module => {
                            if (permission.slug === 'add_' + module.toLowerCase() || permission.slug === 'edit_' + module.toLowerCase() || permission.slug === 'delete_' + module.toLowerCase() || permission.slug === 'view_' + module.toLowerCase()) {
                                found = true
                            }
                        })
                        if (!found) {
                            this.checkList.push(permission.id)
                        }
                    })

                }
            })
            this.permissionModal = true
        },
        addNewPermission() {
            this.createPermissionModal = true
        },
        addNewRole() {
            this.permissionsPerRole = []
            this.additionalPermissions = []
            this.checkList = []
            this.modules.forEach(module => {
                this.permissionsPerRole.push({
                    authority: module.toLowerCase()
                })
            })
            let permissions = JSON.parse(Vue.$cookies.get('permissions-list'))
            if (!permissions) {
                this.$store.dispatch('getAction', "/permissions").then(response => {
                    if (response.code === 200) {
                        this.permissions = response.data
                        let found = false
                        this.permissions.forEach(permission => {
                            found = false
                            this.modules.forEach(module => {
                                if (permission.slug === 'add_' + module.toLowerCase() || permission.slug === 'edit_' + module.toLowerCase() || permission.slug === 'delete_' + module.toLowerCase() || permission.slug === 'view_' + module.toLowerCase()) {
                                    found = true
                                }
                            })
                            if (!found) {
                                this.additionalPermissions.push(permission)
                            }
                        })
                        Vue.$cookies.set('permissions-list', JSON.stringify(response.data))
                    }
                })
            } else {
                this.permissions = permissions
                let found = false
                this.permissions.forEach(permission => {
                    found = false
                    this.modules.forEach(module => {
                        if (permission.slug === 'add_' + module.toLowerCase() || permission.slug === 'edit_' + module.toLowerCase() || permission.slug === 'delete_' + module.toLowerCase() || permission.slug === 'view_' + module.toLowerCase()) {
                            found = true
                        }
                    })
                    if (!found) {
                        this.additionalPermissions.push(permission)
                    }
                })
            }
            this.roleModal = true
        },
        save() {
            let permissions = []
            this.permissionsPerRole.forEach(permission => {
                this.permissions.forEach(perm => {
                    if (permission[perm.slug] == true) {
                        permissions.push(perm.id)
                    }
                })
            })
            permissions.push(...this.checkList)
            let permissions_request = {
                permissions: permissions,
                api: '/roles/' + this.selectedRole.id,
                json_object: true
            };
            this.$store.dispatch('postAction', permissions_request).then(response => {
                if (response.code === 200) {
                    console.log(response)
                    this.$forceUpdate()
                    this.close()
                }
            });
        },
        close() {
            this.$bvModal.hide('role_permission_modal_id');
        },
        cancelPermissionCreation() {
            this.permission_name = ""
            this.$bvModal.hide('create_permission_modal_id');
        },
        createPermission() {
            this.$validator.validate('permission_name', this.permission_name);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['permission_name']);
                if (valid) {
                    let permissions_request = {
                        name: this.permission_name,
                        api: '/permission/create',
                        json_object: true
                    };
                    this.$store.dispatch('postAction', permissions_request).then(response => {
                        if (response.code === 200) {
                            Vue.$cookies.remove('permissions-list', null, this.$store.state.domain);
                            this.cancelPermissionCreation()
                            this.$forceUpdate()
                        }
                    });
                }
            })
        },
        cancelRoleCreation() {
            this.$bvModal.hide('role_modal_id');
            this.role_name = ""
        },
        createRole() {
            this.$validator.validate('role_name', this.role_name);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['role_name']);
                if (valid) {
                    let permissions = []
                    this.permissionsPerRole.forEach(permission => {
                        this.permissions.forEach(perm => {
                            if (permission[perm.slug] == true) {
                                permissions.push(perm.id)
                            }
                        })
                    })
                    permissions.push(...this.checkList)
                    let permissions_request = {
                        name: this.role_name,
                        permissions: permissions,
                        api: '/roles/create',
                        json_object: true
                    };
                    this.$store.dispatch('postAction', permissions_request).then(response => {
                        if (response.code === 200) {
                            this.roles.push(...response.data)
                            Vue.$cookies.remove('roles-list')
                            this.cancelRoleCreation()
                            this.$forceUpdate()
                        }
                    });
                }
            })
        }
    }
}
</script>
<style>

.cm-toggle {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    border: 0;
    outline: 0;
    cursor: pointer;
    margin: 10px;
}


/* To create surface of toggle button */
.cm-toggle:after {
    content: '';
    width: 60px;
    height: 28px;
    display: inline-block;
    background: rgba(196, 195, 195, 0.55);
    border-radius: 18px;
    clear: both;
}


/* Contents before checkbox to create toggle handle */
.cm-toggle:before {
    content: '';
    width: 32px;
    height: 32px;
    display: block;
    position: absolute;
    left: 0;
    top: -3px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}


/* Shift the handle to left on check event */
.cm-toggle:checked:before {
    left: 32px;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Background color when toggle button will be active */
.cm-toggle:checked:after {
    background: #16a085;
}
</style>