<template>
    <div class="col-md-12 p-0">
        <el-tree
            v-if="refreshTree"
            node-key="id"
            :data="selected"
            :props="defaultProps">
        </el-tree>
        <div class="col-md-12">
            <el-button class="clear-button" @click="close">
                {{ $root.translate('close') }}
            </el-button>
        </div>
    </div>
</template>
<script>
import Vue from "vue";

export default {
    props: {
        user: {
            required: false
        }
    },
    data() {
        return {
            refreshTree: true,
            defaultProps: {
                children: 'children',
                label: 'name',
                disabled: 'disabled',
            },
            selected: []
        };
    },
    mounted() {
        let user = {}
        if (this.user) {
            user = this.user
        } else {
            user = Vue.$cookies.get('user');
        }
        let that = this
        this.$store.dispatch('getAction', "/user/" + user.user_id + "/permissions").then(response => {
            this.selected = response.data
            this.refreshTree = false
            this.$nextTick(() => {
                that.refreshTree = true
            })
            this.$forceUpdate()
        })
    },
    methods: {
        close() {
            this.$bvModal.hide('permission_modal_id');
        }
    }
};
</script>