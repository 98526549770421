<template>
    <board :span="24" :span_responsive="[ 24, 24 ]" title="List of doctors" height="auto"
           v-if="$store.state.permissions.view_doctors">
        <div class="col-md-12 margin-bottom-10 p-0" v-if="$store.state.permissions.add_doctors">
            <el-button @click="addNewDoctor" class="save-updates-button pull-right">Add new doctor</el-button>
        </div>
        <div class="col-md-12 with-dropdown-filter-button">
            <div class="grid-filter-trigger" @click="filterTrigger">
                <i :class="floating_filter?'fas fa-filter':'far fa-filter'"></i>
            </div>
        </div>
        <ag-grid-vue :pagination="true"
                     :localeText="$store.state.translations"
                     :enableRtl="$store.state.ag_grid_rtl"
                     :grid-options="gridOptions"
                     rowHeight="60"
                     :floatingFilter="floating_filter"
                     :frameworkComponents="frameworkComponents"
                     :paginationPageSize="20"
                     class="ag-theme-material"
                     :columnDefs="columnDefs"
                     :rowData="data">
        </ag-grid-vue>

        <b-modal v-model="doctorModal" size="xl" hide-footer ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> Doctor </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-6" :class="{'has-error': errors.has('full_name')}">
                        <label class="col-md-4 col-form-label title-label">Full Name : <em>*</em></label>
                        <div class="col-md-8">
                            <el-input name="full_name" v-model="model.full_name"
                                      v-validate="'required'"></el-input>
                            <span class="help-block" v-if="errors.has('full_name')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Joining Date : </label>
                        <div class="col-md-8">
                            <el-date-picker
                                name="joining_date"
                                format="dd-MM-yyyy"
                                value-format="dd-MM-yyyy"
                                :picker-options="pickerOptions"
                                v-model="model.joining_date"
                                type="date">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Speciality : </label>
                        <div class="col-md-8">
                            <el-input name="speciality" v-model="model.speciality"></el-input>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Department :</label>
                        <div class="col-md-8" v-if="model.id">
                            {{ selectedDepartment }}
                        </div>
                        <div class="col-md-8" v-else>
                            <multiselect name="department" v-model="selectedDepartment"
                                         :options="departmentsList"
                                         placeholder="Select departments.." label="title"></multiselect>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">CV : </label>
                        <div class="col-md-8">
                            <el-upload
                                :on-change="changeCV"
                                :multiple="false"
                                ref="cv_uploader"
                                class="upload-demo"
                                action="#"
                                :auto-upload="false"
                                :file-list="cvFiles">
                                <!--                                accept=".pdf, .doc, .docx">-->
                                <el-button size="small" type="primary">Upload CV</el-button>
                            </el-upload>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">License :</label>
                        <div class="col-md-8">
                            <el-upload
                                :on-change="changeLicense"
                                :multiple="false"
                                ref="license_uploader"
                                class="upload-demo"
                                action="#"
                                :auto-upload="false"
                                :file-list="licenseFiles">
                                <!--                                accept=".pdf, .doc, .docx">-->
                                <el-button size="small" type="primary">Upload License</el-button>
                            </el-upload>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <el-button class="clear-button" v-if="model.id"
                               @click="deactivateDoctor"> Deactivate
                    </el-button>
                    <el-button class="clear-button"
                               @click="cancelCreation"> Close
                    </el-button>
                    <el-button class="save-updates-button pull-right"
                               @click="createDoctor"> {{ model.id ? "Save Updates" : "Create" }}
                    </el-button>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="viewModal" id="create_permission_modal_id" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> Doctor </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Full Name :</label>
                        <div class="col-md-8">
                            {{ model.full_name }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Joining Date : </label>
                        <div class="col-md-8">
                            {{ model.joining_date }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Speciality : </label>
                        <div class="col-md-8">
                            {{ model.speciality }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Department :</label>
                        <div class="col-md-8">
                            {{ model.department && model.department[0] ? model.department[0].title : '' }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">CV : </label>
                        <div class="col-md-8">
                            <a :href="$store.state.SERVER_URL + '/'+cv.saved_as.split('public/')[1]" download
                               target="_blank" v-for="(cv,key) in model.cv"
                               :key="key">{{ cv.initial_name }}</a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">License :</label>
                        <div class="col-md-8">
                            <a :href="$store.state.SERVER_URL + '/'+license.saved_as.split('public/')[1]" download
                               target="_blank" v-for="(license,key) in model.license"
                               :key="key">{{ license.initial_name }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <el-button class="clear-button" @click="cancelCreation"> Close</el-button>
                </div>
            </div>
        </b-modal>
    </board>
</template>

<script>
import board from "@/components/CommonComponents/Board";
import {AgGridVue} from "ag-grid-vue";
import optionsCell from '@/components/CommonComponents/agGrid/AgGridActionsCell.vue';


export default {
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() || time.getDay() == 5;
                }
            },
            selectedDepartment: '',
            departmentsList: [],
            licenseFiles: [],
            cvFiles: [],
            model: {},
            viewModal: false,
            doctorModal: false,
            data: [],
            selectedEntity: {},
            floating_filter: false,
            frameworkComponents: null,
            gridOptions: {
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                }, components: {
                    'optionsCell': this.optionsCell,
                }
            },
            columnDefs: [],
        }
    },
    mounted() {

        this.columnDefs = [
            {
                "headerName": "#",
                "width": 60,
                "cellRenderer": function (params) {
                    return params.rowIndex + 1;
                },
                "sortable": false,
                "suppressMenu": true
            },
            {
                "headerName": 'Doctor',
                "field": 'full_name',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.full_name
                },
                valueGetter: params => {
                    return params.data.full_name
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Speciality',
                "field": 'speciality',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.speciality
                },
                valueGetter: params => {
                    return params.data.speciality
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Departments',
                "field": 'departments',
                "cellRenderer": function (params) {
                    let department = []
                    if (params.data.department) {
                        params.data.department.forEach(dep => {
                            department.push(dep.title)
                        });
                    }
                    return department.toString()
                },
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    let department = []
                    if (params.data.department) {
                        params.data.department.forEach(dep => {
                            department.push(dep.title)
                        });
                    }
                    return department.toString()
                },
                valueGetter: params => {
                    let department = []
                    if (params.data.department) {
                        params.data.department.forEach(dep => {
                            department.push(dep.title)
                        });
                    }
                    return department.toString()
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Joining Date',
                "field": 'joining_date',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.joining_date
                },
                valueGetter: params => {
                    return params.data.joining_date
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                headerName: this.$root.translate('action'),
                field: 'id',
                width: 100,
                cellRendererFramework: optionsCell,
                cellRendererParams: {
                    props: [
                        {
                            value: 'View',
                            type: 'action',
                            onClick: this.view
                        }
                    ]
                },
                "suppressMenu": true
            }
        ]

        if (this.$store.state.permissions.edit_appointment) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'Edit',
                    type: 'action',
                    onClick: this.edit
                }
            )
        }
        if (this.$store.state.permissions.delete_appointment) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'Deactivate',
                    type: 'action',
                    onClick: this.deactivate
                }
            )
        }
        this.$store.dispatch('getAction', '/doctor').then(response => {
            if (response.code === 200) {
                this.data = response.data
            }
        })
    },
    components: {
        board,
        AgGridVue
    },
    methods: {
        removeDepartment() {
            this.departmentsList = [];
            this.selectedDepartment = '';
        },
        loadDepartments(params, dep = null) {
            this.selectedDepartment = ''
            this.$store.dispatch('getAction', "/department/" + params.id).then(response => {
                if (response.code === 200) {
                    this.departmentsList = response.data[0].department
                    if (dep) {
                        this.selectedDepartment = this.$root.getObjectByKey('title', dep, this.departmentsList)
                    }
                }
            })
        },
        loadDoctors(params) {
            this.$store.dispatch('getAction', "/department/" + params.id).then(response => {
                if (response.code === 200) {
                    console.log(response)
                    this.doctorsList = response.data[0].department
                }
            })
        },
        changeLicense(file, fileList) {
            console.log(file, fileList);
            this.licenseFiles = [file]
            this.$forceUpdate()
        },
        changeCV(file, fileList) {
            console.log(file, fileList);
            this.cvFiles = [file]
            this.$forceUpdate()
        },
        cancelCreation() {
            this.model = {};
            this.licenseFiles = [];
            this.cvFiles = [];
            this.doctorModal = false
            this.viewModal = false
        },
        createDoctor() {
            this.$validator.validate('full_name', this.model.full_name);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['full_name']);
                if (valid) {
                    let formData = new FormData();
                    if (this.model.id) {
                        formData.set('api', '/doctor/' + this.model.id)
                    } else {
                        formData.set('api', '/doctor')
                    }
                    formData.set('full_name', this.model.full_name)
                    formData.set('joining_date', this.model.joining_date ? this.model.joining_date : '')
                    formData.set('speciality', this.model.speciality ? this.model.speciality : '')
                    formData.set('department', this.selectedDepartment ? this.selectedDepartment.id : '')
                    if (this.licenseFiles.length > 0 && !this.licenseFiles[0].saved_as) {
                        for (let i = 0; i < this.licenseFiles.length; i++) {
                            formData.append('license[]', this.licenseFiles[i].raw)
                            formData.append('licenseFilesNames[]', this.licenseFiles[i].name)
                        }
                    }
                    if (this.cvFiles.length > 0 && !this.cvFiles[0].saved_as) {
                        for (let i = 0; i < this.cvFiles.length; i++) {
                            formData.append('cv[]', this.cvFiles[i].raw)
                            formData.append('cvFilesNames[]', this.cvFiles[i].name)
                        }
                    }
                    this.$store.dispatch('postAction', formData).then(response => {
                        if (response.code === 200) {
                            if (this.model.id) {
                                for (let i = 0; i < this.data.length; i++) {
                                    if (this.data[i].id === this.model.id) {
                                        this.data[i] = response.data
                                    }
                                }
                            } else {
                                this.data.unshift(response.data)
                            }
                            this.gridOptions.api.setRowData([])
                            this.gridOptions.api.setRowData(this.data)
                            this.cancelCreation()
                            this.$forceUpdate()
                        }
                    });
                }
            })
        },
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
        view(option, data, key, type) {
            console.log(option, data, key, type)
            this.model = data
            this.licenseFiles = this.model.license? this.model.license:[]
            this.cvFiles = this.model.cv ? this.model.cv : []
            this.viewModal = true
        },
        edit(option, data, key, type) {
            console.log(option, data, key, type)
            this.model = JSON.parse(JSON.stringify(data))

            this.licenseFiles = this.model.license? this.model.license:[]
            this.cvFiles = this.model.cv? this.model.cv:[]
            this.doctorModal = true
        },
        deactivate(option, data, key, type) {
            console.log(option, data, key, type)
            this.model = data
            this.deactivateDoctor()
        },
        deactivateDoctor() {
            this.$swal({
                icon: 'warning',
                text: 'Do you want to deactivate this doctor ' + this.model.full_name,
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    let request = {
                        api: "/doctor/" + this.model.id,
                        archived: true,
                        json_object: true
                    };
                    this.$store.dispatch('postAction', request).then(response => {
                        if (response.code === 200) {
                            for (let i = 0; i < this.data.length; i++) {
                                if (this.data[i].id === this.model.id) {
                                    this.data.splice(i, 1)
                                }
                            }
                            this.cancelCreation()
                        }
                    })
                }
            })
        },
        addNewDoctor() {
            this.doctorModal = true
            this.model = {}
        }
    }
}
</script>