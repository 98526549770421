<template>
    <div class="col-md-12 p-0">
        <div class="form-group">
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Branch : </label>
                <div class="col-md-8">
                    {{ selectedAppointment.branch }}
                </div>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Department : </label>
                <div class="col-md-8">
                    {{ selectedAppointment.department }}
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Doctor : </label>
                <div class="col-md-8">
                    {{ selectedAppointment.doctor }}
                </div>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Option : </label>
                <div class="col-md-8">
                    {{ selectedAppointment.doctor_options }}
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Assigned to: </label>
                <div class="col-md-8">
                    {{ selectedAppointment.assigned_to }}
                </div>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Due date: </label>
                <div class="col-md-8">
                    {{ selectedAppointment.date_field }}
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-12 p-0">
                <label class="col-md-2 col-form-label title-label">Special Note: </label>
                <div class="col-md-10">
                    {{ selectedAppointment.special_note }}
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Creation Date :</label>
                <div class="col-md-8">
                    {{ selectedAppointment.created_at }}
                </div>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Status :</label>
                <div class="col-md-8">
                    <button v-if="selectedAppointment.status==='Executed'" type="button"
                            class="el-button el-button--success"><span
                        style="color: white">{{ selectedAppointment.status }}</span></button>
                    <button v-else-if="selectedAppointment.status==='Cancelled'" type="button"
                            class="el-button el-button--danger"><span
                        style="color: white">{{ selectedAppointment.status }}</span></button>
                    <button v-else type="button" class="el-button el-button--warning"><span
                        style="color: white">{{ selectedAppointment.status }}</span></button>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div>
                <el-button class="clear-button" @click="closeModel"> Close
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedAppointment: {required: true},
    },
    methods: {
        closeModel() {
            this.$bvModal.hide('view_appointment_modal');
            this.$bvModal.hide('view_appointment_modal_home');
            this.$bvModal.hide('view_appointment_modal_top');
        }
    }
}
</script>