<template>
    <board :span="24" :span_responsive="[ 24, 24 ]" title="List of departments" height="auto" v-if="$store.state.permissions.view_departments">
        <div class="col-md-12 margin-bottom-10 p-0" v-if="$store.state.permissions.add_departments">
            <el-button @click="addNewDepartment" class="save-updates-button pull-right">Add new department</el-button>
        </div>
        <div class="col-md-12 with-dropdown-filter-button">
            <div class="grid-filter-trigger" @click="filterTrigger">
                <i :class="floating_filter?'fas fa-filter':'far fa-filter'"></i>
            </div>
        </div>
        <ag-grid-vue :pagination="true"
                     :localeText="$store.state.translations"
                     :enableRtl="$store.state.ag_grid_rtl"
                     :grid-options="gridOptions"
                     rowHeight="60"
                     :floatingFilter="floating_filter"
                     :frameworkComponents="frameworkComponents"
                     :paginationPageSize="20"
                     class="ag-theme-material"
                     :columnDefs="columnDefs"
                     :rowData="departments">
        </ag-grid-vue>

        <b-modal v-model="departmentsModal" size="md" hide-footer ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> Department </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-12" :class="{'has-error': errors.has('title')}">
                        <label class="col-md-4 col-form-label title-label">Title : <em>*</em></label>
                        <div class="col-md-8">
                            <el-input name="title" v-model="model.title"
                                      v-validate="'required'"></el-input>
                            <span class="help-block" v-if="errors.has('title')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-12">
                        <label class="col-md-4 col-form-label title-label">Creation Date :</label>
                        <div class="col-md-8">
                            {{ model.id ? model.created_at : new Date().toLocaleString() }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <el-button class="clear-button" v-if="model.id" @click="archiveDepartment"> Deactivate
                    </el-button>
                    <el-button class="clear-button" @click="cancelCreation"> Close
                    </el-button>
                    <el-button class="save-updates-button pull-right"
                               @click="createDepartment"> {{ model.id ? "Save Updates" : "Create" }}
                    </el-button>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="viewModal" size="md" hide-footer ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> Department </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-12">
                        <label class="col-md-4 col-form-label title-label">Title:</label>
                        <div class="col-md-8">
                            {{ model.title }}
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-12">
                        <label class="col-md-4 col-form-label title-label">Creation Date :</label>
                        <div class="col-md-8">
                            {{ model.created_at }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <el-button class="clear-button" @click="cancelCreation"> Close
                    </el-button>
                </div>
            </div>
        </b-modal>
    </board>
</template>

<script>
import board from "@/components/CommonComponents/Board";
import {AgGridVue} from "ag-grid-vue";
import optionsCell from '@/components/CommonComponents/agGrid/AgGridActionsCell.vue';


export default {
    data() {
        return {
            model: {},
            departmentsModal: false,
            viewModal: false,
            departments: [],
            selectedEntity: {},
            floating_filter: false,
            frameworkComponents: null,
            gridOptions: {
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                }, components: {
                    'optionsCell': this.optionsCell,
                }
            },
            columnDefs: [],
        }
    },
    mounted() {
        this.columnDefs = [
            {
                "headerName": "#",
                "width": 60,
                "cellRenderer": function (params) {
                    return params.rowIndex + 1;
                },
                "sortable": false,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('name'),
                "field": 'title',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.title
                },
                valueGetter: params => {
                    return params.data.title
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Doctors',
                "field": 'doctorCount',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.doctorCount
                },
                valueGetter: params => {
                    return params.data.doctorCount
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                headerName: this.$root.translate('action'),
                field: 'id',
                width: 100,
                cellRendererFramework: optionsCell,
                cellRendererParams: {
                    props: [
                        {
                            value: 'view',
                            type: 'action',
                            onClick: this.view
                        }
                    ]
                },
                "suppressMenu": true
            }
        ]
        if (this.$store.state.permissions.edit_departments) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'edit',
                    type: 'action',
                    onClick: this.edit
                }
            )
        }
        if (this.$store.state.permissions.delete_departments) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'Deactivate',
                    type: 'action',
                    onClick: this.archive
                }
            )
        }

        this.$store.dispatch('getAction', "/department").then(response => {
            if (response.code === 200) {
                this.departments = response.data
            }
        })
    },
    components: {
        board,
        AgGridVue
    },
    methods: {
        cancelCreation() {
            this.model = {};
            this.departmentsModal = false
            this.viewModal = false
        },
        createDepartment() {
            this.$validator.validate('title', this.model.title);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['title']);
                if (valid) {
                    let request = {
                        title: this.model.title,
                        json_object: true
                    };
                    if (this.model.id) {
                        request.api = "/department/" + this.model.id
                    } else {
                        request.api = "/department"
                    }
                    this.$store.dispatch('postAction', request).then(response => {
                        if (response.code === 200) {

                            if (this.model.id) {
                                for (let i = 0; i < this.departments.length; i++) {
                                    if (this.departments[i].id === this.model.id) {
                                        this.departments[i] = response.data
                                    }
                                }
                            } else {
                                this.departments.unshift(response.data)
                            }
                            this.gridOptions.api.setRowData([])
                            this.gridOptions.api.setRowData(this.departments)
                            this.cancelCreation()
                            this.$forceUpdate()
                        }
                    });
                }
            })
        },
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
        view(option, data, key, type) {
            console.log(option, data, key, type)
            this.model = data
            this.viewModal = true
        },
        edit(option, data, key, type) {
            console.log(option, data, key, type)
            this.model = data
            this.departmentsModal = true
        },
        archiveDepartment() {
            this.$swal({
                icon: 'warning',
                text: 'Do you want to delete this department ' + this.model.title,
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    let request = {
                        archived: true,
                        api: "/department/" + this.model.id,
                        json_object: true
                    };

                    this.$store.dispatch('postAction', request).then(response => {
                        if (response.code === 200) {
                            for (let i = 0; i < this.departments.length; i++) {
                                if (this.departments[i].id === this.model.id) {
                                    this.departments.splice(i, 1)
                                }
                            }
                            this.cancelCreation()
                        }
                    })
                }
            })
        },
        archive(option, data, key, type) {

            console.log(option, data, key, type)
            this.model = data
            this.archiveDepartment()
        },
        addNewDepartment() {
            this.departmentsModal = true
        }
    }
}
</script>