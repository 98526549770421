<template>
    <board :span="24" :span_responsive="[ 24, 24 ]" title="List of branches" height="auto" v-if="$store.state.permissions.view_branches">
        <div class="col-md-12 margin-bottom-10 p-0" v-if="$store.state.permissions.add_branches">
            <el-button @click="addNewBranch" class="save-updates-button pull-right">Add new branch</el-button>
        </div>
        <div class="col-md-12 with-dropdown-filter-button">
            <div class="grid-filter-trigger" @click="filterTrigger">
                <i :class="floating_filter?'fas fa-filter':'far fa-filter'"></i>
            </div>
        </div>
        <ag-grid-vue :pagination="true"
                     :localeText="$store.state.translations"
                     :enableRtl="$store.state.ag_grid_rtl"
                     :grid-options="gridOptions"
                     rowHeight="60"
                     :floatingFilter="floating_filter"
                     :frameworkComponents="frameworkComponents"
                     :paginationPageSize="20"
                     class="ag-theme-material"
                     :columnDefs="columnDefs"
                     :rowData="branches">
        </ag-grid-vue>

        <b-modal v-model="branchModal" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ model.id ? 'Update Branch' : 'Branch' }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-6" :class="{'has-error': errors.has('title')}">
                        <label class="col-md-4 col-form-label title-label">Title : <em>*</em></label>
                        <div class="col-md-8">
                            <el-input name="title" v-model="model.title"
                                      v-validate="'required'"></el-input>
                            <span class="help-block" v-if="errors.has('title')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6" :class="{'has-error': errors.has('address')}">
                        <label class="col-md-4 col-form-label title-label">Address : <em>*</em></label>
                        <div class="col-md-8">
                            <el-input name="address" v-model="model.address"
                                      v-validate="'required'"></el-input>
                            <span class="help-block" v-if="errors.has('department_name')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6" :class="{'has-error': errors.has('branch_manager')}">
                        <label class="col-md-4 col-form-label title-label">B. Manager : <em>*</em></label>
                        <div class="col-md-8">
                            <el-input name="branch_manager" v-model="model.branch_manager"
                                      v-validate="'required'"></el-input>
                            <span class="help-block" v-if="errors.has('branch_manager')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6" :class="{'has-error': errors.has('phone_number')}">
                        <label class="col-md-4 col-form-label title-label">Phone Number : <em>*</em></label>
                        <div class="col-md-8">
                            <el-input name="phone_number" v-model="model.phone_number"
                                      v-validate="'required'"></el-input>
                            <span class="help-block" v-if="errors.has('phone_number')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Creation Date :</label>
                        <div class="col-md-8">
                            {{ model.id ? model.created_at : new Date().toLocaleString() }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-12">
                    <el-button class="clear-button" v-if="model.id"
                               @click="archiveBranch"> Deactivate
                    </el-button>
                    <el-button class="clear-button"
                               @click="cancelCreation"> Close
                    </el-button>
                    <el-button class="save-updates-button pull-right"
                               @click="createBranch"> {{ model.id ? "Save Updates" : "Create" }}
                    </el-button>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="viewModal" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> Branch {{ model.title }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Title:</label>
                        <div class="col-md-8">
                            {{ model.title }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Address:</label>
                        <div class="col-md-8">
                            {{ model.address }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">B. Manager: </label>
                        <div class="col-md-8">
                            {{ model.branch_manager }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Phone Number : </label>
                        <div class="col-md-8">
                            {{ model.phone_number }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Creation Date :</label>
                        <div class="col-md-8">
                            {{ model.created_at }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-12">
                    <el-button class="clear-button"
                               @click="cancelCreation"> Close
                    </el-button>
                </div>
            </div>
        </b-modal>
    </board>
</template>

<script>
import board from "@/components/CommonComponents/Board";
import {AgGridVue} from "ag-grid-vue";
import optionsCell from '@/components/CommonComponents/agGrid/AgGridActionsCell.vue';


export default {
    data() {
        return {
            model: {},
            branchModal: false,
            viewModal: false,
            branches: [],
            floating_filter: false,
            frameworkComponents: null,
            gridOptions: {
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                }, components: {
                    'optionsCell': this.optionsCell,
                }
            },
            columnDefs: []
        }
    },
    mounted() {
        this.columnDefs = [
            {
                "headerName": "#",
                "width": 60,
                "cellRenderer": function (params) {
                    return params.rowIndex + 1;
                },
                "sortable": false,
                "suppressMenu": true
            },
            {
                "headerName": "Title",
                "field": 'title',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.title
                },
                valueGetter: params => {
                    return params.data.title
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": "Address",
                "field": 'address',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.address
                },
                valueGetter: params => {
                    return params.data.address
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": "B. Manager",
                "field": 'branch_manager',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.branch_manager
                },
                valueGetter: params => {
                    return params.data.branch_manager
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": "Phone Number",
                "field": 'phone_number',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.phone_number
                },
                valueGetter: params => {
                    return params.data.phone_number
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                headerName: this.$root.translate('action'),
                field: 'id',
                width: 100,
                cellRendererFramework: optionsCell,
                cellRendererParams: {
                    props: [
                        {
                            value: 'view',
                            type: 'action',
                            onClick: this.view
                        }
                    ]
                },
                "suppressMenu": true
            }
        ]

        if (this.$store.state.permissions.edit_branches) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'edit',
                    type: 'action',
                    onClick: this.edit
                }
            )
        }
        if (this.$store.state.permissions.delete_branches) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'Deactivate',
                    type: 'action',
                    onClick: this.archive
                }
            )
        }
        this.$store.dispatch('getAction', "/branch").then(response => {
            if (response.code === 200) {
                this.branches = response.data
            }
        })
    },
    components: {
        board,
        AgGridVue
    },
    methods: {
        cancelCreation() {
            this.branchModal = false
            this.viewModal = false
        },
        createBranch() {
            this.$validator.validate('title', this.model.title);
            this.$validator.validate('address', this.model.address);
            this.$validator.validate('branch_manager', this.model.branch_manager);
            this.$validator.validate('phone_number', this.model.phone_number);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['title', 'address', 'branch_manager', 'phone_number']);
                if (valid) {
                    let request = {
                        title: this.model.title,
                        address: this.model.address,
                        branch_manager: this.model.branch_manager,
                        phone_number: this.model.phone_number,
                        json_object: true
                    };
                    if (this.model.id) {
                        request.api = "/branch/" + this.model.id
                    } else {
                        request.api = "/branch"
                    }
                    this.$store.dispatch('postAction', request).then(response => {
                        if (response.code === 200) {
                            if (this.model.id) {
                                for (let i = 0; i < this.branches.length; i++) {
                                    if (this.branches[i].id === this.model.id) {
                                        this.branches[i] = response.data
                                    }
                                }
                            } else {
                                this.branches.unshift(response.data)
                            }
                            this.gridOptions.api.setRowData([])
                            this.gridOptions.api.setRowData(this.branches)
                            this.cancelCreation()
                        }
                    });
                }
            })
        },
        archiveBranch() {
            this.$swal({
                icon: 'warning',
                text: 'Do you want to deactivate this branch ' + this.model.title,
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.branchModal=false
                    let request = {
                        api: "/branch/" + this.model.id,
                        archived: true,
                        json_object: true
                    };
                    this.$store.dispatch('postAction', request).then(response => {
                        if (response.code === 200) {
                            for (let i = 0; i < this.branches.length; i++) {
                                if (this.branches[i].id === this.model.id) {
                                    this.branches.splice(i, 1)
                                }
                            }

                        }
                    })
                }
            })
        },
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
        view(option, data, key, type) {
            console.log(option, data, key, type)
            this.model = data
            this.viewModal = true
        },
        edit(option, data, key, type) {
            console.log(option, data, key, type)
            this.model = data
            this.branchModal = true
        },
        archive(option, data, key, type) {
            console.log(option, data, key, type)
            this.model = data
            this.archiveBranch()
        },
        addNewBranch() {
            this.model = {}
            this.branchModal = true
        }
    }
}
</script>
<style lang="scss">
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 4.75rem auto !important;
    }
}
</style>