<template>
    <div class="desktop-nav header navbar fixed-top p-0">
        <div class="nav-logo mr-sm-5 ml-sm-4">
            <a href="javascript:;" @click="$router.push('/')" style="float: left;">
                <img src="/icons/novomed-blue.svg" alt="Novomed" height="50px" width="125px">
            </a>
        </div>

        <ul class="navbar-nav flex-row ml-lg-auto">
            <li class="nav-item dropdown notification-dropdown pl-4 pr-lg-0 pr-2 ml-lg-2 mr-lg-4  align-self-center">
                <el-menu mode="horizontal" @select="handleSelect" style="float:right">
                    <el-submenu index="1" class="topmenu-1-menu3-item topmenu-1-menu3-item-blue">
                        <template slot="title">
                            <icon icon="notification" size="19"></icon>
                            <sup class="bullet el-badge__content">
                                {{$store.state.notifications.length}}
                            </sup>
                        </template>
                      <el-menu-item :index="'notification-'+key" :key="key" v-for="(notification,key) in $store.state.notifications">
                          <span @click="viewNotification(notification)">
                              <i class="fa fa-bell margin-right-10"></i>{{ notification.title }}
                          </span>
                      </el-menu-item>
                    </el-submenu>

                    <el-submenu index="4" class="topmenu-1-menu3-item" :router="true"
                                style="border-left: 1px solid #63757d;">
                        <template slot="title">
                            {{ userIo.name }}
                            <el-avatar> {{ userIo.name[0].toUpperCase() }}</el-avatar>
                        </template>
                        <el-menu-item index="4-1"><i class="fas fa-user margin-right-10"></i>Manage Profile
                        </el-menu-item>
                        <el-menu-item index="4-2">
                            <icon icon="logout" class="margin-right-10" size="19"></icon>
                            Logout
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </li>
        </ul>

        <b-modal id="view_request_modal_top" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> View Request </h4>
            </div>
            <viewRequestForm :selectedRequest="info"></viewRequestForm>
        </b-modal>
        <b-modal size="xl" hide-footer id="view_announcement_modal_top"
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> View Announcement </h4>
            </div>
            <viewAnnouncement :selectedAnnouncement="info"></viewAnnouncement>
        </b-modal>
        <b-modal id="view_task_modal_top"
                 hide-footer ignore-enforce-focus-selector=".login-container" size="lg">
            <div slot="modal-header">
                <h4> View Task </h4>
            </div>
            <viewTaskModel :selectedTask="info"></viewTaskModel>
        </b-modal>
        <b-modal size="xl" hide-footer id="view_appointment_modal_top"
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> View Appointment</h4>
            </div>
            <viewAppointment :selectedAppointment="info"></viewAppointment>
        </b-modal>
    </div>

</template>

<style scoped lang="scss">
@import "@/assets/css/colors.scss";

.screen-shot-image {
    width: 100%;
    height: auto;
    max-height: 350px;
}

.el-menu-item, .el-submenu {
    padding: 0 10px;
}

.dashboard-header .topmenu-2 {
    height: 42px;
}

.dashboard-header .topmenu-1 div.topmenu-1-menu3 .topmenu-1-menu3-item-blue span.bullet {
    color: $color-blue-1;
    background-color: $color-blue-7;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    top: 15px;
    left: -2px;
    right: -15px;
}
</style>

<script>

import Vue from 'vue';
import viewAnnouncement from '@/components/AnouncementsManagement/view.vue';
import viewAppointment from '@/components/appointment/view.vue'
import viewRequestForm from '@/components/requestsManagement/view.vue';
import viewTaskModel from '@/components/tasks/view.vue'

export default {
    name: 'top-menu',
    data() {
        return {
            userIo: Vue.$cookies.get('user'),
            notification_pagintion_info: 10,
            modelId: '',
            info: {}
        };
    },
    props: {
        title: {
            type: String,
            default: 'title is not set, you can set it by emitting \'TitleChange.\' '
        }
    },
    components:{
        viewAnnouncement,
        viewAppointment,
        viewRequestForm,
        viewTaskModel
    },
    computed: {
        unreadInfoNotificationsCount() {
            let notifications = [];
            if (notifications === null) {
                return false;
            }
            const filterUnread = (notification => !notification.is_read);
            return notifications.filter(filterUnread).length;
        },
    },
    mounted() {
    },
    methods: {
        viewNotification(notification) {
            this.info = {}
            let api = ""
            if (notification.object_type === "announcement") {
                this.modelId = "view_announcement_modal_top"
                api = "/announcements/" + notification.object_id
            } else if (notification.object_type === "appointment") {
                this.modelId = "view_appointment_modal_top"
                api = "/appointment/" + notification.object_id
            } else if (notification.object_type === "request") {
                this.modelId = "view_request_modal_top"
                api = "/request/" + notification.object_id
            } else if (notification.object_type === "task") {
                this.modelId = "view_task_modal_top"
                api = "/tasks/" + notification.object_id
            }

            this.$store.dispatch('getAction', api).then(response => {
                if (response.code === 200) {
                    this.info = response.data
                    this.$bvModal.show(this.modelId);
                    this.$forceUpdate()
                }
            })
        },
        handleSelect(key) {
            switch (key) {
                case '4-1':
                    this.$router.push('/profile');
                    break;
                case '4-2':
                    this.$router.push('/logout');
                    break;
                default: {
                    return;
                }
            }
        },
    }
};
</script>
<style>
.el-badge__content{
    padding-right: 8px !important;
    border: none !important;
    color: white !important;
    height: 21px !important;
    line-height: 21px !important;
}
</style>