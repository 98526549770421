<template>
    <div class="row" :class="{ 'login-popup': !background }">
        <div class="col-md-12 p-0">
            <div class="col-md-5 login-first-block">
                <div class="login-first-icon">
                </div>
            </div>
            <div class="login-second-block" :class="{'transparent':$store.state.login_modal,'col-md-5':!$store.state.login_modal}">
                <div class="login-label">
                    {{ !$store.state.allowResetPasswordPage?'Login':'Reset Password' }}
                </div>
                <div v-if="!$store.state.allowResetPasswordPage" class="login-form" :class="$store.state.login_modal ? 'transparent': ''">
                    <div class="form-group" :class="{'has-error': errors.has('email')}">
                        <label class="col-md-4 form-labels">
                            Email </label>
                        <div class="col-md-12">
                            <div class="col-md-8 p-0">
                                <el-input name="email" type="email" v-model="model.email" v-validate="'required|email'"
                                          @keyup.enter.native="validateCredentials"></el-input>
                                <span class="help-block" v-if="errors.has('email')">
                                {{
                                        model.email ? 'The email field must be a valid email' : $root.translate('this_field_is_required')
                                    }}
                            </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" :class="{'has-error': errors.has('password')}">
                        <label class="col-md-4 form-labels"> Password</label>
                        <div class="col-md-12">
                            <div class="col-md-8 p-0">
                                <el-input name="password" type="password" v-model="model.password"
                                          v-validate="'required'"
                                          @keyup.enter.native="validateCredentials"></el-input>
                                <span class="help-block" v-if="errors.has('password')">
                                    {{ $root.translate('this_field_is_required') }}
                                </span>
                            </div>
                            <div class="col-md-8 p-0">
                                <a class="forget-password-label" :class="{'disabled': disableForgetPassword}"
                                   @click="forgetPassword">
                                    Forget Password?
                                </a>
                            </div>
                            <div class="col-md-8 p-0">
                                <el-button class="login-button" type="default"
                                           @click="validateCredentials">
                                    Login
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-left: 25vh; margin-top: 3vh" v-else>
                    <resetPageComponent :user="userInfo" :with_verification="true"></resetPageComponent>
                </div>
            </div>
            <div class="col-md-2 login-second-block">
                <div class="login-third-block">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import resetPageComponent from '@/components/user/ResetPassword.vue';

export default {
    props: {
        background: {
            required: false,
            default: true
        }
    },
    components: {
        resetPageComponent,

    },
    data() {
        return {
            userInfo: {},
            model: {
                email: "",
                password: ""
            },
            disableForgetPassword: false,
        }
    },
    mounted() {
        this.$store.dispatch('menusVisible', false);
        let htmlEl = document.querySelector("html");
        if (this.$store.state.translation_type === 'ar') {
            this.$root.toggleBodyClass('remove', 'rtl');
            this.$root.toggleBodyClass('add', 'ltr');
            htmlEl.setAttribute('dir', 'ltr');
        }
    },
    methods: {
        forgetPassword() {
            this.$validator.validate('email', this.model.email);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['email']);
                if (valid) {
                    let request = {
                        email: this.model.email,
                        api: '/send-verification-code',
                        json_object: true
                    };
                    this.disableForgetPassword = true
                    this.$store.dispatch('postAction', request).then(response => {

                        this.userInfo = {
                            email: this.model.email,
                            id: response.data,
                        }
                        this.$store.state.allowResetPasswordPage = true
                        this.disableForgetPassword = false
                        this.$forceUpdate()
                    }).catch(() => {

                        this.disableForgetPassword = false
                        this.$swal.fire({
                            icon: 'error',
                            type: 'error',
                            title: '',
                            text: "Email doesn't exists",
                            allowOutsideClick: true,
                            allowEscapeKey: true
                        });
                    })
                }
            })
        },
        validateCredentials() {
            this.$validator.validate('email', this.model.email);
            this.$validator.validate('password', this.model.password);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['email', 'password']);
                if (valid) {
                    let formData = new FormData();
                    formData.append("email", this.model.email);
                    formData.append("password", this.model.password);
                    formData.set('api', '/login');
                    this.$store.dispatch('postAction', formData).then(response => {
                        if (response.code === 200) {
                            this.$store.dispatch("setToken", response.data.token);
                            Vue.$cookies.set("user", JSON.stringify(response.data.user), null, '/', this.$store.state.domain);
                            localStorage.setItem("translations", JSON.stringify(response.data.translations));
                            localStorage.setItem("permission", JSON.stringify(response.data.permissions), null, '/', this.$store.state.domain);

                            if (!this.$store.state.login_modal) {
                                this.$store.dispatch('menusVisible', true);
                            }
                            this.$store.state.login_modal = false;
                            location.reload(true);
                        }
                    }).catch(error => {
                        console.log(error)
                        this.$swal.fire({
                            type: 'error',
                            icon: 'error',
                            title: 'Login Failed',
                            text: "Wrong Email/Password combination",
                            allowOutsideClick: true,
                            allowEscapeKey: true
                        });
                    });
                }
            })
        },
        checkIfAuthenticate() {
            this.$store.state.token = Vue.$cookies.get('token');
            if (this.$store.getters.isAuthorized) {
                this.redirecToDestination();
            }
        },
        redirecToDestination() {
            this.$router.push(this.$store.state.redirectToAfterLogin);
        },
        created() {
            this.checkIfAuthenticate();
        },

    }
}

</script>

<style>
body {
    background: none;
}
</style>
<style lang="scss">
.login-popup{
    .login-first-block {
        display: none;
    }

    .login-second-block {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        height: 40%;
        //width: 60vh !important;
        display: table;
        .login-label {
            margin-top: 5vh;
            text-align: center;
            font: normal normal bold 50px/63px Quicksand;
            letter-spacing: 0px;
            color: #333333;
            opacity: 1;
        }

        .form-labels {
            color: var(--unnamed-color-333333);
            text-align: left;
            font: normal normal normal 16px/20px Quicksand;
            letter-spacing: 0px;
        }

        .forget-password-label {
            float: right;
            text-align: left;
            font: normal normal normal 18px/23px Quicksand;
            letter-spacing: 0px;
            color: #FF1CC3 !important;
        }

        .login-button {
            border: none;
            outline: none;
            width: 100%;
            background: #001DFE;
            letter-spacing: 0px;
            border-radius: 28px;
            margin-top: 3vh;

            span {
                color: white;
            }
        }
        .login-form{
            margin-left: 3vh;
            margin-top: 0vh;
        }
        .el-button:focus, .el-button:hover{
            background: #001DFE !important;
        }
    }
    .login-third-block{
        display: none;
    }
}
.login-first-block {
    height: 100vh;
    background: #0DC0F2 0% 0% no-repeat padding-box;

    .login-first-icon {
        margin-top: 40vh;
        margin-left: 10vh;
        width: 420px;
        height: 75px;
        background: transparent url('/images/login-first-icon.svg') 0% 0% no-repeat padding-box;
        border: 1px solid #00000000;
    }
}

.login-second-block {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 100vh;

    .login-form{
        margin-left: 25vh;
        margin-top: 3vh
    }
    .login-label {
        font: var(--unnamed-font-style-normal) normal bold 50px/63px var(--unnamed-font-family-quicksand);
        letter-spacing: var(--unnamed-character-spacing-0);
        margin-top: 30vh;
        color: var(--unnamed-color-333333);
        text-align: center;
        font: normal normal bold 50px/63px Quicksand;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
    }

    .form-labels {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-quicksand);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-333333);
        text-align: left;
        font: normal normal normal 16px/20px Quicksand;
        letter-spacing: 0px;
    }

    .forget-password-label {
        float: right;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 18px/23px var(--unnamed-font-family-quicksand);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal normal 18px/23px Quicksand;
        letter-spacing: 0px;
        color: #FF1CC3 !important;
    }

    .login-button {
        border: none;
        outline: none;
        width: 100%;
        background: #001DFE;
        letter-spacing: 0px;
        //color: #ffffff;
        border-radius: 28px;
        margin-top: 3vh;

        span {
            color: white;
        }
    }
    .el-button:focus, .el-button:hover{

        background: #001DFE !important;
    }
}
.login-third-block{
    top: 0px;float: right; width: 200px;margin-right: -10px;
    padding: 0px;
    height: 100vh;
    background: transparent url('/images/login-second-icon.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
}
</style>
