<template>
    <span v-if="$store.state.permissions.view_admin_home_page">
        <div class="row" style="margin-left: 10px">
            <div class="col-md-12 margin-top-10">
                <label class="form-labels"> Announcements </label>
                <div class="column-separator"></div>
                <a href="javascript:;" class="check-all-label" @click="$router.push('/announcements')">Check All</a>
            </div>
            <div class="col-md-6" :key="key" v-for="(announcement,key) in announcements">
                <div class="widget-content-area data-widgets br-4 main-div-class"
                     :style="key===0?{'background-color': '#30c0f2'}:{'background-color': '#f57629'}" v-if="key<2">
                    <div class="widget  t-sales-widget" style="color: #cef2fd">
                        <div class="media">
                            <div class="icon ml-2" style="color: white !important;">
                                {{ announcement.title }}<br>
                                {{ announcement.expired_in }}
                            </div>
                        </div>
                        <p class="widget-total-stats mt-2 ml-2"
                           style="color: white !important;"> {{ announcement.description }}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12  margin-top-10">
                <div class="col-md-3 p-0">
                    <label class="form-labels"> Appointments </label>
                    <div class="column-separator"></div>
                    <a href="javascript:;" class="check-all-label" @click="$router.push('/appointment')">Check All</a>
                </div>
                <div class="col-md-9">
                    <label class="form-labels"> Quick Task Creation </label>
                    <div class="column-separator"></div>
                    <a href="javascript:;" class="check-all-label" @click="$router.push('/tasks')">Check All</a>
                </div>
            </div>

            <div class="col-md-12">
                <div class="col-md-3" style="background-color: white;">
                    <el-timeline :reverse="false" class="margin-top-10" style="background-color: white;">
                        <el-timeline-item
                            v-for="(activity, index) in appointments"
                            :key="index"
                            :timestamp="activity.date">
                            {{ activity.doctor }}
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div class="col-md-9" style="padding-right: 0px;">
                    <create-task class="main-div-class"></create-task>
                </div>
            </div>
            <div class="col-md-12 margin-top-10">
                <div class="col-md-12 p-0">
                    <label class="form-labels" @click="loadTomorrowTasks('today')"
                           style="cursor: pointer;"> Today Tasks </label>
                    <div class="column-separator"></div>
                    <a @click="loadTomorrowTasks('tomorrow')" style="margin-left: 20px; cursor: pointer;">
                        Tomorrow Tasks
                    </a>
                    <div class="column-separator"></div>
                    <a href="javascript:;" class="check-all-label" @click="$router.push('/tasks')">Check All</a>
                </div>
                <div class="col-md-12 p-0">
                    <div class="col-xl-3 mb-xl-3 col-lg-3 col-md-3 col-sm-3" style="padding-left: 0px" :key="key"
                         v-for="(task,key) in tasks">
                        <div class="widget-content-area  data-widgets br-4 main-div-class">
                            <div class="widget  t-sales-widget" style="color: black">
                                <div class="media">
                                    <div class="icon ml-2">
                                        {{ task.title }}
                                    </div>
                                    <div class="media-body" style="text-align: right">
                                        <el-dropdown trigger="click">
                                            <el-button icon="fas fa-ellipsis-v" type="plain" size="mini"></el-button>
                                            <el-dropdown-menu slot="dropdown">

                                                <el-dropdown-item
                                                    @click.native="sendReminderTask(task)">
                                                    Send Reminder
                                                </el-dropdown-item>
                                                <el-dropdown-item
                                                    @click.native="addAssigneeTask(task)">
                                                    Add Assignee
                                                </el-dropdown-item>
                                                <el-dropdown-item
                                                    @click.native="archiveTask(task)">
                                                    Archive
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                </div>
                                <p class="widget-total-stats mt-2 ml-2">{{ task.assignee }}</p>
                            </div>
                            <el-progress
                                :percentage="task.status==='Upcoming'?0:task.status==='In progress'?33:task.status==='To be reviewed'?67:100"></el-progress>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 margin-top-10">
                <div class="col-md-8 p-0">
                    <label class="form-labels"> Last Requests </label>
                    <div class="column-separator"></div>
                    <a href="javascript:;" class="check-all-label" @click="$router.push('/requests')">Check All</a>
                </div>

                <div class="col-md-4">
                    <label class="form-labels"> Team </label>
                    <div class="column-separator"></div>
                    <a href="javascript:;" class="check-all-label" @click="$router.push('/users')">Check All</a>
                </div>
                <div class="col-md-8 p-0">
                    <div class="col-xl-4 mb-xl-4 col-lg-4 col-md-4 col-sm-4" :key="key" style="padding-left: 0px"
                         v-for="(request,key) in requests">
                        <div class="widget-content-area  data-widgets br-4 main-div-class">
                            <div class="widget  t-sales-widget" style="color: black">
                                <div class="media">
                                    <div class="media-body text-right">
                                        <p class="widget-text mb-0" style="line-break: anywhere;">
                                            {{ request.type }}</p>
                                    </div>
                                    <div class="media-body" style="text-align: right">
                                        <el-dropdown trigger="click">
                                            <el-button icon="fas fa-ellipsis-v" type="plain" size="mini"></el-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item
                                                    @click.native="sendNumberRequest(request)">
                                                    Send Reminder
                                                </el-dropdown-item>
                                                <el-dropdown-item
                                                    @click.native="addAssigneeRequest(request)">
                                                    Add assignee
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                </div>
                                <p class="widget-total-stats mt-2">{{ request.doctor_name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 p-0">
                    <div class="col-xl-6 mb-xl-4 col-lg-6 col-md-6 col-sm-6" :key="key" v-for="(user,key) in users">
                        <div class="widget-content-area  data-widgets br-4 main-div-class" v-if="key<4">
                            <div class="widget  t-sales-widget">
                                <div class="media">
                                    <icon icon="person-colored" class="margin-right-10" size="50"></icon>
                                    <div class="media-body text-right">
                                        <p class="widget-text mb-0" style="line-break: anywhere;">{{ user.name }}</p>
                                        <p style="line-break: anywhere;">{{ user.email }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </span>
    <span v-else>
        <div class="col-md-6" v-for="(notification,key) in $store.state.notifications" :key="key"
             @click="viewNotification(notification)" style="cursor: pointer">
            <board :span="24" :span_responsive="[ 24, 24 ]" :title="notification.title"
                   height="auto" :class="'notification-'+notification.object_type">
                <div class="col-md-12 p-0 notification-content">
                  Type:  {{ notification.object_type.toUpperCase() }}:<br>
                  Message: {{ notification.message }}<br>
                  Sent by: {{ notification.sender_id }}
                </div>
            </board>
        </div>

        <b-modal id="view_request_modal_home" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> View Request </h4>
            </div>
            <viewRequestForm :selectedRequest="info"></viewRequestForm>
        </b-modal>
        <b-modal size="xl" hide-footer id="view_announcement_modal_home"
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> View Announcement </h4>
            </div>
            <viewAnnouncement :selectedAnnouncement="info"></viewAnnouncement>
        </b-modal>
        <b-modal id="view_task_modal_home"
                 hide-footer ignore-enforce-focus-selector=".login-container" size="lg">
            <div slot="modal-header">
                <h4> View Task </h4>
            </div>
            <viewTaskModel :selectedTask="info"></viewTaskModel>
        </b-modal>
        <b-modal size="xl" hide-footer id="view_appointment_modal_home"
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> View Appointment</h4>
            </div>
            <viewAppointment :selectedAppointment="info"></viewAppointment>
        </b-modal>
    </span>
</template>

<script>
import createTask from '@/components/tasks/create.vue'
import Vue from "vue";
import board from "@/components/CommonComponents/Board";
import viewAnnouncement from '@/components/AnouncementsManagement/view.vue';
import viewAppointment from '@/components/appointment/view.vue'
import viewRequestForm from '@/components/requestsManagement/view.vue';
import viewTaskModel from '@/components/tasks/view.vue'

export default {
    data() {
        return {
            user: Vue.$cookies.get('user'),
            branchList: [],
            users: [],
            requests: [],
            tasks: [],
            appointments: [],
            announcements: [],
            modelId: '',
            info: {}
        }
    },
    mounted() {
        if (this.$store.state.permissions.view_admin_home_page) {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;

            this.$store.dispatch('getAction', "/users").then(response => {
                if (response.code === 200) {
                    this.users = response.data
                }
            })

            this.$store.dispatch('getAction', "/request?count=6").then(response => {
                if (response.code === 200) {
                    this.requests = response.data
                }
            })

            let api = ''
            if (this.$store.state.permissions.view_all_tasks) {
                api = "/tasks?due_date=" + today
            } else {
                api = "/tasks?task_to=" + this.user.name + "&due_date=" + today
            }
            this.$store.dispatch('getAction', api).then(response => {
                if (response.code === 200) {
                    this.tasks = response.data.data
                }
            })

            this.$store.dispatch('getAction', "/appointment?count=4").then(response => {
                console.log(response)
                if (response.code === 200) {
                    this.appointments = response.data
                }
            })
            this.$store.dispatch('getAction', "/announcements?count=2").then(response => {
                if (response.code === 200) {
                    this.announcements = response.data
                }
            })
        }
    },
    components: {
        "create-task": createTask,
        "board": board,
        viewAnnouncement,
        viewAppointment,
        viewRequestForm,
        viewTaskModel
    },
    methods: {
        viewNotification(notification) {
            this.info = {}
            let api = ""
            if (notification.object_type === "announcement") {
                this.modelId = "view_announcement_modal_home"
                api = "/announcements/" + notification.object_id
            } else if (notification.object_type === "appointment") {
                this.modelId = "view_appointment_modal_home"
                api = "/appointment/" + notification.object_id
            } else if (notification.object_type === "request") {
                this.modelId = "view_request_modal_home"
                api = "/request/" + notification.object_id
            } else if (notification.object_type === "task") {
                this.modelId = "view_task_modal_home"
                api = "/tasks/" + notification.object_id
            }

            this.$store.dispatch('getAction', api).then(response => {
                if (response.code === 200) {
                    this.info = response.data
                    this.$bvModal.show(this.modelId);
                }
            })
        },
        //request actions
        sendNumberRequest(request) {
            console.log(request)
        },
        addAssigneeRequest(request) {
            console.log(request)
        },

        //tasks actions
        editTask(task) {
            console.log(task)
        },
        sendReminderTask(task) {
            console.log(task)
        },
        addAssigneeTask(task) {
            console.log(task)
        },
        archiveTask(task) {
            console.log(task)
        },
        loadTomorrowTasks(filter) {
            let today = ""
            if (filter === "tommorrow") {
                let tommorrow = new Date(+new Date() + 86400000).toLocaleDateString()
                today = tommorrow.split('/')[2] + '-' + tommorrow.split('/')[1] + '-' + tommorrow.split('/')[0];
            } else {
                today = new Date();
                let dd = String(today.getDate()).padStart(2, '0');
                let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                let yyyy = today.getFullYear();
                today = yyyy + '-' + mm + '-' + dd;
            }
            let api = ''
            if (this.$store.state.permissions.view_all_tasks) {
                api = "/tasks?due_date=" + today
            } else {
                api = "/tasks?task_to=" + this.user.name + "&due_date=" + today
            }
            this.$store.dispatch('getAction', api).then(response => {
                if (response.code === 200) {
                    this.tasks = response.data
                }
            })
        }
    }
}
</script>
<style lang="scss">
.notification-request {
    background-color: #f57629 !important;

    .title {
        color: white !important;
    }

    .notification-content {
        color: white !important;
    }
}

.notification-announcement {
    background-color: #30c0f2 !important;

    .title {
        color: white !important;
    }

    .notification-content {
        color: white !important;
    }
}

.home-icons {
    text-align: center;
    margin-top: 20px;
}

.column-separator {
    width: 1px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    height: 28px;
    position: absolute;
    background: #343434 0% 0% no-repeat padding-box;
}

.check-all-label {
    margin-left: 20px;
    color: #f181dd !important;
}

.main-div-class {
    background-color: #ffffff;
    padding: 15px !important;
    margin-top: 2px !important;
}
</style>