<template>
    <board v-if="$store.state.permissions.view_tasks" :span="24" :span_responsive="[ 24, 24 ]" height="auto"
           title="Tasks Management">
        <div v-if="$store.state.permissions.add_tasks" class="col-md-12 margin-bottom-10 p-0">
            <el-button class="save-updates-button pull-right" @click="addNewTask">
                Add Task
            </el-button>
        </div>
        <div class="col-md-12 with-dropdown-filter-button">
            <div class="grid-filter-trigger" @click="filterTrigger">
                <i :class="floating_filter?'fas fa-filter':'far fa-filter'"></i>
            </div>
        </div>
        <ag-grid-vue :cacheBlockSize="10"
                     :columnDefs="columnDefs"
                     :enableRtl="$store.state.ag_grid_rtl"
                     :floatingFilter="floating_filter"
                     :frameworkComponents="frameworkComponents"
                     :grid-options="gridOptions"
                     :localeText="$store.state.translations"
                     :maxBlocksInCache="10"
                     :pagination="true"
                     :paginationPageSize="10"
                     :rowData="tasks"
                     class="ag-theme-material"
                     rowHeight="60"
                     @grid-ready="onGridReady">
        </ag-grid-vue>

        <b-modal id="edit_task_modal" v-model="editModal" hide-footer ignore-enforce-focus-selector=".login-container"
                 size="xl">
            <div slot="modal-header">
                <h4> Edit Task </h4>
            </div>
            <createTask :task="selectedTask"></createTask>
        </b-modal>

        <b-modal v-model="viewModal" id="view_task_modal"
                 hide-footer ignore-enforce-focus-selector=".login-container" size="lg">
            <div slot="modal-header">
                <h4> View Task </h4>
            </div>
            <viewTaskModel :selectedTask="selectedTask"></viewTaskModel>


        </b-modal>

        <b-modal v-model="statusModal" hide-footer ignore-enforce-focus-selector=".login-container"
                 size="md">
            <div slot="modal-header">
                <h4> {{ $root.translate('status') }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-12">
                        <label class="col-md-3 col-form-label title-label">Status :</label>
                        <div class="col-md-1">
                            <el-radio v-model="selectedTask.status" label="Upcoming">Upcoming</el-radio>
                            <el-radio v-model="selectedTask.status" label="Pending Approval">Pending Approval</el-radio>
                            <el-radio v-model="selectedTask.status" label="In Progress">In Progress</el-radio>
                            <el-radio v-model="selectedTask.status" label="Executed">Executed</el-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <el-button class="clear-button"
                           @click="closeModal"> Close
                </el-button>

                <el-button class="save-updates-button pull-right"
                           @click="saveStatus"> Save Updates
                </el-button>
            </div>
        </b-modal>

        <b-modal id="create_task_modal" v-model="taskModal" hide-footer ignore-enforce-focus-selector=".login-container"
                 size="xl">
            <div slot="modal-header">
                <h4> Create Task </h4>
            </div>
            <div class="col-md-12 p-0">
                <createTask></createTask>
            </div>
        </b-modal>
    </board>
</template>

<script>
import Vue from 'vue'
import board from "@/components/CommonComponents/Board";
// import {AgGridVue} from "ag-grid-vue";
import {AgGridVue} from 'ag-grid-vue/main';
import optionsCell from '@/components/CommonComponents/agGrid/AgGridActionsCell.vue';
import createTask from '@/components/tasks/create.vue'
import viewTaskModel from './view'

export default {
    data() {
        return {
            user: Vue.$cookies.get('user'),
            model: {},
            selectedTask: {
                status: ''
            },
            statusModal: false,
            viewModal: false,
            editModal: false,
            pickerOptions: {
                disabledDate(time) {
                    const today = new Date()
                    const tomorrow = new Date(today)
                    tomorrow.setDate(tomorrow.getDate() - 1)
                    return time.getTime() < tomorrow || time.getDay() == 5;
                }
            },
            taskModal: false,
            gridApi: {},
            tasks: [],
            users: [],
            floating_filter: false,
            frameworkComponents: null,
            gridOptions: {
                rowModelType: 'serverSide',
                serverSideStoreType: 'partial',
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                }, components: {
                    'optionsCell': this.optionsCell,
                    'priorityField': this.priorityField,
                }
            },
            columnDefs: []
        }
    },
    mounted() {
        this.columnDefs = [
            {
                "headerName": "#",
                "width": 60,
                "cellRenderer": function (params) {
                    return params.rowIndex + 1;
                },
                "filter": false,
                "sortable": false,
                "suppressMenu": true
            },
            {
                "headerName": 'Title',
                "field": 'title',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.title
                    else return '';
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.title
                    else return '';
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Applicant',
                "field": 'applicant',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.applicant
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.applicant
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Assignee',
                "field": 'assignee',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.assignee
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.assignee
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('status'),
                "field": 'status',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.status
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.status
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Due Date',
                "field": 'due_date',
                filter: 'agTextColumnFilter',
                cellRenderer: function (params) {
                    if (params.data)
                        return params.data.due_date.split('-').reverse().join('-')
                },
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.due_date.split('-').reverse().join('-')
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.due_date.split('-').reverse().join('-')
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('created_at'),
                "field": 'created_at',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.created_at
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.created_at
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": 'Priority',
                "field": 'priority',
                cellRenderer: 'priorityField',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.priority
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.priority
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                headerName: this.$root.translate('action'),
                width: 100,
                cellRendererFramework: optionsCell,
                cellRendererParams: {
                    props: [
                        {
                            value: 'view',
                            type: 'action',
                            onClick: this.viewTask
                        }
                    ]
                },
                "suppressMenu": true
            }
        ]
        if (this.$store.state.permissions.edit_tasks) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'edit',
                    type: 'action',
                    onClick: this.editTask
                }
            )
        }
        if (this.$store.state.permissions.send_task_reminder) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'send_reminder',
                    type: 'action',
                    onClick: this.sendReminder
                }
            )
        }
        if (this.$store.state.permissions.change_task_status) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'update_status',
                    type: 'action',
                    onClick: this.updateStatus
                }
            )
        }
        if (this.$store.state.permissions.delete_tasks) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'archive',
                    type: 'action',
                    onClick: this.archive
                }
            )
        }

        this.$store.dispatch('getAction', "/users").then(response => {
            if (response.code === 200) {
                this.users = response.data
            }
        })
    },
    components: {
        board,
        createTask,
        AgGridVue,
        viewTaskModel
    },
    methods: {
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            let datasource = this.dataSource();
            params.api.setServerSideDatasource(datasource);
        },
        dataSource() {
            let that = this;
            return {
                getRows(params) {
                    console.log(params)
                    let filter = {};
                    if (params.request.filterModel) {
                        let keys = Object.keys(params.request.filterModel)
                        keys.forEach(key => {
                            filter[key] = params.request.filterModel[key].filter
                        })
                    }
                    let gridSort = []
                    if (params.request.sortModel) {
                        gridSort = params.request.sortModel
                    }
                    let api = ''
                    if (that.$store.state.permissions.view_all_tasks) {
                        api = "/tasks" + "?filter=" + JSON.stringify(filter) + "&sort=" + JSON.stringify(gridSort)
                    } else {
                        api = "/tasks?task_to=" + that.user.name + "&filter=" + JSON.stringify(filter) + "&sort=" + JSON.stringify(gridSort)
                    }
                    that.$store.dispatch('getAction', api).then(response => {
                        if (response.code === 200) {
                            that.tasks = response.data
                            setTimeout(function () {

                                params.successCallback(response.data.data, response.data.total);
                            }, 100)

                        }
                    })
                }
            }
        },
        priorityField(params) {
            if (params.data) {
                if (params.data.priority === 'Low') {
                    return '<button type="button" class="el-button el-button--success" style="width: 130px;"><span style="color: white">' + params.data.priority + '</span></button>'
                } else if (params.data.priority === 'High') {
                    return '<button type="button" class="el-button el-button--warning" style="width: 130px;"><span style="color: white">' + params.data.priority + '</span></button>'
                } else if (params.data.priority === 'Urgent') {
                    return '<button type="button" class="el-button el-button--danger" style="width: 130px;"><span>' + params.data.priority + '</span></button>'
                }
                return ""
                // return '<button type="button" class="el-button el-button--default el-tag--medium-priority" style="width: 130px;"><span>' + params.data.priority + '</span></button>'

            }
        },
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
        addNewTask() {
            this.taskModal = true
        },
        cancelCreation() {
            this.taskModal = false
            this.model = {}
        },

        sendReminder(option, data, key, type) {
            console.log(option, data, key, type)
            let applicant = this.$root.getObjectByKey('name', data.applicant, this.users)
            let assignee = []
            if (data.assignee) {
                data.assignee = data.assignee.split(',')
                for (let i = 0; i < data.assignee.length; i++) {
                    assignee.push(this.$root.getObjectByKey('name', data.assignee[i], this.users))
                }
            }
            let assignee_id = []
            for (let i = 0; i < assignee.length; i++) {
                assignee_id.push(assignee[i].user_id)
            }
            let request = {
                type: "task",
                title: data.title,
                id: data.id,
                applicant_id: applicant.user_id,
                due_date: data.due_date,
                json_object: true,
                assignee_id: assignee_id,
                api: "/tasks/" + data.id + "/reminder"
            }

            this.$store.dispatch("postAction", request).then(response => {
                if (response.code === 200) {
                    this.$swal({
                        icon: 'info',
                        text: 'Notifications had sent to ' + data.assigned_to + '.',
                        showCancelButton: false,
                        confirmButtonText: 'OK'
                    })
                }
            })
        },

        updateStatus(option, data, key, type) {
            this.selectedTask = data
            this.statusModal = true
            console.log(option, data, key, type)
        },
        archiveTask() {
            this.$swal({
                icon: 'warning',
                text: 'Do you want to delete this task ' + this.model.title,
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    let request = {
                        archived: true,
                        api: "/tasks/" + this.model.id,
                        json_object: true
                    };

                    this.$store.dispatch('postAction', request).then(response => {
                        if (response.code === 200) {
                            this.$store.state.tasksCount--
                            let datasource = this.dataSource();
                            this.gridOptions.api.setServerSideDatasource(datasource);
                            // this.gridOptions.api.applyServerSideTransaction({
                            //     remove: [
                            //         this.tasks[i]
                            //     ]
                            // });
                            // let that=this
                            // this.gridOptions.api.forEachNode(function(rowNode) {
                            //
                            //     if (rowNode.data.id==this.) {
                            //         // arbitrarily update some data
                            //         var updated = rowNode.data;
                            //         updated.gold += 1;
                            //
                            //         // directly update data in rowNode
                            //         rowNode.setData(updated);
                            //     }
                            // });

                            this.cancelCreation()
                        }
                    })
                }
            })
        },
        archive(option, data, key, type) {
            this.selectedTask = data
            this.model = JSON.parse(JSON.stringify(data))
            this.archiveTask()
            console.log(option, data, key, type)
        },
        viewTask(option, data, key, type) {
            this.selectedTask = data
            this.viewModal = true
            console.log(option, data, key, type)
        },
        editTask(option, data, key, type) {
            this.selectedTask = data
            this.editModal = true
            console.log(option, data, key, type)
        },
        closeModal() {
            this.statusModal = false
            this.viewModal = false
        },
        saveStatus() {
            let task = {
                status: this.selectedTask.status,
                api: "/tasks/" + this.selectedTask.id,
                json_object: true
            }
            this.$store.dispatch("postAction", task).then(response => {
                if (response.code === 200) {
                    let count = 0
                    for (let i = 0; i < this.tasks.length; i++) {
                        if (response.data.id === this.tasks[i].id) {
                            this.tasks[i] = response.data
                            this.$forceUpdate()
                        }
                        if (this.tasks[i].status !== 'Done') {
                            count++
                        }
                        this.$store.state.tasksCount = count
                    }
                    this.gridOptions.api.redrawRows();
                    this.closeModal()
                }
            })
        }

    }
}
</script>
<style>
.el-button--success {
    color: white !important;
}

.el-tag--medium-priority {
    background-color: yellow !important;
    border-color: yellow !important;
    color: #fff !important;
}
</style>