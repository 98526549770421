<template>
    <board :span="24" :span_responsive="[ 24, 24 ]" :title="translate('new_patient')" height="auto">
        <h3 class="patient-header">{{ translate('personal_information') }}</h3>
        <div class="col-md-12 p-0" :class="{'has-error': errors.has('name_abb')}">
            <el-radio-group name="name_abb" v-model="model.name_abb" v-validate="'required'">
                <el-radio label="dr">{{ translate('dr') }}.</el-radio>
                <el-radio label="mr">{{ translate('mr') }}.</el-radio>
                <el-radio label="mrs">{{ translate('mrs') }}.</el-radio>
                <el-radio label="miss">{{ translate('miss') }}</el-radio>
            </el-radio-group>
        </div>
        <div class="col-md-12 p-0" style="display: flex">
            <div class="col-md-4 p-0">
                <div class="form-group" :class="{'has-error': errors.has('first_name')}">
                    <label class="col-md-4 col-form-label title-label">{{ translate('first_name') }} :
                        <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="first_name" v-model="model.first_name" v-validate="'required'"></el-input>
                        <span class="help-block" v-if="errors.has('first_name')">
                            {{ translate('this_field_is_required') }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-0">
                <div class="form-group">
                    <label class="col-md-4 col-form-label title-label">{{ translate('middle_name') }} :</label>
                    <div class="col-md-8">
                        <el-input v-model="model.middle_name"></el-input>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-0">
                <div class="form-group" :class="{'has-error': errors.has('last_name')}">
                    <label class="col-md-4 col-form-label title-label">{{ translate('last_name') }} :
                        <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="last_name" v-model="model.last_name" v-validate="'required'"></el-input>
                        <span class="help-block" v-if="errors.has('last_name')">
                            {{ translate('this_field_is_required') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 p-0">
            <div class="col-md-4 p-0">
                <div class="form-group" :class="{'has-error': errors.has('gender')}">
                    <label class="col-md-4 col-form-label title-label">{{ translate('gender') }} :
                        <em>*</em></label>
                    <div class="col-md-8">
                        <el-radio-group name="gender" v-model="model.gender" v-validate="'required'">
                            <el-radio label="male">{{ translate('male') }}</el-radio>
                            <el-radio label="female">{{ translate('female') }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-0">
                <div class="form-group" :class="{'has-error': errors.has('birth_date')}">
                    <label class="col-md-4 col-form-label title-label">{{ translate('date_of_birth') }} :
                        <em>*</em></label>
                    <div class="col-md-8">
                        <el-date-picker
                            name="birth_date"
                            v-validate="'required'"
                            format="dd-MM-yyyy"
                            value-format="dd-MM-yyyy"
                            :picker-options="pickerOptions"
                            v-model="model.birth_date"
                            type="date">
                        </el-date-picker>
                        <span class="help-block" v-if="errors.has('birth_date')">
                            {{ translate('this_field_is_required') }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-md-4 p-0">
                <div class="form-group" :class="{'has-error': errors.has('occupation')}">
                    <label class="col-md-4 col-form-label title-label">{{ translate('occupation') }} :
                        <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="occupation" v-model="model.occupation" v-validate="'required'"></el-input>
                        <span class="help-block" v-if="errors.has('occupation')">
                            {{ translate('this_field_is_required') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 p-0">
            <div class="col-md-8 p-0">
                <div class="form-group" :class="{'has-error': errors.has('marital_status')}">
                    <label class="col-md-2 col-form-label title-label">{{ translate('marital_status') }}:
                        <em>*</em></label>
                    <div class="col-md-10">
                        <el-radio-group name="marital_status" v-model="model.marital_status" v-validate="'required'">
                            <el-radio label="single">{{ translate('single') }}</el-radio>
                            <el-radio label="married">{{ translate('married') }}</el-radio>
                            <el-radio label="divorced">{{ translate('divorced') }}</el-radio>
                            <el-radio label="widowed">{{ translate('widowed') }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>

            <div class="col-md-4 p-0">
                <div class="form-group" :class="{'has-error': errors.has('nationality')}">
                    <label class="col-md-4 col-form-label title-label">{{ translate('nationality') }} :
                        <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="nationality" v-model="model.nationality" v-validate="'required'"></el-input>
                        <span class="help-block" v-if="errors.has('nationality')">
                            {{ translate('this_field_is_required') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <h3 class="patient-header">{{ translate('contact_details') }}</h3>
        <div class="col-md-12 p-0">
            <div class="col-md-4 p-0">
                <div class="form-group" :class="{'has-error': errors.has('mobile_number')}">
                    <label class="col-md-4 col-form-label title-label">{{ translate('mobile_number') }} :
                        <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="mobile_number" class="phone-number-field" v-model="model.mobile_number"
                                  v-validate="'required'"></el-input>
                        <span class="help-block" v-if="errors.has('mobile_number')">
                            {{ translate('this_field_is_required') }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-0">
                <div class="form-group">
                    <label class="col-md-4 col-form-label title-label">{{ translate('phone_number') }} :</label>
                    <div class="col-md-8">
                        <el-input name="phone_number" class="phone-number-field"
                                  v-model="model.phone_number"></el-input>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-0">
                <div class="form-group">
                    <label class="col-md-4 col-form-label title-label">{{ translate('email_address') }} :</label>
                    <div class="col-md-8">
                        <el-input name="email_address" class="phone-number-field"
                                  v-model="model.email_address"></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 p-0">
            <div class="col-md-4 p-0">
                <div class="form-group">
                    <label class="col-md-4 col-form-label title-label"> {{ translate('name_of_guardian_spouse') }}
                        :</label>
                    <div class="col-md-8">
                        <el-input v-model="model.spouse_name"></el-input>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-0">
                <div class="form-group" :class="{'has-error': errors.has('emergency_person_name')}">
                    <label class="col-md-4 col-form-label title-label">
                        {{ translate('contact_person_in_case_of_emergency') }} : <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="emergency_person_name" v-model="model.emergency_person_name"
                                  v-validate="'required'"></el-input>
                        <span class="help-block" v-if="errors.has('emergency_person_name')">
                            {{ translate('this_field_is_required') }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-md-4 p-0">
                <div class="form-group" :class="{'has-error': errors.has('emergency_phone_number')}">
                    <label class="col-md-4 col-form-label title-label">{{ translate('phone_number') }}:
                        <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="emergency_phone_number" class="phone-number-field"
                                  v-model="model.emergency_phone_number"
                                  v-validate="'required'"></el-input>
                        <span class="help-block" v-if="errors.has('emergency_phone_number')">
                            {{ translate('this_field_is_required') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <h3 class="patient-header">{{ translate('residency_status') }}</h3>
        <div class="col-md-12 p-0">
            <div class="form-group" :class="{'has-error': errors.has('residency')}">
                <label class="col-md-1 col-form-label title-label">{{ translate('residency') }} : <em>*</em>
                </label>
                <div class="col-md-8">
                    <el-radio-group name="residency" v-model="model.residency" v-validate="'required'">
                        <el-radio label="resident">{{ translate('resident') }}</el-radio>
                        <el-radio label="non_resident">{{ translate('non_resident') }}</el-radio>
                    </el-radio-group>
                </div>
            </div>
        </div>
        <div class="col-md-12 p-0" v-if="model.residency==='resident'">
            <div class="form-group" :class="{'has-error': errors.has('resident')}">
                <label class="col-md-1 col-form-label title-label">{{ translate('resident') }} :
                    <em>*</em></label>
                <div class="col-md-1">
                    <el-radio-group name="resident" v-model="model.resident" v-validate="'required'">
                        <el-radio label="uae_national">{{ translate('uae_national') }}</el-radio>
                        <el-radio label="non_uae_national_residency_visa">
                            {{ translate('non_uae_national_residency_visa') }}
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
        </div>
        <div class="col-md-12 p-0" v-if="model.residency==='resident'">
            <div class="form-group" :class="{'has-error': errors.has('staying_in')}">
                <label class="col-md-1 col-form-label title-label">{{ translate('staying_in') }} :
                    <em>*</em></label>
                <div class="col-md-11">
                    <el-radio-group name="staying_in" v-model="model.staying_in" v-validate="'required'">
                        <el-radio label="abu_dhabi">{{ translate('abu_dhabi') }}</el-radio>
                        <el-radio label="dubai">{{ translate('dubai') }}</el-radio>
                        <el-radio label="sharjah">{{ translate('sharjah') }}</el-radio>
                        <el-radio label="ajman">{{ translate('ajman') }}</el-radio>
                        <el-radio label="al_ain">{{ translate('al_ain') }}</el-radio>
                        <el-radio label="fujairah">{{ translate('fujairah') }}</el-radio>
                        <el-radio label="ras_al_khaimah">{{ translate('ras_al_khaimah') }}</el-radio>
                        <el-radio label="umm_al_quwain">{{ translate('umm_al_quwain') }}</el-radio>
                    </el-radio-group>
                </div>
            </div>
        </div>
        <div class="col-md-12 p-0" v-if="model.residency==='non_resident'">
            <div class="col-md-5 p-0">
                <div class="form-group" :class="{'has-error': errors.has('country_of_residence')}">
                    <label class="col-md-4 col-form-label title-label">{{ translate('country_of_residence') }} :
                        <em>*</em></label>
                    <div class="col-md-8">
                        <el-input name="country_of_residence" v-model="model.country_of_residence"
                                  v-validate="'required'"></el-input>
                        <span class="help-block" v-if="errors.has('country_of_residence')">
                            {{ translate('this_field_is_required') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 p-0">
            <div class="col-md-4 p-0">
                <div class="form-group" :class="{'has-error': errors.has('identity_proof')}">
                    <label class="col-md-4 col-form-label title-label">{{ translate('identity_proof') }} :
                        <em>*</em></label>
                    <div class="col-md-3">
                        <el-radio-group name="identity_proof" v-model="model.identity_proof" v-validate="'required'">
                            <el-radio label="passport_id_number">{{ translate('passport_id_number') }}
                            </el-radio>
                            <el-radio label="emirates_id_number" style="margin-top: 35px">
                                {{ translate('emirates_id_number') }}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <el-input v-model="model.passport_id_number_field"
                          :disabled="model.identity_proof==='passport_id_number'?null:'disabled'"></el-input>
                <el-input class="margin-top-10" v-model="model.emirates_id_number_field"
                          :disabled="model.identity_proof==='emirates_id_number'?null:'disabled'"></el-input>
            </div>
        </div>

        <div class="col-md-12 p-0" style="margin-top: 20px;">
            <div class="form-group" :class="{'has-error': errors.has('payment_mode')}">
                <label class="col-md-1 col-form-label title-label">{{ translate('mode_of_payment') }}:
                    <em>*</em></label>
                <div class="col-md-3">
                    <el-radio-group name="payment_mode" v-model="model.payment_mode" v-validate="'required'">
                        <el-radio label="cash_credit">{{ translate('cash_credit') }}</el-radio>
                        <el-radio label="insurance_company_name">
                            {{ translate('insurance_company_name') }}
                        </el-radio>
                    </el-radio-group>
                </div>
                <div class="col-md-4">
                    <el-input name="insurance_company_name" v-model="model.insurance_company_name"></el-input>
                </div>
            </div>
        </div>
        <div class="col-md-4 p-0">
            <div class="form-group" :class="{'has-error': errors.has('hear_about_us')}">
                <label class="col-md-4 col-form-label title-label">{{ translate('how_did_you_hear_about_us') }} :
                    <em>*</em></label>
                <div class="col-md-8">
                    <multiselect name="hear_about_us" v-model="model.hear_about_us" :options="hearList"
                                 placeholder="..." v-validate="'required'" label="value"></multiselect>
                    <span class="col-md-12 help-block" v-if="errors.has('hear_about_us')">
                        {{ translate('this_field_is_required') }}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-4 p-0">
            <div class="form-group">
                <label class="col-md-4 col-form-label title-label">{{ translate('referred_from_dr_clinics') }}
                    :</label>
                <div class="col-md-8">
                    <el-input v-model="model.referred_from"></el-input>
                </div>
            </div>
        </div>
        <h3 class="patient-header">{{ translate('general_consent_from') }}</h3>
        <div class="col-md-12 padding-top-10" v-if="lang==='ar'">
            <p>
                أطلب الرعاية الطبية والعلاج في هذه المنشأة وأوافق على قبول الخدمات و الإجراءات العلاجيه التي قد
                تشخص
                حالتي الطبية.<br>
                <br>أتفهم أن قبولي على هذه الخدمات يدعى نموذج الموافقة العام وأنه يتضمن تقييم روتيني أو إجراء
                (إجراءات) أو علاج / (علاجات) مثل سحب الدم ، الفحص البدني ، إجراء واستخدام التخدير الموضعي وغيره
                من
                الإجراءات التي تتطلب الحد الأدنى من التدخل الجراحي.<br>
                <br>أوافق على أنه قد تكون هناك نماذج موافقة مستنيرة&nbsp;مختلفة لبعض الإجراءات التشخيصية والجراحية
                المحددة.
                <br>أفهم أن هذه الخدمات ستقدم لي من قبل الأطباء والممرضين والمساعدين الطبيين ومقدمي الرعاية
                الصحية.
                <br>وأقر كذلك بأن نتائج العلاجات الطبية والإجراءات الجراحية قد لا تكون متوقعة بشكل كاف، وأن
                توقيعي
                على أي من نماذج الموافقة المستنيرة هو بمثابة إقرار على تفهمي أنه لا يمكن لفريق نوفومد ولا للفريق
                الطبي أن يقدم أي ضمان أو تأكيد للنتائج.
                <br>يتم الاحتفاظ بكافة المعلومات الطبية بسرية تامة. لن يتم الكشف عن أي شيء لأي شخص أو (أشخاص)
                بدون
                موافقتي الخطية. إذا كنت تحت بند التأمين ، فأنا أصرح لشركة نوفومد بالكشف لشركة التأمين الخاصة بي
                أو
                هيئة الصحة التنظيمية التي قد تكون مسؤولة كليًا أو جزئيًا عن الرسوم و كل أو جزء من سجلاتي الطبية
                إذا
                لزم الأمر لسداد المبلغ المترتب على خدمات الرعاية الصحية المقدمة.
                <br>أتفهم أنه ستقوم شركة نوفومد بتقديم مطالبة لشركة التأمين الخاصة بي ، ولكنني مسؤول مالياً عن
                جميع
                الرسوم التي لا تغطيها شركة التأمين وفقًا للقوانين دولة الإمارات العربية المتحدة.
                <br>أوافق أيضًا على أن بأمكان نوفومد الاستفادة من معلوماتي الطبية ، أو الفصح عن جميع معلوماتي
                الطبية
                أو جزء منها لمقدمي الرعاية الصحية الآخرين الذين استشارهم طبيبي أو حسب ما تقتضيه الحاجة.
                <br>أتحمل المسؤولية الكاملة عن جميع العناصر الخاصة بالممتلكات الفردية والأشياء الثمينة والعناصر
                الشخصية الأخرى.
                لقد تلقيت نسخة من كتيب حقوق المريض ومسؤولياته من مراكز نوفومد.<br>
                أدرك أن اتفاقي لقبول جميع هذه الخدمات سيظل ساريًا الى أن أذكر أنني لم أعد أرغب في الحصول على هذه
                الخدمات أو حتى أن أنتهي من العلاج.<br>
                في حالة الطوارئ ، أوافق على تحويلي إلى أقرب مرفق مجهز.
            </p>
        </div>
        <div class="col-md-12 padding-top-10" v-else>
            <p>
                I am asking for medical care and treatment at this facility and agree to accept services which
                may
                diagnose my medical condition, procedures to treat my condition and medical care.<br>
                I understand that my agreement to accept these services is called a General Consent and that it
                includes
                any routine assessment, procedure(s) or treatment(s) such as blood drawing, physical
                examination,
                administration of medication(s), diagnostic procedure(s), use of local anesthesia and other
                non-invasive
                procedures.<br>
                I do acknowledge that different declarations (Informed consents) may be needed for some specific
                diagnostic and surgical procedures<br>
                I understand that these services will be provided to me by physicians, nurses, physician
                assistants
                and
                other health care providers, as registered and privileged by Law, and Novomed governance
                framework<br>
                I further acknowledge that the results of medical treatments and surgical procedures may not be
                predictable, and that my signature on any informed consent form acknowledges my understanding,
                and
                comprehension that neither NOVOMED nor the attending medical team may be able to give any
                guarantee
                or
                confirmation of outcomes.<br>
                All medical information is held in the strictest confidence. Nothing will be disclosed to any
                person
                (s)
                without my written consent. If under insurance, I authorize NOVOMED to disclose to my insurance
                company
                or Regulatory Health Authority that may be liable all or part of NOVOMED charges, all or part of
                my
                medical records if necessary to process payments for the health care services provided. I
                understand
                that as a courtesy to me, NOVOMED will file an insurance claim with my insurance company but
                that I
                am
                financially responsible for all charges not covered by insurance company as per the UAE laws and
                regulation.<br>
                I also authorize NOVOMED to utilize my medical information, or to release all or part of my
                medical
                information to other health care providers consulted by my physician or NOVOMED, as may be
                necessary.<br>
                I assume full responsibility for all items of individual property, valuables and other personal
                items.<br>
                I have received a copy of NOVOMED patient's right &amp; responsibilities.<br>
                I understand that my agreement to accept all these services will remain in effect unless I say
                that
                I no
                longer want these services or until my treatment is completed.<br>
                In case of Emergency, I agreed to be transferred to the closest equipped Facility.</p>
        </div>
        <div class="col-md-12 p-0">
            <VueSignaturePad width="318px" height="318px" ref="signaturePad" style="border: solid 1px black"/>
            <button @click="undoSig">{{ translate('undo') }}</button>

        </div>
        <div class="col-md-12 p-0">
            <a class="link-inline success save-button" @click="create"><i class=" fa fa-save"></i>
                {{ translate('create') }}</a>
        </div>

        <h3 class="patient-header">{{ translate('guidelines') }}</h3>
        <div class="col-md-12" v-if="lang==='ar'">
            <p>
                يجب أن يفي وصي المريض أو البديل بالمعايير القانونية التالية:<br>
                يجب أن يبلغ 18 عام أو أكثر . للأب أو الأم الحق في التوقيع نيابة عن ابنهما /ابنتهما بغض النظر عن
                سنهما. يجب أن يكون قادراً على فهم محتوى الاستمارة وتوقيعها. يستطيع القراءة والكتابة بشكل صحيح ،
                وإلا
                يمكن أن يساعده شخص آخر ليشرح المحتويات ويوقع على النموذج معه كشاهد. كل من الذكور والإناث تعتبر
                متساوية في الموافقة.<br>
                <br>تعريف الوصي:
                <br>الأب أو الأم أو الأخ أو الأخت أو العم أو الجدة أو الأقارب أو أي من الحموين. يحل الزوج محل
                الأب
                وأولئك الذين يتبعونه.
                <br>إذا لم يكن للمريض أقارب مقيمين في دولة الإمارات العربية المتحدة فيمكن لكفيله أو كفيل بديل ،
                أو
                لصديق أن يقوم بالتوقيع نيابة عنه.
                الإعفاءات:<br>
                <br>• يعفى من إعطاء الموافقة مرضى الطوارئ غير الواعين / غير المؤهلين الذين ليس لديهم ولي أمر أو
                مقدم
                موافقة بديل في الوقت الذي يتم فيه إدخاله في وحدة الطوارئ
                • يجب على الزوج أن يوقع شخصيًا على النموذج المتعلق بالعلاجات والتدخلات الجراحية التي قد تؤثر على
                خصوبة زوجته في المستقبل. تطبق قوانين دولة الإمارات العربية المتحدة فقط على أي نزاع قد ينشأ حول
                العلاج أو التعامل مع المريض. المحاكم الإماراتية مختصة حصرياً في اتخاذ القرار بشأن هذا النزاع
                والحكم
                عليه.<br>
                <br>المعلومات:
                <br>• يجب أن يجيب أعضاء الفريق الطبي على أسئلة المريض / الوصي / البديل بطريقة بسيطة وسهلة الفهم
                وموضوعية ، دون المبالغة أو الوعود. والهدف هو تزويدهم بالمعلومات الكافية عن جميع الإجراءات
                والعلاجات
                التشخيصية ، فضلاً عن أي آثار جانبية ومضاعفات.
                • يجب أن يكون هذا: واضح ، وعلمي ، ومفهوم بسهولة وكافي لمساعدته في اتخاذ القرارات. يجب ألا تحتوي
                هذه
                المعلومات على الوعود أو التوقعات.<br>
                لغة<br>
                • يجب أن تكون هناك لغة مشتركة بين المريض / الأوصياء أو مقدمي قبول النموذج والطبيب لفهم محتوى. قد
                يتم
                الاستعانة بمترجم وفقاً لما تقتضيه الحاجة، وعليه، يجب أن يوقع المترجم على الإقرار<br>
            </p>
        </div>
        <div class="col-md-12" v-else>
            <p>Patient guardian or substitute consent giver must meet the following legal criteria: <br>
                • Shall be 18years old or more. A father or mother have the right to sign on behalf of their son
                or
                daughter regardless of their age. Shall be of sound mind and body. Shall be able to understand
                the
                contents of the declaration and sign it. Can read and write properly, otherwise may be assisted
                by
                another person who explains the contents and sign the declaration with him/her as witness. Both
                males
                and females are considered equal in consenting.<br>
                <br>
                Definition of the Guardian:<br>
                • Father, mother, brother, sister, uncle, grandmother, relatives or any parent in law. The
                husband
                supersedes the father and those who follow under him.<br>
                <br>
                Definition of Substitute Consent Giver:<br>
                • If the patient has no relatives residing in UAE: his/her sponsor or a substitute of the
                sponsor,
                then
                a friend may sign on his/her behalf.<br>
                <br>
                Exemptions:<br>
                • An unconscious/incompetent emergency patient who has no guardian or substitute consent giver
                at
                the
                time he/she is admitted to the accident and emergency unit shall be exempted from giving
                consent.<br>
                • Husband must sign in person the declaration related to treatments and surgical interventions
                that
                may
                affect his wife's fertility in the future. Only the UAE laws shall be applied to any dispute
                that
                may
                arise regarding the treatment or dealing with the patient. The UAE courts are exclusively
                competent
                to
                decide and judge on such dispute.<br>
                Information &amp; Question:<br>
                • Members of the medical team should answer patient's/guardian's/substitute consent giver's
                questions in
                a simple, easy to understand, and objective manner, without exaggeration or promises. The aim is
                to
                give
                them enough information on all aspects of the diseases, diagnostic measures and treatments, as
                well
                as
                any side effects and usual complications. This should be: clear, scientific, easily understood
                and
                enough to assist him/her in taking decisions. This information shall not contain and promises or
                expectations.<br>
                Language:<br>
                • There should be a common language between the patient/guardians or substitute consent givers
                and
                physician in order to understand content of the declaration. A translator may be used, if
                necessary,
                who
                must also sign the declaration.<br>
            </p>
        </div>
    </board>
</template>
<script>
import board from "@/components/CommonComponents/Board";
import Vue from 'vue';
import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);

export default {
    data() {
        return {
            lang: 'en',
            model: {},
            translations: {},
            hearList: [],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
        }
    },
    beforeMount() {
        if (this.$route.query.lang === 'ar') {
            this.lang = this.$route.query.lang
            this.$store.state.language_type = this.$route.query.lang
            this.translations = {
                new_patient: 'إضافة مريض جديد',
                this_field_is_required: 'هذه الخانة مطلوبة',
                branch: 'الفرع',
                dr: 'د',
                mr: 'السيد',
                mrs: 'السيدة',
                miss: 'الآنسة',
                first_name: 'الإسم الأول',
                middle_name: 'اسم الأب',
                last_name: 'العائلة',
                gender: 'الجنس',
                date_of_birth: 'تاريخ الميلاد',
                occupation: 'المهنة',
                marital_status: 'الحالة الإجتماعية',
                single: 'أعزب',
                married: 'متزوج',
                divorced: 'مطلق',
                widowed: 'أرمل',
                nationality: 'الجنسية',
                mobile_number: 'رقم الجوال',
                phone_number: 'رقم الهاتف',
                email_address: 'البريد الإلكتروني',
                name_of_guardian_spouse: 'اسم ولي الأمر /الزوج/الزوجة',
                contact_person_in_case_of_emergency: 'الشخص المطلوب الإتصال به في حال الطوارئ',
                residency: 'الإقامة',
                resident: 'مقيم',
                uae_national: 'الجنسية الإمارتية',
                non_uae_national_residency_visa: 'جنسية أخرى (تأشرة إقامة)',
                staying_in: 'مدينة الإقامة',
                abu_dhabi: 'أبو ظبي',
                dubai: 'دبي',
                sharjah: 'الشارقة',
                ajman: 'عجمان',
                al_ain: 'العين',
                fujairah: 'الفجيرة',
                ras_al_khaimah: 'رأس الخيمة',
                umm_al_quwain: 'أم القيوين',
                country_of_residence: 'بلد الإقامة',
                identity_proof: 'بيانات التعريف',
                passport_id_number: 'رقم جواز السفر',
                mode_of_payment: 'طريقة الدفع',
                cash_credit: 'بطاقة/نقدي',
                insurance_company_name: 'اسم شركة التأمين',
                how_did_you_hear_about_us: 'كيف سمعت عن المركز؟',
                referred_from_dr_clinics: 'بإحالة من طبيب/عيادات',
                personal_information: 'معلومات شخصية',
                contact_details: 'معلومات الإتصال',
                residency_status: 'نوع الإقامة',
                family_friend: 'العائلة-صديق',
                newspaper: 'جريدة',
                snapchat: 'سناب شات',
                instagram: 'انستاجرام',
                facebook: 'فيسبوك',
                twitter: 'تويتر',
                google: 'جوجل',
                youTube: 'يوتيوب',
                tiktok: 'تيك توك',
                other: 'أخرى',
                emirates_id_number: 'رقم الهوية الإمارتية',
                general_consent_from: 'نموذج الموافقة',
                undo: 'إرجاع',
                guidelines: 'القواعد الارشادية',
                male: 'ذكر',
                female: 'أنثى',
                non_resident: 'غير مقيم',
                create: 'إنشاء'
            }
        } else {
            this.translations = {
                new_patient: 'New Patient',
                this_field_is_required: 'This field is required',
                branch: 'Branch',
                dr: 'Dr',
                mr: 'Mr',
                mrs: 'Mrs',
                miss: 'Miss',
                first_name: 'First name',
                middle_name: 'Middle name',
                last_name: 'Last name',
                gender: 'Gender',
                date_of_birth: 'Date of birth',
                occupation: 'Occupation',
                marital_status: 'Marital status',
                single: 'Single',
                married: 'Married',
                divorced: 'Divorced',
                widowed: 'Widowed',
                nationality: 'Nationality',
                mobile_number: 'Mobile number',
                phone_number: 'Phone number',
                email_address: 'email address',
                name_of_guardian_spouse: 'Name of guardian / spouse',
                contact_person_in_case_of_emergency: 'Contact person in case of emergency',
                residency: 'Residency',
                resident: 'Resident',
                uae_national: 'UAE National',
                non_uae_national_residency_visa: 'Non-UAE national (Residency Visa)',
                staying_in: 'Staying in',
                abu_dhabi: 'Abu Dhabi',
                dubai: 'Dubai',
                sharjah: 'Sharjah',
                ajman: 'Ajman',
                al_ain: 'Al Ain',
                fujairah: 'Fujairah',
                ras_al_khaimah: 'Ras Al Khaimah',
                umm_al_quwain: 'Umm Al Quwain',
                country_of_residence: 'Country of residence',
                identity_proof: 'Identity proof',
                passport_id_number: 'Passport ID number',
                mode_of_payment: 'Mode of payment',
                cash_credit: 'Cash / Credit',
                insurance_company_name: 'Insurance company name',
                how_did_you_hear_about_us: 'How did you hear about us?',
                referred_from_dr_clinics: 'Referred from Dr clinics',
                personal_information: 'Personal Information',
                contact_details: 'Contact Details',
                residency_status: 'Residency Status',
                family_friend: 'Family / Friend',
                newspaper: 'Newspaper',
                snapchat: 'Snapchat',
                instagram: 'Instagram',
                facebook: 'Facebook',
                twitter: 'Twitter',
                google: 'Google',
                youTube: 'YouTube',
                tiktok: 'Tiktok',
                other: 'Other',
                emirates_id_number: 'Emirates ID Number',
                general_consent_from: 'General Consent from',
                undo: 'Undo',
                guidelines: 'Guidelines',
                male: 'Male',
                female: 'Female',
                non_resident: 'Non-Resident',
                create: 'Create'
            }
        }
        let htmlEl = document.querySelector("html");
        if (this.$route.query.lang === 'ar') {
            this.toggleBodyClass('add', 'rtl');
            this.toggleBodyClass('remove', 'ltr');
            htmlEl.setAttribute('dir', 'rtl');
        } else {
            this.toggleBodyClass('remove', 'rtl');
            this.toggleBodyClass('add', 'ltr');
            htmlEl.setAttribute('dir', 'ltr');
        }
    },
    beforeDestroy() {
        let htmlEl = document.querySelector("html");
        this.toggleBodyClass('remove', 'rtl');
        this.toggleBodyClass('add', 'ltr');
        htmlEl.setAttribute('dir', 'ltr');
    },
    mounted() {
        this.hearList = [
            {
                key: 'family_friend',
                value: this.translate('family_friend')
            },
            {
                key: 'newspaper',
                value: this.translate('newspaper')
            },
            {
                key: 'snapchat',
                value: this.translate('snapchat')
            },
            {
                key: 'instagram',
                value: this.translate('instagram')
            },
            {
                key: 'facebook',
                value: this.translate('facebook')
            },
            {
                key: 'twitter',
                value: this.translate('twitter')
            },
            {
                key: 'google',
                value: this.translate('google')
            },
            {
                key: 'youTube',
                value: this.translate('youTube')
            },
            {
                key: 'tiktok',
                value: this.translate('tiktok')
            },
            {
                key: 'other',
                value: this.translate('other')
            }
        ]
    },
    components: {
        board
    },
    methods: {
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;

            if (addRemoveClass === 'add') {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        translate(value) {
            if (!this.translations[value]) {
                console.log(value)
                return value
            }
            return this.translations[value];
        },
        create() {
            let signature = this.$refs.signaturePad.saveSignature();
            console.log(signature);
            this.$validator.validate('name_abb', this.model.name_abb);
            this.$validator.validate('first_name', this.model.first_name);
            this.$validator.validate('last_name', this.model.last_name);
            this.$validator.validate('gender', this.model.gender);
            this.$validator.validate('birth_date', this.model.birth_date);
            this.$validator.validate('occupation', this.model.occupation);
            this.$validator.validate('marital_status', this.model.marital_status);
            this.$validator.validate('nationality', this.model.nationality);
            this.$validator.validate('mobile_number', this.model.mobile_number);
            this.$validator.validate('emergency_person_name', this.model.emergency_person_name);
            this.$validator.validate('emergency_phone_number', this.model.emergency_phone_number);
            this.$validator.validate('residency', this.model.residency);
            if (this.model.residency === 'resident') {
                this.$validator.validate('resident', this.model.resident);
                this.$validator.validate('staying_in', this.model.staying_in);
            } else if (this.model.residency === 'non_resident') {
                this.$validator.validate('country_of_residence', this.model.country_of_residence);
            }
            this.$validator.validate('identity_proof', this.model.identity_proof);
            this.$validator.validate('payment_mode', this.model.payment_mode);
            this.$validator.validate('hear_about_us', this.model.hear_about_us);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, [
                    'name_abb',
                    'first_name',
                    'last_name',
                    'gender',
                    'birth_date',
                    'occupation',
                    'marital_status',
                    'nationality',
                    'mobile_number',
                    'emergency_person_name',
                    'emergency_phone_number',
                    'residency',
                    'resident',
                    'staying_in',
                    'country_of_residence',
                    'identity_proof',
                    'country_of_residence',
                    'payment_mode',
                    'hear_about_us']);
                if (valid) {
                    let request = JSON.parse(JSON.stringify(this.model))
                    request.hear_about_us = request.hear_about_us.key
                    if (this.model.identity_proof === 'passport_id_number') {
                        request.emirates_id_number_field = ''
                    } else {
                        request.passport_id_number_field = ''
                    }
                    request.api = "/patients";
                    request.signature = signature.data
                    request.branch_id = this.$route.params.branch_id
                    request.json_object = true
                    this.$store.dispatch("postAction", request).then(response => {
                        if (response.code === 200) {
                            this.model = {}
                        }
                    })
                }
            })
        },
        undoSig() {
            this.$refs.signaturePad.undoSignature();
        }
    }
}
</script>
<style lang="scss">
.patient-header {
    color: white;
    display: inline-block;
    background-color: #2EA092;
    width: 100%;
    padding: 8px;
}
</style>