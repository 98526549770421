<template>
    <board :span="24" :span_responsive="[ 24, 24 ]" title="Requests" height="auto"
           v-if="$store.state.permissions.view_requests">
        <div class="col-md-12 margin-bottom-10 p-0" v-if="$store.state.permissions.add_requests">
            <el-button @click="addNewRequest" class="save-updates-button pull-right">Add Request</el-button>
        </div>
        <div class="col-md-12 with-dropdown-filter-button">
            <div class="grid-filter-trigger" @click="filterTrigger">
                <i :class="floating_filter?'fas fa-filter':'far fa-filter'"></i>
            </div>
        </div>
        <ag-grid-vue :pagination="true"
                     :localeText="$store.state.translations"
                     :enableRtl="$store.state.ag_grid_rtl"
                     :grid-options="gridOptions"
                     rowHeight="60"
                     :maxBlocksInCache="10"
                     :cacheBlockSize="10"
                     @grid-ready="onGridReady"
                     :floatingFilter="floating_filter"
                     :frameworkComponents="frameworkComponents"
                     :paginationPageSize="10"
                     class="ag-theme-material"
                     :columnDefs="columnDefs"
                     :rowData="requests">
        </ag-grid-vue>

        <b-modal v-model="createModal" id="register_modal_id" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ $root.translate('create_request') }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Branch :</label>
                        <div class="col-md-8">
                            <multiselect name="branch" v-model="model.branch" :options="branchList"
                                         placeholder="Select branch.." :disabled="user.branch==null?null:'disabled'"
                                         label="title" v-validate="'required'"></multiselect>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Applicant :</label>
                        <div class="col-md-8">
                            {{ user.name }}
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6" :class="{'has-error': errors.has('doctor_name')}">
                        <label class="col-md-4 col-form-label title-label">Doctor : <em>*</em></label>
                        <div class="col-md-8">
                            <multiselect name="doctor_name" v-model="model.doctor_name" :options="doctorsList"
                                         v-validate="'required'" label="full_name" track-by="id"
                                         placeholder="Select .."></multiselect>
                            <span class="help-block" v-if="errors.has('doctor_name')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>

                    <div class="col-md-6" :class="{'has-error': errors.has('request_type')}">
                        <label class="col-md-4 col-form-label title-label"> Type : <em>*</em></label>
                        <div class="col-md-8">
                            <multiselect name="request_type" v-model="model.type" :options="requestType"
                                         placeholder="Select .." v-validate="'required'"></multiselect>
                            <span class="help-block" v-if="errors.has('request_type')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6" :class="{'has-error': errors.has('assigned_to')}">
                        <label class="col-md-4 col-form-label title-label"> Assigned To : <em>*</em></label>
                        <div class="col-md-8">
                            <multiselect name="assigned_to" v-model="model.assigned_to" :options="users"
                                         placeholder="Select .." track-by="user_id" :multiple="true"
                                         label="name" v-validate="'required'"></multiselect>
                            <span class="help-block" v-if="errors.has('assigned_to')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">License :</label>
                        <div class="col-md-8">
                            <el-upload
                                :multiple="false"
                                ref="license_uploader"
                                class="upload-demo"
                                action="#"
                                :auto-upload="false"
                                :file-list="licenseFiles"
                                :on-change="changeLicense"
                                :on-remove="removeFile">
                                <!--                                accept=".pdf, .doc, .docx"-->
                                <el-button size="small" type="primary">Upload License</el-button>
                            </el-upload>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-12">
                        <label class="col-md-2 col-form-label title-label"> Description :</label>
                        <div class="col-md-10">
                            <el-input type="textarea" :autosize="{ minRows: 4}"
                                      v-model="model.description"></el-input>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Creation Date :</label>
                        <div class="col-md-8">
                            {{ new Date().toLocaleString() }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-md-4 col-form-label title-label">Status :</label>
                        <div class="col-md-8">
                            <el-button type="warning">Upcoming</el-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div>
                    <el-button class="clear-button" v-if="model.id && $store.state.permissions.delete_requests"
                               @click="archiveRequest"> Archive
                    </el-button>
                    <el-button class="clear-button"
                               @click="cancelCreation"> Close
                    </el-button>
                    <el-button class="save-updates-button pull-right"
                               @click="createRequest"> {{ model.id ? "Save Updates" : "Create" }}
                    </el-button>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="viewModal" id="view_request_modal" size="xl" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> View Request </h4>
            </div>

            <viewRequestForm :selectedRequest="selectedRequest"></viewRequestForm>
        </b-modal>

        <b-modal v-model="statusModal" size="md" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ $root.translate('status') }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <div class="form-group">
                    <div class="col-md-12">
                        <label class="col-md-4 col-form-label title-label">Status : <em>*</em></label>
                        <el-radio v-model="selectedRequest.request_status" label="Cancelled">Cancelled</el-radio>
                        <el-radio v-model="selectedRequest.request_status" label="Executed">Executed</el-radio>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <el-button class="clear-button"
                           @click="closeModal"> Close
                </el-button>
                <el-button class="save-updates-button pull-right"
                           @click="saveStatus"> Save Updates
                </el-button>
            </div>
        </b-modal>
    </board>
</template>

<script>
import board from "@/components/CommonComponents/Board";
import {AgGridVue} from "ag-grid-vue";
import optionsCell from '@/components/CommonComponents/agGrid/AgGridActionsCell.vue';
import Vue from "vue";
import viewRequestForm from './view'

export default {
    data() {
        return {
            branchList: [],
            user: Vue.$cookies.get('user'),
            model: {
                assigned_to: [],
                request_to: 'Marketing',
                branch: ''
            },
            toList: ['Marketing'],
            requestType: [
                'SMS Campaign',
                'Mirrors',
                'Business Card',
                'Voucher / Offers'
            ],
            doctorsList: [],
            createModal: false,
            viewModal: false,
            requests: [],
            users: [],
            selectedUser: {},
            floating_filter: false,
            frameworkComponents: null,
            gridApi: {},
            gridOptions: {
                rowModelType: 'serverSide',
                serverSideStoreType: 'partial',
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                }, components: {
                    'optionsCell': this.optionsCell,
                    'statusField': this.statusField,
                    'viewLicense': this.viewLicense,
                }
            },
            columnDefs: [],
            licenseFiles: [],
            selectedRequest: {
                request_status: ''
            },
            statusModal: false
        }
    },
    mounted() {
        this.columnDefs = [
            {
                "headerName": "#",
                "width": 60,
                "cellRenderer": function (params) {
                    return params.rowIndex + 1;
                },
                "sortable": false,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('branch'),
                "field": 'branch',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.branch
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.branch
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": "Applicant",
                "field": 'applicant',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.applicant
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.applicant
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": "Assignee",
                "field": 'assigned_to',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.assigned_to
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.assigned_to
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": "Type",
                "field": 'type',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.type
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.type
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('doctor'),
                "field": 'doctor_name',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.doctor_name
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.doctor_name
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('license'),
                "field": 'license',
                filter: 'agTextColumnFilter',
                cellRenderer: 'viewLicense',
                filterValueGetter: params => {
                    if (params.data)
                        if (params.data.license && params.data.license.length > 0) {
                            let name = params.data.license[0].initial_name
                            for (let i = 1; i < params.data.license.length; i++) {
                                name = name + '-' + params.data.license[i].initial_name
                            }
                            return name
                        }
                },
                valueGetter: params => {
                    if (params.data)
                        if (params.data.license && params.data.license.length > 0) {
                            let name = params.data.license[0].initial_name
                            for (let i = 1; i < params.data.license.length; i++) {
                                name = name + '-' + params.data.license[i].initial_name
                            }
                            return name
                        }
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('status'),
                "field": 'request_status',
                cellRenderer: 'statusField',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data)
                        return params.data.request_status
                },
                valueGetter: params => {
                    if (params.data)
                        return params.data.request_status
                },
                "filterParams": {
                    apply: true,
                    buttons: ['apply'],
                    newRowsAction: 'keep',
                    filterOptions: ['equals']
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                headerName: this.$root.translate('action'),
                width: 100,
                cellRendererFramework: optionsCell,
                cellRendererParams: {
                    props: [
                        {
                            value: 'view',
                            type: 'action',
                            onClick: this.viewRequest
                        }
                    ]
                }
            }
        ]
        if (this.$store.state.permissions.edit_requests) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'Edit',
                    type: 'action',
                    onClick: this.editRequest
                }
            )
        }
        if (this.$store.state.permissions.delete_requests) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'Archive',
                    type: 'action',
                    onClick: this.archiveRequestTable
                }
            )
        }
        if (this.$store.state.permissions.send_request_reminder) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'send_reminder',
                    type: 'action',
                    onClick: this.sendReminder
                }
            )
        }
        if (this.$store.state.permissions.change_request_status) {
            this.columnDefs[this.columnDefs.length - 1].cellRendererParams.props.push(
                {
                    value: 'update_status',
                    type: 'action',
                    onClick: this.updateStatus
                }
            )
        }
        this.$store.dispatch('getAction', "/users").then(response => {
            if (response.code === 200) {
                this.users = response.data
            }
        })
        this.$store.dispatch('getAction', "/doctor").then(response => {
            if (response.code === 200) {
                this.doctorsList = response.data
            }
        })
        this.$store.dispatch('getAction', "/branch").then(response => {
            if (response.code === 200) {
                this.branchList = response.data
            }
        })
    },
    components: {
        board,
        AgGridVue,
        viewRequestForm
    },
    methods: {
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            let datasource = this.dataSource();
            params.api.setServerSideDatasource(datasource);
        },
        dataSource() {
            let that = this;
            return {
                getRows(params) {
                    let filter = {};
                    if (params.request.filterModel) {
                        let keys = Object.keys(params.request.filterModel)
                        keys.forEach(key => {
                            filter[key] = params.request.filterModel[key].filter
                        })
                    }
                    let gridSort = []
                    if (params.request.sortModel) {
                        gridSort = params.request.sortModel
                    }
                    let api = "/request" + "?filter=" + JSON.stringify(filter) + "&sort=" + JSON.stringify(gridSort)

                    that.$store.dispatch('getAction', api).then(response => {
                        if (response.code === 200) {
                            that.requests = response.data
                            setTimeout(function () {
                                params.successCallback(response.data.data, response.data.total);
                            }, 100)

                        }
                    })
                }
            }
        },
        archiveRequest() {
            this.$swal({
                icon: 'warning',
                text: 'Do you want to delete this request',
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    let request = {
                        json_object: true,
                        api: "/request/" + this.model.id,
                        archived: true
                    }
                    this.$store.dispatch("postAction", request).then(response => {
                        if (response.code === 200) {
                            this.$store.state.requestsCount--
                            let datasource = this.dataSource();
                            this.gridOptions.api.setServerSideDatasource(datasource);
                            this.cancelCreation()
                            this.$forceUpdate()
                        }
                    })
                }
            })
        },
        statusField(params) {
            if (!params.data) {
                return
            }
            if (params.data.request_status === 'Executed') {
                return '<button type="button" class="el-button el-button--success" style="width: 130px;"><span style="color: white">' + params.data.request_status + '</span></button>'
            } else if (params.data.request_status === 'Cancelled') {
                return '<button type="button" class="el-button el-button--danger" style="width: 130px;"><span>' + params.data.request_status + '</span></button>'
            } else {
                return '<button type="button" class="el-button el-button--warning" style="width: 130px;"><span style="color: white">' + params.data.request_status + '</span></button>'
            }
        },
        viewLicense(params) {
            if (!params.data) {
                return
            }
            if (params.data.license && params.data.license.length > 0) {
                console.log(params)
                let name = '<a href="' + this.$store.state.SERVER_URL + '/' + params.data.license[0].saved_as.split('public/')[1] + '" download target="_blank">' + params.data.license[0].initial_name + '</a>'
                for (let i = 1; i < params.data.license.length; i++) {
                    name = name + '<a href="' + this.$store.state.SERVER_URL + '/' + params.data.license[i].saved_as.split('public/')[1] + '" target="_blank">' + params.data.license[i].initial_name + '</a>'
                }
                return name
            }
        },
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
        removeFile() {
            this.licenseFiles = []
        },
        changeLicense(file, fileList) {
            console.log(file, fileList);
            this.licenseFiles = [file]
            this.$forceUpdate()
        },
        addNewRequest() {
            this.createModal = true

            this.licenseFiles = []
            this.model = {
                assigned_to: [],
                request_to: 'Marketing',
                branch: ''
            }
        },
        cancelCreation() {
            this.createModal = false
            this.model = {
                assigned_to: [],
                request_to: 'Marketing'
            }
        },
        createRequest() {
            this.$validator.validate('request_to', this.model.request_to);
            this.$validator.validate('request_type', this.model.type);
            this.$validator.validate('assigned_to', this.model.assigned_to);
            this.$validator.validate('doctor_name', this.model.doctor_name);

            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['request_to', 'request_type', 'assigned_to', 'doctor_name']);
                if (valid) {
                    let assigned_to = []
                    let assignee_id = []

                    for (let i = 0; i < this.model.assigned_to.length; i++) {
                        assigned_to.push(this.model.assigned_to[i].name)
                        assignee_id.push(this.model.assigned_to[i].user_id)
                    }
                    let formData = new FormData();
                    formData.set('request_from', this.user.name)
                    formData.set('request_to', this.model.request_to)
                    formData.set('assigned_to', assigned_to.toString())
                    formData.set('assignee_id', assignee_id.toString())
                    formData.set('type', this.model.type)
                    formData.set('description', this.model.description ? this.model.description : '')
                    formData.set('api', this.model.id ? "/request/" + this.model.id : "/request")

                    if (this.licenseFiles.length > 0 && !this.licenseFiles[0].saved_as) {
                        for (let i = 0; i < this.licenseFiles.length; i++) {
                            formData.append('license[]', this.licenseFiles[i].raw)
                            formData.append('licenseFilesNames[]', this.licenseFiles[i].name)
                        }
                    }
                    formData.set('doctor_name', this.model.doctor_name ? this.model.doctor_name.full_name : '')
                    formData.set('branch', this.model.branch ? this.model.branch.title : '')
                    let applicant = this.$root.getObjectByKey('name', this.model.applicant, this.users)
                    formData.set('applicant', !this.model.id ? this.user.name : this.model.applicant)
                    formData.set('applicant_id', !this.model.id ? this.user.id : applicant.user_id)
                    this.$store.dispatch("postAction", formData).then(response => {
                        if (response.code === 200) {
                            if (!this.model.id) {
                                this.$store.state.requestsCount++
                            }
                            this.cancelCreation()
                            let datasource = this.dataSource();
                            this.gridOptions.api.setServerSideDatasource(datasource);
                            this.$forceUpdate()
                        }
                    })
                }
            })
        },
        sendReminder(option, data, key, type) {
            console.log(option, data, key, type)
            let applicant = this.$root.getObjectByKey('name', data.applicant, this.users)
            let assigned_to = []
            if (data.assigned_to) {
                data.assigned_to = data.assigned_to.split(',')
                for (let i = 0; i < data.assigned_to.length; i++) {
                    assigned_to.push(this.$root.getObjectByKey('name', data.assigned_to[i], this.users))
                }
            }
            let assignee_id = []
            for (let i = 0; i < assigned_to.length; i++) {
                assignee_id.push(assigned_to[i].user_id)
            }
            let request = {
                type: "request",
                id: data.id,
                applicant_id: applicant.user_id,
                json_object: true,
                assignee_id: assignee_id,
                api: "/request/" + data.id + "/reminder"
            }

            this.$store.dispatch("postAction", request).then(response => {
                if (response.code === 200) {
                    this.$swal({
                        icon: 'info',
                        text: 'Notifications had sent to ' + data.assigned_to + '.',
                        showCancelButton: false,
                        confirmButtonText: 'OK'
                    })
                }
            })
        },

        viewRequest(option, data, key, type) {
            this.selectedRequest = data
            this.viewModal = true
            console.log(option, data, key, type)
        },
        archiveRequestTable(option, data) {

            this.selectedRequest = data
            this.model = JSON.parse(JSON.stringify(data))
            this.archiveRequest()
        },
        editRequest(option, data, key, type) {
            this.selectedRequest = data
            this.model = JSON.parse(JSON.stringify(data))

            this.model.doctor_name = this.$root.getObjectByKey('full_name', this.model.doctor_name, this.doctorsList)
            this.model.branch = this.$root.getObjectByKey('title', this.model.branch, this.branchList)
            this.model.type = this.$root.getObjectByKey(null, this.model.type, this.requestType)
            this.licenseFiles = this.model.license
            let assigned_to = []
            if (this.model.assigned_to) {
                this.model.assigned_to = typeof this.model.assigned_to === "string" ? this.model.assigned_to.split(',') : this.model.assigned_to
                for (let i = 0; i < this.model.assigned_to.length; i++) {
                    assigned_to.push(this.$root.getObjectByKey('name', this.model.assigned_to[i], this.users))
                }
            }
            this.model.assigned_to = assigned_to

            this.createModal = true
            console.log(option, data, key, type)
        },
        updateStatus(option, data, key, type) {
            this.selectedRequest = data
            this.statusModal = true
            console.log(option, data, key, type)
        },

        closeModal() {
            this.statusModal = false
        },
        saveStatus() {
            let task = {
                request_status: this.selectedRequest.request_status,
                api: "/request/" + this.selectedRequest.id,
                json_object: true
            }
            this.$store.dispatch("postAction", task).then(response => {
                if (response.code === 200) {
                    if (this.selectedRequest.request_status === 'Executed') {
                        this.$store.state.requestsCount--
                    }
                    for (let i = 0; i < this.requests.length; i++) {
                        if (response.data.id === this.requests[i].id) {
                            this.requests[i] = response.data
                            this.$forceUpdate()
                        }
                    }
                    this.gridOptions.api.redrawRows();
                    this.closeModal()
                }
            })
        }

    }
}
</script>