<template>
    <board :span="24"
           :span_responsive="[ 24, 24 ]" title="List of branches" height="auto">
        <div class="row" style="width: 100%">
            <div :key="key" v-for="(branch,key) in branchList">
                <div class="col-md-3" style="text-align: center; height: 280px"
                     v-if="branchList.length === 0 || viewedBranches.includes(branch.title)">
                    <div class="col-md-12 p-0">
                        <a href="javascript:;" @click="$router.push('/branch/'+branch.id)">
                            <img src="/images/appointment-icon.png" :alt="branch.title" :title="branch.title"
                                 height="100%"
                                 width="200px">
                        </a>
                    </div>
                    <div class="col-md-12 p-0">
                        <h4>{{ branch.title }}</h4>
                    </div>
                </div>
            </div>
        </div>
    </board>
</template>
<script>

import board from "@/components/CommonComponents/Board";
import Vue from "vue";

export default {
    data() {
        return {
            user: Vue.$cookies.get('user'),
            branchList: [],
            viewedBranches: []
        }
    },
    mounted() {
        this.$store.dispatch('getAction', "/branch").then(response => {
            if (response.code === 200) {
                this.branchList = response.data

                if (this.user.branch) {
                    for (let i = 0; i < this.branchList.length; i++) {
                        if (this.branchList[i].name === this.user.branch) {
                            this.viewedBranches.push(this.branchList[i].name)
                        }
                    }
                } else {
                    for (let i = 0; i < this.branchList.length; i++) {
                        this.viewedBranches.push(this.branchList[i].name)
                    }
                }
                this.$store.state.branches = response.data;
            }
        })
    },
    components: {
        board
    }
}
</script>