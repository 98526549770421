<template>
  <div class="col-md-12 p-0" style=" padding-top: 10px !important;">
    <div class="col-md-12 p-0">
      <div class="form-group">
        <div class="col-md-6" :class="{'has-error': errors.has('title')}">
          <label class="col-md-4 col-form-label title-label">Title : <em>*</em></label>
          <div class="col-md-8" v-if="model.id">
            {{ model.title }}
          </div>
          <div class="col-md-8" v-else>
            <el-input name="title" v-model="model.title" v-validate="'required'"></el-input>
            <span class="help-block" v-if="errors.has('title')">
                            {{ $root.translate('this_field_is_required') }}
                        </span>
          </div>
        </div>
        <div class="col-md-6" :class="{'has-error': errors.has('due_date')}">
          <label class="col-md-4 col-form-label title-label">Due Date : <em>*</em></label>
            <div class="col-md-8" v-if="model.id">
                {{ model.due_date }}
            </div>
          <div class="col-md-8" v-else>
            <el-date-picker
              name="due_date"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              v-model="model.due_date"
              v-validate="'required'"
              type="date">
            </el-date-picker>
            <span class="help-block" v-if="errors.has('due_date')">
                {{ $root.translate('this_field_is_required') }}
            </span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-6">
          <label class="col-md-4 col-form-label title-label">Applicant :</label>
          <div class="col-md-8">
            {{ user.name }}
          </div>
        </div>
        <div class="col-md-6" :class="{'has-error': errors.has('assignee')}">
          <label class="col-md-4 col-form-label title-label">Assignee : <em>*</em></label>
          <div class="col-md-8">
            <multiselect name="assignee" v-model="model.assignee" :options="users"
                         placeholder="Select .." track-by="user_id" :multiple="true"
                         label="name" v-validate="'required'"></multiselect>
            <span class="help-block" v-if="errors.has('assignee')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-12">
          <label class="col-md-2 col-form-label title-label">Description :</label>
          <div class="col-md-10">
            <el-input name="description" type="textarea" :autosize="{ minRows: 4}"
                      v-model="model.description"></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 p-0" v-if="!task">
      <div class="form-group">
        <div class="col-md-6">
          <label class="col-md-4 col-form-label title-label">Priority :</label>
          <div class="col-md-8">
            <el-tag :class="{'enlarge-tag': selectedIndex === 1}" effect="dark" @click="selectTag(1)"
                    type="success"></el-tag>
            <el-tag :class="{'enlarge-tag': selectedIndex === 2}" effect="dark" @click="selectTag(2)"
                    type="medium-priority"></el-tag>
            <el-tag :class="{'enlarge-tag': selectedIndex === 3}" effect="dark" @click="selectTag(3)"
                    type="warning"></el-tag>
            <el-tag :class="{'enlarge-tag': selectedIndex === 4}" effect="dark" @click="selectTag(4)"
                    type="danger"></el-tag>
          </div>
        </div>
        <div class="col-md-6 p-0">
          <el-button class="save-updates-button pull-right"
                     @click="createTask"> {{ task ? "Save Updates" : "Create" }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="col-md-12 p-0" v-else>
      <div class="form-group">
        <div class="col-md-10 pull-right">
          <div class="col-md-12" v-if="!viewAllComments">
                        <span>{{
                            task.comment && task.comment[0] ? task.comment[task.comment.length - 1].created_by + ': ' + task.comment[task.comment.length - 1].comment : ''
                          }}</span>
          </div>
          <div class="col-md-12" v-else>
            <div :key="key" v-for="(tsk,key) in task.comment">
              {{ tsk.created_by + ': ' + tsk.comment }}
            </div>
          </div>
          <div class="col-md-12">
            <a href="javascript:;" class="checklist-label pull-right" @click="viewAllComments=true"> Check
              Previous Comments</a>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-12">
          <label class="col-md-2 col-form-label title-label">Add Comment :</label>
          <div class="col-md-10">
            <el-input name="comment" placeholder="Add Comment" type="textarea" :autosize="{ minRows: 4}"
                      v-model="model.new_comment"></el-input>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-6">
          <label class="col-md-4 col-form-label title-label">Attach File :</label>
          <div class="col-md-8">
            <el-upload
              :on-change="changeTaskFiles"
              :multiple="false"
              ref="cv_uploader"
              class="upload-demo"
              action="#"
              :auto-upload="false"
              :file-list="taskFiles">
              <!--                                accept=".pdf, .doc, .docx">-->
              <el-button size="small" type="primary">Attach</el-button>
            </el-upload>
          </div>
        </div>
        <div class="col-md-6">

          <label class="col-md-4 col-form-label title-label">Attach File :</label>

          <div class="col-md-8">
            <div class="col-md-12 p-0" :key="key" v-for="(file,key) in model.files">

              <div class="col-md-4 p-0">
                <label class="col-md-4 col-form-label">{{file.created_by}} :</label>
              </div>
              <div class="col-md-8 p-0">
                <a :href="$store.state.SERVER_URL + '/' +file.saved_as.split('public/')[1]" target="_blank">
                  {{ file.initial_name }} </a>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div class="form-group">
        <div class="col-md-6">
          <label class="col-md-4 col-form-label title-label">Creation Date :</label>
          <div class="col-md-8">
            {{ model.created_at }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <div>
          <el-button class="clear-button" v-if="model.id && $store.state.permissions.delete_tasks"
                     @click="archiveTask"> Archive
          </el-button>
          <el-button class="clear-button"
                     @click="cancelCreation"> Close
          </el-button>

          <el-button class="save-updates-button pull-right"
                     @click="createTask">Save Updates
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: 'create-task',
  data() {
    return {
      user: Vue.$cookies.get('user'),
      selectedIndex: -1,
      model: {},
      selectedTask: {
        status: ''
      },
      statusModal: false,
      viewAllComments: false,
      pickerOptions: {
        disabledDate(time) {
          const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() - 1)
          return time.getTime() < tomorrow || time.getDay() == 5;
        }
      },
      tasks: [],
      users: [],
      taskFiles: [],
      floating_filter: false,
      frameworkComponents: null,

    }
  },
  props: {
    task: {required: false}
  },
  mounted() {

    this.model = this.task ? JSON.parse(JSON.stringify(this.task)) : {}

    if (this.model.id) {
      if (this.model.priority === 'Low') {
        this.selectedIndex = 1
      } else if (this.model.priority === 'Medium') {
        this.selectedIndex = 2
      } else if (this.model.priority === 'High') {
        this.selectedIndex = 3
      } else if (this.model.priority === 'Urgent') {
        this.selectedIndex = 4
      }
    }
    this.$store.dispatch('getAction', "/users").then(response => {
      if (response.code === 200) {
        this.users = response.data
        if (this.model.id) {
          let assignee = []
          let db_assignee = this.model.assignee.split(',')
          for (let i = 0; i < db_assignee.length; i++) {
            assignee.push(this.$root.getObjectByKey('name', db_assignee[i], this.users))
          }
          this.model.assignee = assignee
        }
      }
    })
  },
  components: {},
  methods: {
    archiveTask() {
      this.$swal({
        icon: 'warning',
        text: 'Do you want to delete this task ' + this.model.title,
        showCancelButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#e3342f',
      }).then((result) => {
        if (result.isConfirmed) {
          let request = {
            archived: true,
            api: "/tasks/" + this.model.id,
            json_object: true
          };
          this.$store.state.tasksCount--
          this.$store.dispatch('postAction', request).then(response => {
            if (response.code === 200) {
              this.$parent.$parent.$parent.$parent.dataSource()
              this.cancelCreation()
            }
          })
        }
      })
    },
    changeTaskFiles(file, fileList) {
      console.log(file, fileList);
      this.taskFiles = [file]
      this.$forceUpdate()
    },
    selectTag(index) {
      this.selectedIndex = index
    },
    cancelCreation() {
      this.model = {}
      this.$bvModal.hide('edit_task_modal');
      this.$bvModal.hide('create_task_modal');
    },
    createTask() {
      this.$validator.validate('title', this.model.title);
      this.$validator.validate('assignee', this.model.assignee);
      this.$validator.validate('due_date', this.model.due_date);
      this.$nextTick(() => {
        let valid = this.$root.checkErrors(this.errors, ['title', 'assignee', 'due_date']);
        if (valid) {
          let assignee = []
          let assignee_id = []
          if (this.model.assignee) {
            for (let i = 0; i < this.model.assignee.length; i++) {
              assignee.push(this.model.assignee[i].name)
              assignee_id.push(this.model.assignee[i].user_id)
            }
          }
          let formData = new FormData()
          formData.set('title', this.model.title ? this.model.title : '')
          let applicant = this.$root.getObjectByKey('name', this.model.applicant, this.users)
          formData.set('applicant', !this.model.id ? this.user.name : this.model.applicant)
          formData.set('applicant_id', !this.model.id ? this.user.id : applicant.user_id)
          formData.set('assignee', assignee.length ? assignee.toString() : [])
          formData.set('assignee_id', assignee_id.length ? assignee_id.toString() : [])
          formData.set('description', this.model.description ? this.model.description : '')
          formData.set('due_date', this.model.due_date ? this.model.due_date : '')
          if (this.taskFiles.length > 0 && !this.taskFiles[0].saved_as) {
            for (let i = 0; i < this.taskFiles.length; i++) {
              formData.append('file[]', this.taskFiles[i].raw)
              formData.append('filesName[]', this.taskFiles[i].name)
            }
          }
          if (this.selectedIndex === 1) {
            formData.set('priority', 'Low')
          } else if (this.selectedIndex === 2) {
            formData.set('priority', 'Medium')
          } else if (this.selectedIndex === 3) {
            formData.set('priority', 'High')
          } else if (this.selectedIndex === 4) {
            formData.set('priority', 'Urgent')
          }
          if (this.model.id) {
            formData.set('api', "/tasks/" + this.model.id)
          } else {
            formData.set('api', "/tasks")
          }
          let comments = []
          if (this.task && this.model.new_comment) {
            formData.append('files[]', JSON.stringify(this.task.files))
            if (this.task.comment) {
              comments = this.task.comment
            }
            comments.push({
              created_by: this.user.name,
              comment: this.model.new_comment
            })
            formData.set('comment', JSON.stringify(comments))
          }

          if (this.taskFiles.length > 0 && !this.taskFiles[0].saved_as) {
            for (let i = 0; i < this.taskFiles.length; i++) {
              formData.append('task[]', this.taskFiles[i].raw)
              formData.append('taskFilesNames[]', this.taskFiles[i].name)
              formData.append('fileCreatedBy[]', this.user.name)
            }

          }
          this.$store.dispatch("postAction", formData).then(response => {
            if (response.code === 200) {
              if (this.model.id) {
                if (this.$route.name !== "home") {
                  let that = this
                  this.$parent.$parent.$parent.$parent.gridOptions.api.forEachNode(function (rowNode) {
                    if (rowNode.data.id == that.model.id) {
                      rowNode.setData(response.data);
                    }
                  });
                }
              } else {
                if (this.$route.name !== "home") {
                  let datasource = this.$parent.$parent.$parent.$parent.dataSource();
                  this.$parent.$parent.$parent.$parent.gridOptions.api.setServerSideDatasource(datasource);
                }
                this.$store.state.tasksCount++;
              }

              this.$bvModal.hide('edit_task_modal');
              this.$bvModal.hide('create_task_modal');
              this.cancelCreation()
            }
          })
        }
      })
    }

  }
}
</script>
<style>
.checklist-label {
  text-decoration: underline;
  color: #001AFE;
}

.el-button--success {
  color: white !important;
}

.el-tag--medium-priority {
  background-color: yellow !important;
  border-color: yellow !important;
  color: #fff;
}

.el-tag {
  width: 30px;
  height: 30px;
  margin-right: 3px;
}

.enlarge-tag {
  width: 50px;
  height: 50px !important;
  margin-bottom: -10px;
}
</style>