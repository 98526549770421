import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        context: '',
        menus_visible: true,
        language_type: 'en',
        allowResetPasswordPage: false,
        redirectToAfterLogin: '/',
        authInfo: {},
        permissions: {},
        announcementsCount: 0,
        tasksCount: 0,
        requestsCount: 0,
        appointmentCount: 0,
        // SERVER_URL: "https://novomed-backend.middlemed.com",
        SERVER_URL: "http://novomed-backend.novomed.marketing",
        // SERVER_URL: "http://novomed-backend.local",
        // SERVER_URL: "http://127.0.0.1:8000",
        login_modal: false,
        notifications: [],
        translations: {
            name: "Name",
            branch: 'Branch',
            department: 'Department',
            doctor: 'Doctor',
            date: 'Date',
            option: 'Option',
            status: 'Status',
            created_at: 'Created at',
            action: 'Action',
            create: 'Create',
            this_field_is_required: 'This field is required',
            email: "Email",
            add_new_user: "Add new user",
            change_password_for: "Change password for",
            change_permission_for: "Change permission for",
            create_user: "Create user",
            change_permission: "Change permission",
            change_password: "Change password",
            add_new_permission: "Add new permission",
            add_new_role: "Add new role",
            view_permission_for: "View permission for",
            close: "Close",
            save: "Save",
            cancel: "Cancel",
            edit_roles_for: "Edit roles for",
            view_permission: "View permissions",
            change_permissions: "Change permissions",
            role: "Role",
            edit_roles: "Edit roles",
            add_new_branch: "Add new branch",
            add_new_department: "Add new department",
            add_new_doctor: "Add new doctor",
            view: "View",
            delete: "Delete",
            remove_doctor: "Remove Doctor",
            update_progress: "Update progress",
            add_new_task: 'Add new task',
            create_task: 'Create task',
            from: 'From',
            to: 'To',
            description: 'Description',
            start_date: 'Start date',
            due_date: 'Due date',
            update_status: 'Update status',
            dr: 'Dr',
            mr: 'Mr',
            mrs: 'Mrs',
            miss: 'Miss',
            first_name: 'First name',
            middle_name: 'Middle name',
            last_name: 'Last name',
            gender: 'Gender',
            date_of_birth: 'Date of birth',
            occupation: 'Occupation',
            marital_status: 'Marital status',
            single: 'Single',
            married: 'Married',
            divorced: 'Divorced',
            widowed: 'Widowed',
            nationality: 'Nationality',
            mobile_number: 'Mobile number',
            phone_number: 'Phone number',
            email_address: 'email address',
            name_of_guardian_spouse: 'Name of guardian / spouse',
            contact_person_in_case_of_emergency: 'Contact person in case of emergency',
            residency: 'Residency',
            resident: 'Resident',
            uae_national: 'UAE National',
            non_uae_national_residency_visa: 'Non-UAE national (Residency Visa)',
            staying_in: 'Staying in',
            abu_dhabi: 'Abu Dhabi',
            dubai: 'Dubai',
            sharjah: 'Sharjah',
            ajman: 'Ajman',
            al_ain: 'Al Ain',
            fujairah: 'Fujairah',
            ras_al_khaimah: 'Ras Al Khaimah',
            umm_al_quwain: 'Umm Al Quwain',
            country_of_residence: 'Country of residence',
            identity_proof: 'Identity proof',
            passport_id_number: 'Passport ID number',
            mode_of_payment: 'Mode of payment',
            cash_credit: 'Cash / Credit',
            insurance_company_name: 'Insurance company name',
            how_did_you_hear_about_us: 'How did you hear about us',
            referred_from_dr_clinics: 'Referred from Dr clinics',
            personal_information: 'Personal Information',
            contact_details: 'Contact Details',
            residency_status: 'Residency Status',
            family_friend: 'Family / Friend',
            newspaper: 'Newspaper',
            snapchat: 'Snapchat',
            instagram: 'Instagram',
            facebook: 'Facebook',
            twitter: 'Twitter',
            google: 'Google',
            youTube: 'YouTube',
            tiktok: 'Tiktok',
            other: 'Other',
            emirates_id_number: 'Emirates ID Number',
            general_consent_from: 'General Consent from',
            undo: 'Undo',
            guidelines: 'Guidelines',
            add_new_appointment: 'Add new appointment',
            create_appointment: 'Create Appointment',
            add_new_request: 'Add new request',
            create_request: 'Create Request',
            request_from: 'Request from',
            request_to: 'Request to',
            request_type: 'Request Type',
            type: 'Type',
            doctor_info: 'Doctor Info',
            remove: 'Remove',
            open: 'Open',
            edit: 'Edit',
            position_name: 'Position name',
            created_by: 'Created by',
            title: 'Title',
            archive: 'Archive',
        }
    },
    mutations: {
        ALLOW_RESET_PASSWORD_PAGE(state, params) {
            state.allowResetPasswordPage = params.state;
        },
        UPDATE_REDIRECT_TO_AFTER_LOGIN(state, params) {
            state.redirectToAfterLogin = params.path
        },
        OPEN_LOGIN_MODEL: (state) => {
            state.login_modal = true;
        },
        UPDATE_TOKEN(state, {token}) {
            if (token) {
                Vue.$cookies.set("token", token, null, '/', this.state.domain);
            } else {
                let cookies = Vue.$cookies.keys();
                cookies.forEach(value => {
                    // Vue.$cookies.set(value, '', null, '/', this.state.domain);
                    Vue.$cookies.remove(value);
                });
            }
            state.token = token;
        },

        UPDATE_DOMAIN: (state, params) => {
            state.domain = params;
        },
        MENUS_VISIBLE(state, value) {
            state.menus_visible = value;
        },
    },
    modules: {},
    getters: {},
    actions: {
        menusVisible({commit}, value) {
            commit("MENUS_VISIBLE", {value});
        },
        allowResetPasswordPage({commit}, params) {
            commit('ALLOW_RESET_PASSWORD_PAGE', params)
        },
        setRedirectToAfterLogin({commit}, params) {
            commit('UPDATE_REDIRECT_TO_AFTER_LOGIN', params)
        },
        getAction(data, api) {
            var payload = {
                method: 'get',
                api: api
            };
            return this.dispatch('sendRequest', payload);
        },
        deleteAction(data, api) {
            var payload = {
                method: 'delete',
                api: api
            };
            return this.dispatch('sendRequest', payload);
        },
        postAction(data, params) {
            let api = ""
            if (params.json_object) {
                api = params.api;
            } else {
                api = params.getAll('api')[0];
            }
            let payload = {
                method: 'post',
                api: api,
                data: params
            };
            return this.dispatch('sendRequest', payload);
        },
        updateAction(data, params) {
            var payload = {
                method: 'put',
                api: params.api,
                data: params
            };
            return this.dispatch('sendRequest', payload);
        },
        sendRequest(data, payload) {
            let request = ""
            if (payload.method === 'get') {
                request = {
                    crossDomain: true,
                    method: 'get',
                    headers: {
                        'Authorization': 'Bearer ' + Vue.$cookies.get('token')
                    }
                };
            } else if (payload.method === 'post') {
                if (payload.data.json_object) {
                    Vue.delete(payload.data, 'json_object');
                    request = {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            // 'Access-Control-Allow-Origin':'*',
                            'Authorization': 'Bearer ' + Vue.$cookies.get('token')
                        },
                        data: payload.data,
                    };
                } else {
                    request = {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + Vue.$cookies.get('token')
                        },
                        data: payload.data,
                    };
                }
            } else if (payload.method === 'delete') {
                request = {
                    crossDomain: true,
                    method: 'delete',
                    headers: {
                        'Authorization': 'Bearer ' + Vue.$cookies.get('token')
                    }
                };
            } else if (payload.method === 'put') {
                request = {
                    method: 'put',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + Vue.$cookies.get('token')
                    },
                    data: payload.data
                };
                payload = payload.data
            }
            if (payload.api.includes('http'))
                request.url = payload.api;
            else
                request.url = this.state.SERVER_URL + payload.api;
            if (request.data) {
                Vue.delete(request.data, 'api');
            }
            return new Promise((resolve, reject) => {
                axios(request).then(response => {
                    resolve(response.data);
                }).catch((error) => {
                    if (error.response) {
                        let resData = error.response.data
                        console.log(resData)
                        if (resData.message == 'Token has expired') {
                            this.dispatch("openLogIn");
                            this.state.loader--;
                            return;
                        }
                        if (resData.data) {
                            if (resData.data.error_code == 1015) {
                                this.dispatch('setAuthInfo', {
                                    resetPasswordToken: resData.data.token,
                                });
                                this.dispatch('allowResetPasswordPage', {
                                    state: true
                                });
                                this.state.loader--;
                                return;
                            } else if (resData.data.error_code == 1013) {
                                this.state.loader--;
                                reject(error);
                                return;
                            } else if (resData.code == 400) {
                                this.dispatch("openLogIn");
                                this.state.loader--;
                                return;
                            }
                        } else if (resData.code == 428) {
                            this.state.loader--;
                            reject(error);
                            return;
                        }
                    }
                    // serviceEvent.$emit('notify', 'error', 'something_went_wrong');
                    if (!this.state.preview_pane_loader) {
                        this.state.loader--;
                    }
                    reject(error);
                })
            });
        },
        setToken({commit}, token) {
            commit("UPDATE_TOKEN", {token});
        },
        removeToken({commit}) {
            commit("UPDATE_TOKEN", "");
        },
        openLogIn({commit}) {
            commit("OPEN_LOGIN_MODEL");
        },
    },
})
