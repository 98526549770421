<template>
    <board :span="24" :span_responsive="[ 24, 24 ]" title="Patients" height="auto">
        <div class="col-md-12 p-0" style="margin-bottom: 10px">
            <button-group>
                <button-group-dropdown title="Export as"
                                       classes="submit-button-dropdown full-width">
                    <button class="button success full" @click="exportTable('csv')"> CSV</button>
                    <button class="button success full" @click="exportTable('xlsx')"> XLSX</button>
                </button-group-dropdown>
            </button-group>
        </div>
        <div class="col-md-12 with-dropdown-filter-button">
            <div class="grid-filter-trigger" @click="filterTrigger">
                <i :class="floating_filter?'fas fa-filter':'far fa-filter'"></i>
            </div>
        </div>
        <ag-grid-vue :pagination="true"
                     :localeText="$store.state.translations"
                     :enableRtl="$store.state.ag_grid_rtl"
                     :grid-options="gridOptions"
                     rowHeight="60"
                     :floatingFilter="floating_filter"
                     :frameworkComponents="frameworkComponents"
                     :paginationPageSize="20"
                     class="ag-theme-material"
                     :columnDefs="columnDefs"
                     :rowData="patients">
        </ag-grid-vue>

        <b-modal v-model="viewPatient" id="reset_password_modal_id" size="lg" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4>{{ selectedPatient.first_name + ' ' + selectedPatient.last_name }}</h4>
            </div>

            <h3 class="patient-header">{{ $root.translate('personal_information') }}</h3>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('name') }} :</label>
                <p class="margin-top-10">
                    {{
                        $root.translate(selectedPatient.name_abb) + ' ' + selectedPatient.first_name + ' ' + selectedPatient.middle_name + ' ' + selectedPatient.last_name
                    }}
                </p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('gender') }} :</label>
                <p class="margin-top-10">
                    {{ $root.translate(selectedPatient.gender) }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('date_of_birth') }}
                    :</label>
                <p class="margin-top-10">{{ selectedPatient.birth_date }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('occupation') }} :</label>
                <p class="margin-top-10">{{ selectedPatient.occupation }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('marital_status') }}
                    :</label>
                <p class="margin-top-10">{{ selectedPatient.marital_status }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('nationality') }}
                    :</label>
                <p class="margin-top-10">{{ selectedPatient.nationality }}</p>
            </div>

            <h3 class="patient-header">{{ $root.translate('contact_details') }}</h3>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('mobile_number') }}
                    :</label>
                <p class="margin-top-10">{{ selectedPatient.mobile_number }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('phone_number') }}
                    :</label>
                <p class="margin-top-10">{{ selectedPatient.phone_number }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('email_address') }}
                    :</label>
                <p class="margin-top-10">{{ selectedPatient.email_address }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">{{
                        $root.translate('name_of_guardian_spouse')
                    }} :</label>
                <p class="margin-top-10">{{ selectedPatient.spouse_name }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{
                        $root.translate('emergency_person_name')
                    }} :</label>
                <p class="margin-top-10">{{ selectedPatient.emergency_person_name }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('phone_number') }}
                    :</label>
                <p class="margin-top-10">{{ selectedPatient.emergency_phone_number }}</p>
            </div>
            <h3 class="patient-header">{{ $root.translate('residency_status') }}</h3>

            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('residency') }} :</label>
                <p class="margin-top-10">{{ selectedPatient.residency }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('resident') }} :</label>
                <p class="margin-top-10">{{ selectedPatient.resident }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('staying_in') }} :</label>
                <p class="margin-top-10">{{ selectedPatient.staying_in }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('country_of_residence') }}
                    :</label>
                <p class="margin-top-10">{{ selectedPatient.country_of_residence }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('identity_proof') }}
                    :</label>
                <p class="margin-top-10">{{ $root.translate(selectedPatient.identity_proof) }}</p>-
                <p v-if="selectedPatient.identity_proof==='passport_id_number'">
                    {{ selectedPatient.passport_id_number_field }}</p>
                <p v-else>{{ selectedPatient.emirates_id_number_field }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{ $root.translate('mode_of_payment') }}
                    :</label>
                <p class="margin-top-10">{{ $root.translate(selectedPatient.payment_mode) }}</p>
                <p v-if="selectedPatient.identity_proof==='passport_id_number'">
                    {{ selectedPatient.passport_id_number_field }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{
                        $root.translate('insurance_company_name')
                    }} :</label>
                <p class="margin-top-10">{{ selectedPatient.insurance_company_name }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{
                        $root.translate('how_did_you_hear_about_us')
                    }} :</label>
                <p class="margin-top-10">{{ $root.translate(selectedPatient.hear_about_us) }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-6 col-form-label title-label">{{
                        $root.translate('referred_from_dr_clinics')
                    }} :</label>
                <p class="margin-top-10">{{ $root.translate(selectedPatient.referred_from) }}</p>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">{{ $root.translate('signature') }} :</label>
                <div class="col-md-8">
                    <img :src="getSignature(selectedPatient.signature)">
                </div>
            </div>
            <div class="col-md-12 p-0">
                <a class="link-inline success save-button" @click="generatePDF"><i class=" fa fa-save"></i>
                    {{ $root.translate('generate_pdf') }}</a>
                <!--                <a class="link-inline success save-button" @click="generateWord"><i class=" fa fa-save"></i>-->
                <!--                    {{ $root.translate('generate_word') }}</a>-->
            </div>
        </b-modal>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="selectedPatient.first_name+' '+selectedPatient.last_name"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="900px"
            ref="html2Pdf"
        >
            <section slot="pdf-content" style="margin: 30px;">
                <h3>{{ $root.translate('personal_information') }}</h3>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('name') }} :</label>
                    <p class="margin-top-10">
                        {{
                            $root.translate(selectedPatient.name_abb) + ' ' + selectedPatient.first_name + ' ' + selectedPatient.middle_name + ' ' + selectedPatient.last_name
                        }}
                    </p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('gender') }} :</label>
                    <p class="margin-top-10">
                        {{ $root.translate(selectedPatient.gender) }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('date_of_birth') }}
                        :</label>
                    <p class="margin-top-10">{{ selectedPatient.birth_date }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('occupation') }} :</label>
                    <p class="margin-top-10">{{ selectedPatient.occupation }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('marital_status') }}
                        :</label>
                    <p class="margin-top-10">{{ selectedPatient.marital_status }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('nationality') }}
                        :</label>
                    <p class="margin-top-10">{{ selectedPatient.nationality }}</p>
                </div>

                <h3>{{ $root.translate('contact_details') }}</h3>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('mobile_number') }}
                        :</label>
                    <p class="margin-top-10">{{ selectedPatient.mobile_number }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('phone_number') }}
                        :</label>
                    <p class="margin-top-10">{{ selectedPatient.phone_number }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('email_address') }}
                        :</label>
                    <p class="margin-top-10">{{ selectedPatient.email_address }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{
                            $root.translate('name_of_guardian_spouse')
                        }} :</label>
                    <p class="margin-top-10">{{ selectedPatient.spouse_name }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{
                            $root.translate('emergency_person_name')
                        }} :</label>
                    <p class="margin-top-10">{{ selectedPatient.emergency_person_name }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('phone_number') }}
                        :</label>
                    <p class="margin-top-10">{{ selectedPatient.emergency_phone_number }}</p>
                </div>
                <h3>{{ $root.translate('residency_status') }}</h3>

                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('residency') }} :</label>
                    <p class="margin-top-10">{{ selectedPatient.residency }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('resident') }} :</label>
                    <p class="margin-top-10">{{ selectedPatient.resident }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('staying_in') }} :</label>
                    <p class="margin-top-10">{{ selectedPatient.staying_in }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('country_of_residence') }}
                        :</label>
                    <p class="margin-top-10">{{ selectedPatient.country_of_residence }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('identity_proof') }}
                        :</label>
                    <p class="margin-top-10">{{ $root.translate(selectedPatient.identity_proof) }}</p>-
                    <p v-if="selectedPatient.identity_proof==='passport_id_number'">
                        {{ selectedPatient.passport_id_number_field }}</p>
                    <p v-else>{{ selectedPatient.emirates_id_number_field }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('mode_of_payment') }}
                        :</label>
                    <p class="margin-top-10">{{ $root.translate(selectedPatient.payment_mode) }}</p>
                    <p v-if="selectedPatient.identity_proof==='passport_id_number'">
                        {{ selectedPatient.passport_id_number_field }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{
                            $root.translate('insurance_company_name')
                        }} :</label>
                    <p class="margin-top-10">{{ selectedPatient.insurance_company_name }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{
                            $root.translate('how_did_you_hear_about_us')
                        }} :</label>
                    <p class="margin-top-10">{{ $root.translate(selectedPatient.hear_about_us) }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{
                            $root.translate('referred_from_dr_clinics')
                        }} :</label>
                    <p class="margin-top-10">{{ $root.translate(selectedPatient.referred_from) }}</p>
                </div>
                <div class="col-md-12 p-0">
                    <label class="col-md-4 col-form-label title-label">{{ $root.translate('signature') }} :</label>
                    <div class="col-md-8">
                        <img :src="image" height="400px" width="400px">
                    </div>
                </div>
            </section>
        </vue-html2pdf>
    </board>
</template>

<script>
import board from "@/components/CommonComponents/Board";
import {AgGridVue} from "ag-grid-vue";
import optionsCell from '@/components/CommonComponents/agGrid/AgGridActionsCell.vue';
import ButtonGroup from '@/components/CommonComponents/SlideOut/buttonGroup.vue';
import ButtonGroupDropdown from '@/components/CommonComponents/SlideOut/buttonGroupButtonDropdown.vue';
import VueHtml2pdf from 'vue-html2pdf'
import axios from 'axios'

export default {
    data() {
        return {
            selectedPatient: {},
            image: '',
            viewPatient: false,
            patients: [],
            floating_filter: false,
            frameworkComponents: null,
            gridOptions: {
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                }, components: {
                    'optionsCell': this.optionsCell,
                }
            },
            columnDefs: []
        }
    },
    mounted() {

        this.columnDefs = [
            {
                "headerName": "#",
                "width": 60,
                "field": 'action',
                "cellRenderer": function (params) {
                    return params.rowIndex + 1;
                },
                "sortable": false,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('name'),
                "field": "first_name",
                "cellRenderer": function (params) {
                    if (params.data.middle_name) {
                        return params.data.first_name + ' ' + params.data.middle_name + ' ' + params.data.last_name
                    } else {
                        return params.data.first_name + ' ' + params.data.last_name
                    }
                },
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    if (params.data.middle_name) {
                        return params.data.first_name + ' ' + params.data.middle_name + ' ' + params.data.last_name
                    } else {
                        return params.data.first_name + ' ' + params.data.last_name
                    }
                },
                valueGetter: params => {
                    if (params.data.middle_name) {
                        return params.data.first_name + ' ' + params.data.middle_name + ' ' + params.data.last_name
                    } else {
                        return params.data.first_name + ' ' + params.data.last_name
                    }
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('gender'),
                "field": 'gender',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.gender
                },
                valueGetter: params => {
                    return params.data.gender
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('birth_date'),
                "field": 'birth_date',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.birth_date
                },
                valueGetter: params => {
                    return params.data.birth_date
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('nationality'),
                "field": 'nationality',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.nationality
                },
                valueGetter: params => {
                    return params.data.nationality
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('created_at'),
                "field": 'created_at',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.created_at
                },
                valueGetter: params => {
                    return params.data.created_at
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                headerName: this.$root.translate('view'),
                width: 100,
                field: 'action',
                cellRendererFramework: optionsCell,
                cellRendererParams: {
                    props: [
                        {
                            icon: 'fas fa-key',
                            value: 'view',
                            type: 'action',
                            onClick: this.view
                        }
                    ]
                },
                "suppressMenu": true
            }
        ]
        this.$store.dispatch('getAction', "/patients?branch_id="+this.$route.params.branch_id).then(response => {
            if (response.code === 200) {
                this.patients = response.data
            }
        })
    },
    components: {
        board,
        AgGridVue,
        ButtonGroup,
        ButtonGroupDropdown,
        VueHtml2pdf
    },
    methods: {
        getImage(url) {
            if (!url)
                return
            axios.get(this.$store.state.SERVER_URL + '/file/' + url.split('/').slice(-1).pop(), {
                responseType: 'blob'
            }).then(response => {
                this.image = window.URL.createObjectURL(new Blob([response.data]))
            })
        },
        getSignature(sig) {
            if (sig) {
                return this.$store.state.SERVER_URL + sig.split('public')[1]
            }
        },
        generatePDF() {
            this.$refs.html2Pdf.generatePdf()
        },
        generateWord() {

        },
        exportTable(type) {
            let columns_keys = [];
            this.columnDefs.forEach(value => {
                if (value.field !== 'action') {
                    columns_keys.push(value.field);
                }
            });
            let params = {
                columnKeys: columns_keys,
                fileName: 'List of patients'
            };
            type === 'csv' ? this.gridOptions.api.exportDataAsCsv(params) : this.gridOptions.api.exportDataAsExcel({
                exportMode: type,
                columnKeys: columns_keys,
                fileName: 'List of Patients'
            });
        },
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
        view(option, data, key, type) {
            console.log(option, data, key, type)
            this.selectedPatient = data;
            this.getImage(this.selectedPatient.signature)
            this.viewPatient = true
        }
    }
}
</script>
<style>
.label-value {
    font-weight: unset;
}
</style>