import Vue from 'vue';
import Router from 'vue-router';
import multiguard from 'vue-router-multiguard';
import CustomGuards from '@/middlewares/routesGuard.js';
import routesPermissionsGuard from '@/middlewares/routesPermissions.js';
import Home from './components/Home.vue';
import Login from './components/user/login.vue';
import listUsers from './components/user/list.vue';
import profile from './components/user/profile.vue';
import Logout from './components/user/logout.vue';
// import Register from './components/user/register.vue';
import Appointment from './components/appointment/list.vue';
// import resetPageComponent from '@/components/user/ResetPassword.vue';
// import viewPermissions from '@/components/permissions/viewPermissions.vue';
import listRoles from '@/components/permissions/roles.vue';
import createRequests from '@/components/requestsManagement/list.vue'
import listTasks from '@/components/tasks/list.vue'
import announcements from '@/components/AnouncementsManagement/list.vue';
import createPatients from '@/components/patients/create'
import listPatients from '@/components/patients/list'
import MenuBranches from '@/components/patients/menuBranches'
import patientMenu from '@/components/patients/patientMenu'
import branches from'@/components/doctorsManagement/branches.vue'
import departments from'@/components/doctorsManagement/departments.vue'
import doctors from'@/components/doctorsManagement/doctors.vue'
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err);
// }
Vue.use(Router);
let globalRoutes = [
    {
        'path': '/',
        'name': 'home',
        'component': Home,
        beforeEnter: multiguard([CustomGuards.authGuard]),
    },
    {
        'path': '/branch',
        'name': 'menu-branch',
        'component': MenuBranches,
        beforeEnter: multiguard([CustomGuards.authGuard]),
    },
    {
        'path': '/branch/:branch_id',
        'name': 'patient-branch',
        'component': patientMenu,
        beforeEnter: multiguard([CustomGuards.authGuard]),
    },
    {
        'path': '/appointment',
        'name': 'appointment',
        'component': Appointment,
        beforeEnter: multiguard([CustomGuards.authGuard, routesPermissionsGuard.permissionGuard]),
        meta: {
            permission: 'list_appointments'
        },
    },
    {
        'path': '/requests',
        'name': 'create-request',
        'component': createRequests,
        beforeEnter: multiguard([CustomGuards.authGuard, routesPermissionsGuard.permissionGuard]),
        meta: {
            permission: 'list_requests'
        },
    },
    {
        'path': '/tasks',
        'name': 'create-task',
        'component': listTasks,
        beforeEnter: multiguard([CustomGuards.authGuard, routesPermissionsGuard.permissionGuard]),
        meta: {
            permission: 'list_tasks'
        },
    },
    {
        'path': '/profile',
        'name': 'profile',
        'component': profile,
        beforeEnter: multiguard([CustomGuards.authGuard, routesPermissionsGuard.permissionGuard]),
    },
    {
        'path': '/users',
        'name': 'users',
        'component': listUsers,
        beforeEnter: multiguard([CustomGuards.authGuard, routesPermissionsGuard.permissionGuard]),
        meta: {
            permission: 'list_users'
        },
    },
    {
        'path': '/roles',
        'name': 'roles',
        'component': listRoles,
        beforeEnter: multiguard([CustomGuards.authGuard, routesPermissionsGuard.permissionGuard]),
        meta: {
            permission: 'list_roles'
        },
    },
    {
        'path': '/branches',
        'name': 'branches',
        'component': branches,
        beforeEnter: multiguard([CustomGuards.authGuard, routesPermissionsGuard.permissionGuard]),
        meta: {
            permission: 'list_roles'
        },
    },
    {
        'path': '/departments',
        'name': 'departments',
        'component': departments,
        beforeEnter: multiguard([CustomGuards.authGuard, routesPermissionsGuard.permissionGuard]),
        meta: {
            permission: 'list_roles'
        },
    },
    {
        'path': '/doctors',
        'name': 'doctors',
        'component': doctors,
        beforeEnter: multiguard([CustomGuards.authGuard, routesPermissionsGuard.permissionGuard]),
        meta: {
            permission: 'list_roles'
        },
    },
    {
        'path': '/announcements',
        'name': 'announcements',
        'component': announcements,
        beforeEnter: multiguard([CustomGuards.authGuard, routesPermissionsGuard.permissionGuard]),
        meta: {
            permission: 'list_announcement'
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: multiguard([CustomGuards.redirectIfLoggedIn])
    },
    {
        path: '/branch/:branch_id/patients/create',
        name: 'create-patient',
        component: createPatients,
        // beforeEnter: multiguard([CustomGuards.redirectIfLoggedIn])
    },
    {
        path: '/branch/:branch_id/patients',
        name: 'list-patient',
        component: listPatients,
        beforeEnter: multiguard([CustomGuards.authGuard]),
        // meta: {
        //     permission: 'view_announcement'
        // },
    }
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: globalRoutes
});
let routesCollection = [
    '/'
];
router.beforeEach((to, from, next) => {
    if (routesCollection.indexOf(to.path.split('/')[1]) != -1) {
        CustomGuards.authGuard(to, from, next);
    } else {
        next();
    }
});

export default router;