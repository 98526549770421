<template>
  <div class="buttons-sidebar">
    <slot></slot>
  </div>
</template>
<script>

  export default {
    methods: {
    },
    props:{
    },
    computed: {}
  }
</script>
<style lang="scss" scoped>
</style>
