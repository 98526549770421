<template>
    <board :span="24" :span_responsive="[ 24, 24 ]" title="Profile Info" height="auto">
        <div class="col-md-12">
            <div class="col-md-3">
                <div class="col-md-4">
                    <label class="col-form-label title-label">Name</label>
                </div>
                <div class="col-md-8 col-form-label">
                    {{ user.name }}
                </div>
            </div>
            <div class="col-md-3">
                <div class="col-md-4">
                    <label class="col-form-label title-label">Email</label>
                </div>
                <div class="col-md-8 col-form-label">
                    {{ user.email }}
                </div>
            </div>
            <div class="col-md-3">
                <div class="col-md-4">
                    <label class="col-form-label title-label">Created at</label>
                </div>
                <div class="col-md-8 col-form-label" style="padding-right: 0px;">
                    {{ user.created_at }}
                </div>
            </div>
            <div class="col-md-3">
                <div class="col-md-4">
                    <label class="col-form-label title-label">Branch</label>
                </div>
                <div class="col-md-8 col-form-label">
                    {{ user.branch }}
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <el-button @click="passwordModal=true" class="save-updates-button pull-right">
                {{ $root.translate('change_password') }}
            </el-button>
        </div>
        <b-modal v-model="passwordModal" id="reset_password_modal_id" size="md" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4>{{ $root.translate('change_password_for') + ' ' + user.name }}</h4>
            </div>
            <div class="col-md-12 p-0">
                <resetPassword :user="user"></resetPassword>
            </div>
        </b-modal>
    </board>
</template>
<script>

import resetPassword from "./ResetPassword";
import board from "@/components/CommonComponents/Board";
import Vue from "vue";

export default {
    data() {
        return {
            user: '',
            passwordModal: false
        }
    },
    mounted() {
        this.user = Vue.$cookies.get("user");
    },
    components: {
        resetPassword,
        board
    }
}
</script>