// import store from '@/store.js';
let routePermissions = {};

export default {
    permissionGuard(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permission"));
        let permission_names=[]
        for (let i = 0; i < permissions.length; i++) {
            permission_names.push(permissions[i].slug)
        }
        if(to.meta.permission){
            next();
            return;
            // if(permission_names.indexOf(to.meta.permission)!=-1){
            //     next();
            // }else{
            //     Vue.swal({
            //         title: 'Warning',
            //         text: "You don't have permission to view this page",
            //         icon: 'warning',
            //         type: 'warning',
            //         showCancelButton: false,
            //         confirmButtonClass: 'btn-danger',
            //         cancelButtonClass: 'btn-info',
            //         confirmButtonText: 'OK'
            //     }).then(() => {
            //         window.location = '/';
            //     });
            // }
        }else{
            next();
        }
        // return false
    },
    getPermission(name) {
        return routePermissions[name];
    }
};
