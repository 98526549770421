<template>
    <div class="col-md-12 p-0">
        <div class="form-group">
            <div class="col-md-6 p-0" :class="{'has-error': errors.has('branch')}">
                <label class="col-md-4 col-form-label title-label">Branch : <em>*</em></label>
                <div class="col-md-8" v-if="!appointment">
                    <multiselect name="branch" v-model="model.branch" :options="branchList"
                                 placeholder="Select branch.." :disabled="user.branch!==undefined?null:'disabled'"
                                 label="title" v-validate="'required'"></multiselect>
                    <span class="help-block" v-if="errors.has('branch')">
                        {{ $root.translate('this_field_is_required') }}
                    </span>
                </div>
                <div class="col-md-8" v-else>
                    {{ appointment.branch }}
                </div>
            </div>
            <div class="col-md-6 p-0" :class="{'has-error': errors.has('department')}">
                <label class="col-md-4 col-form-label title-label">Department :<em>*</em></label>
                <div class="col-md-8" v-if="!appointment">
                    <multiselect name="department" v-model="model.department" :options="departmentList"
                                 placeholder="Select department.." label="title"
                                 v-validate="'required'"></multiselect>
                    <span class="help-block" v-if="errors.has('department')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                </div>
                <div class="col-md-8" v-else>
                    {{ appointment.department }}
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-md-6 p-0" :class="{'has-error': errors.has('doctor')}">
                <label class="col-md-4 col-form-label title-label">Doctor :<em>*</em></label>
                <div class="col-md-8" v-if="!appointment">
                    <multiselect id="doctor" name="doctor" v-model="model.doctor" :options="doctorList"
                                 placeholder="Select doctor.." label="full_name"
                                 v-validate="'required'"></multiselect>
                    <span class="help-block" v-if="errors.has('doctor')">
              {{ $root.translate('this_field_is_required') }}
          </span>
                </div>
                <div class="col-md-8" v-else>
                    {{ appointment.doctor }}
                </div>
            </div>
            <div class="col-md-6 p-0" :class="{'has-error': errors.has('doctor_options')}">
                <label class="col-md-4 col-form-label title-label">Option :<em>*</em></label>
                <div class="col-md-8">
                    <multiselect name="doctor_options" v-model="model.doctor_options" :options="doctorOption"
                                 placeholder="Select.." v-validate="'required'"></multiselect>
                    <span class="help-block" v-if="errors.has('doctor_options')">
                                {{ $root.translate('this_field_is_required') }}
                            </span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-6 p-0" :class="{'has-error': errors.has('date_field')}">
                <label class="col-md-4 col-form-label title-label">Due date :<em>*</em></label>
                <div class="col-md-8">
                    <date-picker v-model="model.date_field" v-validate="'required'" placeholder="Select date"
                                 name="date_field" :config="date_time_options"></date-picker>
                    <span class="help-block" v-if="errors.has('date_field')">
                        {{ $root.translate('this_field_is_required') }}
                    </span>
                </div>
            </div>
            <div class="col-md-6 p-0" :class="{'has-error': errors.has('assigned_to')}">
                <label class="col-md-4 col-form-label title-label">Assignee : <em>*</em></label>
                <div class="col-md-8">
                    <multiselect id="assigned_to" name="assigned_to" v-model="model.assigned_to" :options="users"
                                 placeholder="Select .." track-by="user_id" :multiple="true"
                                 label="name" v-validate="'required'"></multiselect>
                    <span class="help-block" v-if="errors.has('assigned_to')">
                        {{ $root.translate('this_field_is_required') }}
                    </span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-md-12 p-0">
                <label class="col-md-2 col-form-label title-label">Special Note :</label>
                <div class="col-md-10">
                    <el-input name="special_note" type="textarea" :autosize="{ minRows: 4}"
                              v-model="model.special_note"></el-input>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Creation Date :</label>
                <div class="col-md-8">
                    {{ model.id ? model.created_at : new Date().toLocaleString() }}
                </div>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Status :</label>
                <div class="col-md-8" v-if="!appointment">
                    <el-button type="warning">Upcoming</el-button>
                </div>
                <div class="col-md-8" v-else>
                    <button v-if="appointment.status==='Executed'" type="button"
                            class="el-button el-button--success">
                        <span style="color: white">{{ appointment.status }}</span></button>
                    <button v-else-if="appointment.status==='Cancelled'" type="button"
                            class="el-button el-button--danger">
                        <span style="color: white">{{ appointment.status }}</span></button>
                    <button v-else type="button" class="el-button el-button--warning"><span
                        style="color: white">{{ appointment.status }}</span></button>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div>
                <el-button class="clear-button" v-if="model.id && $store.state.permissions.delete_appointment"
                           @click="archiveAppointment"> Archive
                </el-button>
                <el-button class="clear-button"
                           @click="clearData"> Close
                </el-button>

                <el-button class="save-updates-button pull-right"
                           @click="createAppointment"> {{ model.id ? "Save Updates" : "Create" }}
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import * as VeeValidate from 'vee-validate';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import moment from 'moment'

Vue.prototype.moment = moment
Vue.use(VeeValidate, {
    fieldsBagName: 'veeFields'
});

export default {
    props: {
        appointment: {required: false}
    },
    data() {
        return {
            branchList: [],
            departmentList: [],
            doctorList: [],
            users: [],
            doctorOption: ['Only Doctor', 'Doctor with patient'],
            model: {
                branch: '',
                department: '',
                assigned_to: '',
                doctor: '',
                date_field: '',
                doctor_options: '',
                special_note: '',
            },
            user: Vue.$cookies.get('user'),
            date_time_options: {}
        }
    },
    mounted() {
        if (this.appointment) {
            this.model = JSON.parse(JSON.stringify(this.appointment))
            this.$forceUpdate()
        }

        let newDate = new Date();
        // newDate.setHours(24, 0, 0, 0);
        this.date_time_options =
            {
                daysOfWeekDisabled: [5],
                minDate: newDate,
                format: 'ddd DD-MM-YYYY hh:mm',
                stepping: 15,
                useCurrent: false,
                sideBySide: true

            }
        this.$store.dispatch('getAction', "/branch").then(response => {
            if (response.code === 200) {
                this.branchList = response.data

                if (this.user.branch) {
                    let id = ''
                    for (let i = 0; i < this.branchList.length; i++) {
                        if (this.branchList[i].title === this.user.branch) {
                            id = this.branchList[i].id
                        }
                    }
                    this.model.branch = {
                        name: this.user.branch,
                        id: id
                    }
                }
            }
        })
        this.$store.dispatch('getAction', "/users").then(response => {
            if (response.code === 200) {
                this.users = response.data
                if (this.model.id) {
                    let assignee = []
                    let db_assignee = this.model.assigned_to.split(',')
                    for (let i = 0; i < db_assignee.length; i++) {
                        assignee.push(this.$root.getObjectByKey('name', db_assignee[i], this.users))
                    }
                    this.model.assigned_to = assignee
                }
            }
        })

        this.$store.dispatch('getAction', "/department").then(response => {
            if (response.code === 200) {
                this.departmentList = response.data
            }
        })

    },
    components: {
        datePicker
    },
    methods: {
        archiveAppointment() {
            this.$swal({
                icon: 'warning',
                text: 'Do you want to delete an appointment with' + this.model.doctor,
                showCancelButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#e3342f',
            }).then((result) => {
                if (result.isConfirmed) {
                    let request = {
                        archived: true,
                        api: "/appointment/" + this.model.id,
                        json_object: true
                    }
                    this.$store.dispatch('postAction', request).then(response => {
                        if (response.code === 200) {
                            this.$store.state.appointmentCount--
                            let data = JSON.parse(JSON.stringify(this.appointment))
                            data.date_field = this.model.date_field
                            data.doctor_options = this.model.doctor_options
                            data.special_note = this.model.special_note ? this.model.special_note : ''
                            this.$parent.$parent.$parent.$parent.dataSource()
                            this.$forceUpdate()
                            this.$bvModal.hide('create_appointment_modal');
                            this.model = {
                                branch: '',
                                department: '',
                                assigned_to: '',
                                doctor: '',
                                date_field: '',
                                doctor_options: '',
                                special_note: '',
                            }
                        }
                    })
                }
            })
        },
        statusField(params) {
            if (params.data.status === 'Executed') {
                return '<button type="button" class="el-button el-button--success" style="width: 130px;"><span style="color: white">' + params.data.status + '</span></button>'
            } else if (params.data.status === 'Cancelled') {
                return '<button type="button" class="el-button el-button--danger" style="width: 130px;"><span>' + params.data.status + '</span></button>'
            } else {
                return '<button type="button" class="el-button el-button--warning" style="width: 130px;"><span style="color: white">' + params.data.status + '</span></button>'
            }
        },
        clearData() {
            this.$bvModal.hide('create_appointment_modal');
        },
        createAppointment() {
            this.$validator.validate('branch', this.model.branch);
            this.$validator.validate('department', this.model.department);
            this.$validator.validate('doctor', this.model.doctor);
            this.$validator.validate('assigned_to', this.model.assigned_to);
            this.$validator.validate('date_field', this.model.date_field);
            this.$validator.validate('doctor_options', this.model.doctor_options);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['branch', 'department', 'doctor', 'assigned_to', 'date_field', 'doctor_options']);
                if (valid) {
                    this.$bvModal.hide('create_appointment_modal');
                    let assignee = []
                    let assignee_id = []
                    if (this.model.assigned_to) {
                        for (let i = 0; i < this.model.assigned_to.length; i++) {
                            assignee.push(this.model.assigned_to[i].name)
                            assignee_id.push(this.model.assigned_to[i].user_id)
                        }
                    }
                    if (!this.appointment) {
                        let request = {
                            branch: this.model.branch.title,
                            department: this.model.department.title,
                            doctor: this.model.doctor.full_name,
                            date_field: this.model.date_field,
                            doctor_options: this.model.doctor_options,
                            special_note: this.model.special_note ? this.model.special_note : '',
                            assigned_to: assignee.length ? assignee.toString() : [],
                            assignee_id: assignee_id.length ? assignee_id.toString() : [],
                            api: "/appointment/create",
                            json_object: true
                        }

                        this.$store.dispatch('postAction', request).then(response => {
                            if (response.code === 200) {
                                this.$store.state.appointmentCount++
                                this.$swal({
                                    icon: 'info',
                                    type: 'info',
                                    text: 'Successfully created',
                                    showCancelButton: false,
                                    confirmButtonText: 'OK',
                                });

                                let datasource = this.$parent.$parent.$parent.$parent.dataSource();
                                this.$parent.$parent.$parent.$parent.gridOptions.api.setServerSideDatasource(datasource);
                                this.model = {
                                    branch: '',
                                    department: '',
                                    doctor: '',
                                    date_field: '',
                                    assigned_to: '',
                                    doctor_options: '',
                                    special_note: '',
                                }
                            }
                        })
                    } else {
                        let request = {
                            date_field: this.model.date_field,
                            doctor_options: this.model.doctor_options,
                            special_note: this.model.special_note ? this.model.special_note : '',
                            assigned_to: assignee.length ? assignee.toString() : [],
                            assignee_id: assignee_id.length ? assignee_id.toString() : [],
                            api: "/appointment/" + this.model.id,
                            json_object: true
                        }
                        this.$store.dispatch('postAction', request).then(response => {
                            if (response.code === 200) {
                                this.$swal({
                                    icon: 'info',
                                    type: 'info',
                                    text: 'Successfully updated',
                                    showCancelButton: false,
                                    confirmButtonText: 'OK',
                                });

                                let datasource = this.$parent.$parent.$parent.$parent.dataSource();
                                this.$parent.$parent.$parent.$parent.gridOptions.api.setServerSideDatasource(datasource);
                                this.$forceUpdate()
                                this.$bvModal.hide('create_appointment_modal');
                                this.model = {
                                    branch: '',
                                    department: '',
                                    assigned_to: '',
                                    doctor: '',
                                    date_field: '',
                                    doctor_options: '',
                                    special_note: '',
                                }
                            }
                        })
                    }
                }
            });
        },
    },
    watch: {
        'model.department': function (val) {
            if (!this.appointment) {
                this.doctorList = []
                this.model.doctor = ''
                if (val) {
                    this.$store.dispatch('getAction', "/department/" + this.model.branch.id + '/' + this.model.department.id).then(response => {
                        if (response.code === 200) {
                            this.doctorList = response.data
                        }
                    })
                }
            }
        }
    }
}
</script>

