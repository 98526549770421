<template>
    <div :class="container_class+' icon-container'">
        <img :src="'/icons/'+icon+'.svg'" :style="size?'width:'+size+'px':''" :class="icon_class+' icon'">
    </div>
</template>

<script>
    export default {
        name: "icon",
        props: {
            size: {
                required: false,
                default: ''
            },
            icon_class: {
                required: false,
                default: ''
            },
            container_class: {
                required: false,
                default: ''
            },
            icon: {
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
