<template>
    <board :span="24" :span_responsive="[ 24, 24 ]" title="Users Management" height="auto" v-if="$store.state.permissions.view_team">
        <div class="col-md-12 margin-bottom-10 p-0" v-if="$store.state.permissions.add_team">
            <el-button @click="addNewUser" class="save-updates-button pull-right">
                {{ $root.translate('add_new_user') }}
            </el-button>
        </div>
        <div class="col-md-12 with-dropdown-filter-button">
            <div class="grid-filter-trigger" @click="filterTrigger">
                <i :class="floating_filter?'fas fa-filter':'far fa-filter'"></i>
            </div>
        </div>
        <ag-grid-vue :pagination="true"
                     :localeText="$store.state.translations"
                     :enableRtl="$store.state.ag_grid_rtl"
                     :grid-options="gridOptions"
                     rowHeight="60"
                     :floatingFilter="floating_filter"
                     :frameworkComponents="frameworkComponents"
                     :paginationPageSize="20"
                     class="ag-theme-material"
                     :columnDefs="columnDefs"
                     :rowData="users">
        </ag-grid-vue>

        <b-modal v-model="passwordModal" id="reset_password_modal_id" size="md" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4>{{ $root.translate('change_password_for') + ' ' + selectedUser.name }}</h4>
            </div>
            <div class="col-md-12 p-0">
                <resetPassword :user="selectedUser"></resetPassword>
            </div>
        </b-modal>
        <b-modal v-model="permissionModal" id="permission_modal_id" size="md" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ $root.translate('view_permission_for') + ' ' + selectedUser.name }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <permissions :user="selectedUser"></permissions>
            </div>
        </b-modal>
        <b-modal v-model="rolesModal" id="permission_modal_id" size="md" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ $root.translate('edit_roles_for') + ' ' + selectedUser.name }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <roles :user="selectedUser"></roles>
            </div>
        </b-modal>
        <b-modal v-model="userModal" id="register_modal_id" size="lg" hide-footer
                 ignore-enforce-focus-selector=".login-container">
            <div slot="modal-header">
                <h4> {{ $root.translate('create_user') }} </h4>
            </div>
            <div class="col-md-12 p-0">
                <register></register>
            </div>
        </b-modal>
    </board>
</template>

<script>
import board from "@/components/CommonComponents/Board";
import {AgGridVue} from "ag-grid-vue";
import optionsCell from '@/components/CommonComponents/agGrid/AgGridActionsCell.vue';
import register from "./register";
import resetPassword from "./ResetPassword";
import permissions from "@/components/permissions/viewPermissions"
import roles from "@/components/permissions/editRoles"
// import filterDatePicker from "@/components/CommonComponents/agGrid/AgGridDateFilter";

export default {
    data() {
        return {
            model: {},
            userModal: false,
            passwordModal: false,
            permissionModal: false,
            rolesModal: false,
            users: [],
            selectedUser: {},
            floating_filter: false,
            frameworkComponents: null,
            gridOptions: {
                defaultColDef: {
                    resizable: true
                },
                onGridSizeChanged(event) {
                    event.api.sizeColumnsToFit();
                }, components: {
                    'optionsCell': this.optionsCell,
                }
            },
            columnDefs: []
        }
    },
    mounted() {

        // this.frameworkComponents = {agDateInput: filterDatePicker};

        this.columnDefs = [
            {
                "headerName": "#",
                "width": 60,
                "cellRenderer": function (params) {
                    return params.rowIndex + 1;
                },
                "sortable": false,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('name'),
                "field": 'name',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.name
                },
                valueGetter: params => {
                    return params.data.name
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('email'),
                "field": 'email',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.email
                },
                valueGetter: params => {
                    return params.data.email
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('branch'),
                "field": 'branch',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.branch
                },
                valueGetter: params => {
                    return params.data.branch
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('role'),
                "field": 'role',
                filter: 'agTextColumnFilter',
                cellRenderer: function (params) {
                    let roles = params.data.role_info;
                    if (roles && roles.length > 0) {
                        let role = roles[0].name
                        for (let i = 1; i < roles.length; i++) {
                            role = role + ' & ' + roles[i].name
                        }
                        return role
                    }
                    return ''
                },
                filterValueGetter: params => {
                    let roles = params.data.role_info ? params.data.role_info : [];
                    if (roles && roles.length > 0) {
                        let role = roles[0].name
                        for (let i = 1; i < roles.length; i++) {
                            role = role + ' & ' + roles[i].name
                        }
                        return role
                    }
                    return ''
                },
                valueGetter: params => {
                    let roles = params.data.role_info ? params.data.role_info : [];
                    if (roles && roles.length > 0) {
                        let role = roles[0].name
                        for (let i = 1; i < roles.length; i++) {
                            role = role + ' & ' + roles[i].name
                        }
                        return role
                    }
                    return ''
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                "headerName": this.$root.translate('created_at'),
                "field": 'created_at',
                filter: 'agTextColumnFilter',
                filterValueGetter: params => {
                    return params.data.created_at
                },
                valueGetter: params => {
                    return params.data.created_at
                },
                "sortable": true,
                "suppressMenu": true
            },
            {
                headerName: this.$root.translate('action'),
                field: 'id',
                width: 100,
                cellRendererFramework: optionsCell,
                cellRendererParams: {
                    props: [
                        {
                            icon: 'fas fa-key',
                            value: 'view_permission',
                            type: 'action',
                            onClick: this.viewPermissionModal
                        }
                    ]
                },
                "suppressMenu": true
            }
        ]
        // edit_user_roles
        // change_password
        // alert(this.$store.state.permissions.edit_user_roles)
            this.columnDefs[6].cellRendererParams.props.push(
                {
                    icon: 'fas fa-question-circle',
                    value: 'change_password',
                    type: 'action',
                    onClick: this.viewPasswordModal
                })

            this.columnDefs[6].cellRendererParams.props.push(
                {
                    icon: 'fas fa-question-circle',
                    value: 'edit_roles',
                    type: 'action',
                    onClick: this.viewRolesModal
                })

        this.$store.dispatch('getAction', "/users").then(response => {
            if (response.code === 200) {
                this.users = response.data
            }
        })
    },
    components: {
        board,
        register,
        resetPassword,
        roles,
        permissions,
        AgGridVue
    },
    methods: {
        filterTrigger() {
            this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
            this.floating_filter = this.gridOptions.floatingFilter;
            this.gridOptions.api.refreshHeader();
            if (!this.floating_filter)
                this.gridOptions.api.setFilterModel(null);
        },
        viewPermissionModal(option, data, key, type) {
            // alert("innn")
            console.log(option, data, key, type)
            this.selectedUser = data
            this.permissionModal = true

        },
        viewPasswordModal(option, data, key, type) {
            // alert("innn")
            console.log(option, data, key, type)

            this.selectedUser = data
            this.passwordModal = true
        },
        viewRolesModal(option, data, key, type) {
            // alert("innn")
            console.log(option, data, key, type)

            this.selectedUser = data
            this.rolesModal = true
        },

        addNewUser() {
            this.userModal = true
        },


    }
}
</script>