import store from '@/store.js';
import Vue from "vue";

export default {
    authGuard(to, from, next) {
        // special routes here
        let specialRoutes = [

        ];

        let specialRoute = specialRoutes.indexOf(to.path) != -1;

        if (!Vue.$cookies.isKey("token") && !specialRoute) {
            store.dispatch('setRedirectToAfterLogin', {
                "path": to.path
            });

            next('login');
        } else {
            next(); // continue
        }
    },
    redirectIfLoggedIn(to, from, next) {
        if (Vue.$cookies.isKey("token")) {
            next('/');
        }
        else {
            next();
        }
    }
};
