import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.config.devtools = true;
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ElementUI from 'element-ui';
import '@/assets/css/element-ui/lib/theme-euler/theme/index.css';
import locale from "./assets/css/element-ui/lib/locale/lang/en";

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect)
Vue.use(ElementUI, {locale});
Vue.use(Vuex);
import icon from '@/components/CommonComponents/icon';
Vue.use(VueSweetalert2);
import 'ag-grid-enterprise';

Vue.component('icon', icon);
Vue.prototype.$eventHub = new Vue();

require('@/assets/bootstrap/dist/css/bootstrap.min.css');
require('@/assets/css/style-en.css');
require('@/assets/layout/css/layout.min.css');
require('@/assets/css/custom.min.css');
require('@/assets/css/themes/blue.min.css');
require('@/assets/css/themes/darkblue.min.css');

require('@/assets/fontawesome/on-server/css/fontawesome-all.min.css');
require('vue-multiselect/dist/vue-multiselect.min.css');
require('@/assets/css/manage.css');
require('@/assets/css/euler-style.scss');

import Pusher from 'pusher-js';
// Enable pusher logging - don't include this in production
// Pusher.logToConsole = true;
// alert("inn")
var pusher = new Pusher('8312247facde373fe518', {
  cluster: 'eu'
});



new Vue({
  router,
  store,
  render: h => h(App),
  created(){
    this.setPermissions()
    window.serviceEvent = new Vue();
    let user=Vue.$cookies.get('user');
    let channel = pusher.subscribe('my-channel'+user.id);
    let that=this
    channel.bind('my-event', function(data) {
      that.$store.state.notifications.unshift(data.message)
      const h = that.$createElement;

      that.$notify({
        title: data.message.title,
        message: h('i', { style: 'color: teal' }, data.message.message)
      });
    })
  },
  methods:{
    getJsonFile(file) {
      try {
        return require('../src/assets/json/' + file + '.json');
      } catch (error) {
        return [];
      }
    },
    setPermissions(){
      let permissions = JSON.parse(localStorage.getItem('permission'))
      for (let i = 0; i < permissions.length; i++) {
        this.$store.state.permissions[permissions[i].slug] = true
      }
    },
    translate(value) {
      if(!this.$store.state.translations[value]){
        console.log(value)
        return value
      }
      return this.$store.state.translations[value];
    },
    checkErrors(errors, keys) {
      for (let value of errors.items) {
        if (keys.includes(value.field)) {
          return false;
        }
      }
      return true;
    },
    getDomain() {
      let hostname = window.location.hostname;
      if (this.isIP(hostname)) {
        return hostname;
      } else {
        let domain = hostname.replace(/(https?:\/\/)?(www.)?/i, '');
        domain = domain.split('.');
        domain = domain.slice(domain.length - 2).join('.');
        if (domain.indexOf('/') !== -1) {
          return domain.split('/')[0];
        }
        return domain;
      }
    },
    isIP(address) {
      let r = RegExp('((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])');
      return r.test(address)
    },
    getObjectByKey(key,search,array){
      if(array.length){
        for(let i=0;i<array.length;i++){
          if(key){

            if(array[i][key]===search){
              return array[i]
            }
          }else {
              if (array[i] === search) {
                  return array[i]
              }
          }
        }
      }
      return search
    }
  }
}).$mount('#app')
