<template>
  <div :class="'button has-dropdown success '+classes">
    {{ title  }}
    <div class="dropdown">
      <slot></slot>
    </div>
  </div>
</template>
<script>

  export default {
    methods: {
    },
    props:{
      title:{
        type: String,
        default : "please set the title prop"
      },
      classes:{
        type: String,
        default : ""
      }
    },
    computed: {}
  }
</script>
<style lang="scss" scoped>
</style>
