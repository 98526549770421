<template>

    <div class="col-md-12 p-0">
        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Title :</label>
                <div class="col-md-8">{{ selectedTask.title }}</div>
            </div>
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Due Date :</label>
                <div class="col-md-8">{{ selectedTask.due_date }}</div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Applicant :</label>
                <div class="col-md-8">{{ selectedTask.applicant }}</div>
            </div>
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Assignee :</label>
                <div class="col-md-8">{{ selectedTask.assignee ? selectedTask.assignee.toString() : '' }}</div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Description :</label>
                <div class="col-md-8">{{ selectedTask.description }}</div>
            </div>
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Priority :</label>
                <div class="col-md-8">{{ selectedTask.priority }}</div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Status :</label>
                <div class="col-md-8">{{ selectedTask.status }}</div>
            </div>
        </div>
        <div class="col-md-12">
            <el-button class="clear-button"
                       @click="closeModel"> Close
            </el-button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedTask: {required: true}
    },
    methods: {
        closeModel() {
            this.$bvModal.hide('view_task_modal');
            this.$bvModal.hide('view_task_modal_home');
            this.$bvModal.hide('view_task_modal_top');
        }
    }
}
</script>