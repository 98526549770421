<template>
    <div class="col-md-12 p-0">
        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Branch :</label>
                <div class="col-md-8">{{ selectedRequest.branch }}</div>
            </div>
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Applicant :</label>
                <div class="col-md-8">
                    {{ selectedRequest.applicant }}
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Doctor :</label>
                <div class="col-md-8">
                    {{ selectedRequest.doctor_name }}
                </div>
            </div>

            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label"> Type :</label>
                <div class="col-md-8">
                    {{ selectedRequest.type }}
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label"> Assigned To :</label>
                <div class="col-md-8">
                    {{ selectedRequest.assigned_to }}
                </div>
            </div>

            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">License :</label>
                <div class="col-md-8">
                    <a v-for="(license,key) in selectedRequest.license" :key="key"
                       :href="$store.state.SERVER_URL + '/'+license.saved_as.split('public/')[1]" download
                       target="_blank">{{ license.initial_name }}</a>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-md-12">
                <label class="col-md-2 col-form-label title-label"> Description :</label>
                <div class="col-md-10">{{ selectedRequest.description }}
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Creation Date :</label>
                <div class="col-md-8">
                    {{ selectedRequest.created_at }}
                </div>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Status :</label>
                <div class="col-md-8">
                    <button v-if="selectedRequest.request_status==='Executed'" class="el-button el-button--success"
                            type="button"><span
                        style="color: white">{{ selectedRequest.request_status }}</span></button>
                    <button v-else-if="selectedRequest.request_status==='Cancelled'" class="el-button el-button--danger"
                            type="button"><span
                        style="color: white">{{ selectedRequest.request_status }}</span></button>
                    <button v-else class="el-button el-button--warning" type="button"><span
                        style="color: white">{{ selectedRequest.request_status }}</span></button>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div>
                <el-button class="clear-button" @click="closeModel"> Close
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedRequest: {required: false},
    },
    methods: {
        closeModel() {
            this.$bvModal.hide('view_request_modal');
            this.$bvModal.hide('view_request_modal_home');
            this.$bvModal.hide('view_request_modal_top');
        }
    }
}
</script>