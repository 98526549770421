<template>
    <div>
        <div class="form-group" v-if="with_verification" :class="{'has-error': errors.has('verification_code')}">
            <label class="col-md-4 form-labels"> Verification Code </label>
            <div class="col-md-12">
                <div class="col-md-12 p-0">
                    <el-input name="verification_code" v-model="model.verification_code"
                              v-validate="'required'"></el-input>
                    <span class="help-block" v-if="errors.has('verification_code')">
                        {{ $root.translate('this_field_is_required') }}
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group" :class="{'has-error': errors.has('new_password')}">
            <label class="col-md-12 form-labels"> Password </label>
            <div class="col-md-12">
                <div class="col-md-12 p-0">
                    <el-input name="new_password" type="password" v-model="model.new_password"
                              v-validate="'required|min:8'" ref="passwordRef"></el-input>
                    <span class="help-block" v-if="errors.has('new_password')">
                        {{
                            model.new_password === '' ? $root.translate('this_field_is_required') : 'Password must be at least 8 characters'
                        }}
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group" :class="{'has-error': errors.has('confirm_password')}">
            <label class="col-md-12 form-labels"> Confirm Password </label>
            <div class="col-md-12">
                <div class="col-md-12 p-0">
                    <el-input name="confirm_password" type="password" v-model="model.confirm_password"
                              v-validate="'required|confirmed:passwordRef'"></el-input>
                    <span class="help-block" v-if="errors.has('confirm_password')">
                        {{
                            model.confirm_password === '' ? $root.translate('this_field_is_required') : "Confirm password doesn't match password"
                        }}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <el-button class="save-updates-button pull-right" type="default" @click="resetPassword">
                Change Password
            </el-button>
        </div>
    </div>
</template>

<script type="text/javascript">
import Vue from 'vue';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
    fieldsBagName: 'veeFields'
});
export default {
    props: {
        user: {
            required: false
        },
        with_verification: {
            required: false
        }
    },
    data() {
        return {
            model: {
                new_password: "",
                confirm_password: ""
            }
        }
    },
    created() {
        // if (!this.$store.state.allowResetPasswordPage) {
        //     window.location = "/";
        // } else {
        //     this.allowed = true
        // }
    },
    methods: {
        resetPassword() {
            if (this.with_verification) {
                this.$validator.validate('verification_code', this.model.verification_code);
            }
            this.$validator.validate('new_password', this.model.new_password);
            this.$validator.validate('confirm_password', this.model.confirm_password);
            this.$nextTick(() => {
                let valid = this.$root.checkErrors(this.errors, ['new_password', 'confirm_password']);
                if (valid) {
                    let user = {}
                    if (this.user) {
                        user = this.user;
                    } else {
                        user = Vue.$cookies.get("user");
                    }
                    let request = {
                        password: this.model.new_password,
                        user: user.id,
                        api: '/reset-password',
                        json_object: true
                    };
                    if (this.with_verification) {
                        request.verification_code = this.model.verification_code
                    }
                    this.$store.dispatch('postAction', request).then(response => {
                        if (response.code === 200) {
                            this.$swal({
                                icon: 'info',
                                type: 'info',
                                text: 'Password changed',
                                showCancelButton: false,
                                confirmButtonText: 'OK',
                            });
                            this.$store.state.allowResetPasswordPage = false
                            this.$bvModal.hide('reset_password_modal_id');
                        }
                    }).catch(() => {
                        this.$swal.fire({
                            icon: 'error',
                            type: 'error',
                            title: '',
                            text: "Wrong Verification Code",
                            allowOutsideClick: true,
                            allowEscapeKey: true
                        });
                    })
                }
            })
        }
    }
}
</script>
