<template>
    <div id="app" ref="printMe" class="col-md-12">
        <div v-if="$store.state.login_modal && $route.name!=='login' && $route.name!=='login'"
             class="login-opacity-background">
            <board :span="6"
                   :span_responsive="[ 6, 24 ]"
                   height="300px">
                <login :background="false"></login>
            </board>
        </div>

        <top-menu v-if="$route.fullPath !=='/login' && $route.name !=='create-patient'"></top-menu>
        <side-menu v-if="$route.fullPath !=='/login'" class="dashboard-sidemenu collapsed"></side-menu>

        <div id="content" :class="{'main-content' :$route.fullPath !=='/login' && $route.name !=='create-patient'}">
            <div class="container">
                <div class="row layout-spacing ">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Login from "@/components/user/login";
import board from "@/components/CommonComponents/Board";
import VueCookies from 'vue-cookies';
import BootstrapVue from 'bootstrap-vue'
import topMenu from '@/components/navigation/TopMenu.vue';
import sideMenu from '@/components/navigation/SideMenu.vue';

Vue.use(VueCookies);
Vue.use(BootstrapVue);
export default {
    data: function () {
        return {
            title: '',
            page: this.$route.query.page,
            filters: []
        }
    },
    mounted() {
        this.$store.state.domain = this.$root.getDomain();
        this.$store.dispatch('getAction', "/notification").then(response => {
            console.log(response)
            if (response.code === 200) {
                this.$store.state.notifications=response.data
            }
        })
        this.$store.dispatch('getAction', "/count").then(response => {
            if (response.code === 200) {
                this.$store.state.announcementsCount=response.data.announcements
                this.$store.state.tasksCount=response.data.tasks
                this.$store.state.requestsCount=response.data.requests
                this.$store.state.appointmentCount=response.data.appointments
            }
        })
    },
    components: {
        Login,
        topMenu,
        board,
        sideMenu,
    }
};
</script>
<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
@import "@/assets/css/colors.scss";

#content, #app {
    padding: 0px !important;
    overflow-x: hidden !important;
}

#sidebar {
    background-color: #2b3643;
}

#sidebar .heading {
    color: $color-white;
    margin-top: -5px;
}

#main {
    width: 75%;
}

.page-bar {
    margin: 0px !important;
    border-bottom: 1px solid #e7ecf1;
}
.ag-theme-material{
    overflow: hidden !important;
}

.login-opacity-background {
    //background-color: rgba($color-white, 0.6);
    width: 100%;
    display: inline-block;
    position: absolute;
    height: 100%;
    z-index: 50;

    .board {
        float: none;
        margin: 0 auto;
        //background-image: url("/images/login.jpg");
        background-size: cover;
    }

    .board::after {
        content: none;
    }
}

div#app div.dashboard-content.collapsed {
    padding-left: 125px;
    margin-left: 0px;
}

div#app .dashboard-content {
    padding-left: 280px;
    margin-left: 0px;
    margin-top: 0px;
}


.additional-child {
    position: absolute;
    left: 265px;
    background-color: #012c43;
    margin-top: -49px;
}

.collapsed .additional-child {
    position: relative;
    left: 0px;
    margin-top: 0px;
}

.dashboard-header {
    display: contents
}

#content > .container {
    padding: 0 30px;
    //width: 100vh;
    max-width: none !important;
}


.swal2-html-container {
    text-align: center !important;
}
</style>
