<template>
    <div class="sidemenu-container" :class="{ collapsed: $store.state.side_menu_collapse }" style="">

        <div class="modernSidebar-nav header navbar">
            <div class="">
                <nav id="modernSidebar">
                    <ul class="menu-categories pl-0 m-0" id="topAccordion">
                        <li class="menu">
                            <a href="javascript:;" @click="$router.push('/')" data-toggle="collapse"
                               aria-expanded="false" class="dropdown-toggle">
                                <div class="" style="text-align: center">
                                    <img src="/icons/home.svg" class="icon">
                                    <span>Home</span>
                                </div>
                            </a>
                        </li>
                        <li class="menu" v-if="$store.state.permissions.view_appointment">
                            <a href="javascript:;" @click="$router.push('/appointment')" data-toggle="collapse"
                               aria-expanded="false" class="dropdown-toggle">
                                <el-badge :value="$store.state.appointmentCount" class="item" type="danger">
                                    <div style="text-align: center">
                                        <img src="/icons/appointment.svg" class="icon">
                                        <span>Appointment</span>
                                    </div>
                                </el-badge>
                            </a>
                        </li>
                        <li class="menu" v-if="$store.state.permissions.view_requests">
                            <a href="javascript:;" @click="$router.push('/requests')" data-toggle="collapse"
                               aria-expanded="false" class="dropdown-toggle">
                                <el-badge :value="$store.state.requestsCount" class="item" type="danger">
                                    <div class="" style="text-align: center">
                                        <img src="/icons/request.svg" class="icon">
                                        <span>Requests</span>
                                    </div>
                                </el-badge>
                            </a>
                        </li>
                        <li class="menu" v-if="$store.state.permissions.view_announcements">
                            <a href="javascript:;" @click="$router.push('/announcements')" data-toggle="collapse"
                               aria-expanded="false" class="dropdown-toggle">
                                <el-badge :value="$store.state.announcementsCount" class="item">
                                    <div class="" style="text-align: center">
                                        <img src="/icons/announcement.svg" class="icon">
                                        <span>Announcements</span>
                                    </div>
                                </el-badge>
                            </a>
                        </li>
                        <li class="menu" v-if="$store.state.permissions.view_tasks">
                            <a href="javascript:;" @click="$router.push('/tasks')" data-toggle="collapse"
                               aria-expanded="false" class="dropdown-toggle">
                                <el-badge :value="$store.state.tasksCount" class="item" type="danger">
                                    <div class="" style="text-align: center">
                                        <img src="/icons/tasks.svg" class="icon">
                                        <span>Tasks</span>
                                    </div>
                                </el-badge>
                            </a>
                        </li>
                        <li class="menu" v-if="$store.state.permissions.view_doctors">
                            <a href="javascript:;" @click="$router.push('/doctors')" data-toggle="collapse"
                               aria-expanded="false"
                               class="dropdown-toggle">
                                <div class="" style="text-align: center">
                                    <img src="/icons/doctors.svg" class="icon">
                                    <span>Doctors</span>
                                </div>
                            </a>
                        </li>
                        <li class="menu" v-if="$store.state.permissions.view_branches">
                            <a href="javascript:;" @click="$router.push('/branches')" data-toggle="collapse"
                               aria-expanded="false"
                               class="dropdown-toggle">
                                <div class="" style="text-align: center">
                                    <img src="/icons/branch.svg" class="icon">
                                    <span>Branches</span>
                                </div>
                            </a>
                        </li>
                        <li class="menu" v-if="$store.state.permissions.view_departments">
                            <a href="javascript:;" @click="$router.push('/departments')" data-toggle="collapse"
                               aria-expanded="false"
                               class="dropdown-toggle">
                                <div class="" style="text-align: center">
                                    <img src="/icons/department.svg" class="icon">
                                    <span>Departments</span>
                                </div>
                            </a>
                        </li>
                        <li class="menu" v-if="$store.state.permissions.view_team">
                            <a href="javascript:;" @click="$router.push('/users')" data-toggle="collapse"
                               aria-expanded="false"
                               class="dropdown-toggle">
                                <div class="" style="text-align: center">
                                    <img src="/icons/team.svg" class="icon">
                                    <span>Team</span>
                                </div>
                            </a>
                        </li>
                        <li class="menu" v-if="$store.state.permissions.view_roles_page">
                            <a href="javascript:;" @click="$router.push('/roles')" data-toggle="collapse"
                               aria-expanded="false"
                               class="dropdown-toggle">
                                <div class="" style="text-align: center">
                                    <img src="/icons/team.svg" class="icon">
                                    <span>Roles</span>
                                </div>
                            </a>
                        </li>

                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

#modernSidebar{
  overflow-y: auto !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.el-badge{
    .el-badge__content{
        padding-right: 8px !important;
    }
}
.el-badge__content--danger{
  padding: 0px !important;
}
.logo-container {
    width: 100%;
    text-align: center;
    margin-top: 45px;

    .logo {
        width: 150px;
        margin: auto;
    }

    .version {
        font-size: 13px;
        margin-top: 10px;
        color: #bbbcbd;
    }
}

.sidemenu-container {
    &.collapsed {
        .logo-container {
            overflow: hidden;
            width: 32px;
            margin: 150px auto 0px;
        }

        .version {
            display: none;
        }
    }
}
</style>
<script>
// import treeMenu from '@/components/CommonComponents/Menu/TreeMenu.vue';
import ClickOutside from 'vue-click-outside';

export default {
    data() {
        return {
            menu: [],
            opened_sub_menus: [],
            userPermissions: {},
            permissionsLoaded: false
        };
    },
    components: {
        // treeMenu
    },
    mounted() {
        this.menu = this.$root.getJsonFile('menu');
    },
    methods: {
        openApplication(url) {
            window.open(url, '_blank')
        },
        handleToggleMenu() {
            this.$store.state.side_menu_collapse = !this.$store.state.side_menu_collapse;
            this.$forceUpdate();
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        closeMenu() {
            for (var i = 1; i < this.opened_sub_menus.length; i++)
                this.$refs.menu.close(this.opened_sub_menus[i]);
        }
    },
    directives: {
        ClickOutside
    }
}
</script>
