<template>
    <div class="board masonry-selector"
         :style="cssProps"
         :class="[
             title ? 'with-title' : 'without-title',
             nopadding ? 'without-padding' : 'with-padding',
             classes ? classes : ''
         ]">
        <h1 class="title" v-if="title">{{ title }}</h1>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'board',
        data() {
            return {
                width: 100
            }
        },
        methods: {
            calculateWidth(span, span_responsive) {
                if (span_responsive.constructor === Array) {
                    return [(span / 24) * 100, (span_responsive[0] / 24) * 100, (span_responsive[1] / 24) * 100];
                } else {
                    return [(span / 24) * 100, (span / 24) * 100, (span / 24) * 100];
                }
            },
            calculateAlternativeWidth(span,) {
                if ((span / 24) * 100 * 2 > 100) {
                    return 100;
                } else {
                    return (span / 24) * 100 * 2
                }
            },
            calculateHeight(height) {
                if (height === 'auto') {
                    return 'unset'
                } else {
                    return height + 'px'
                }
            }
        },
        props: {
            span: {
                type: Number,
                default: 8
            },
            span_responsive: {
                type: Array,
                default: () => ([])
            },
            title: {
                type: String,
                default: ''
            },
            height: {
                type: String,
                default: '100'
            },
            nopadding: {
                type: Boolean,
                default: false
            },
            classes: {
                type: String,
                default: ''
            },
            appointment_icon: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            cssProps() {
                return {
                    '--width': this.calculateWidth(this.span, this.span_responsive)[0] + '%',
                    '--width-md': this.calculateWidth(this.span, this.span_responsive)[1] + '%',
                    '--width-sm': this.calculateWidth(this.span, this.span_responsive)[2] + '%',
                    '--height': this.calculateHeight(this.height),
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/css/colors.scss";

    .board {
        width: calc(var(--width));
        height: var(--height);

        h1 {
            color: $color-blue-1;
            font-weight: bold;
        }

        .content {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            position: relative
        }

        h1.title {
            width: 100%;
            display: block;
            padding: 0 20px;
            margin: 0;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #eaeaea;
            position: relative;
            font-weight: bold;
            font-size: 16px
        }

        @media #{$mediaquery-small} {
            width: calc(var(--width-md) - 20px)
        }
        @media #{$mediaquery-smaller} {
            width: calc(var(--width-sm) - 20px)
        }
        @media #{$mediaquery-mobile} {
            width: calc(100% - 20px);
        }
    }
</style>

