<template>
    <div class="col-md-12 p-0">
        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Title :</label>
                <div class="col-md-8">
                    {{ selectedAnnouncement.title }}
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Applicant :</label>
                <div class="col-md-8">
                    {{ selectedAnnouncement.applicant }}
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Add Doctor :</label>
                <div class="col-md-8">
                    {{ selectedAnnouncement.doctor_name }}
                </div>
            </div>

            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label"> Expired In :</label>
                <div class="col-md-8">
                    {{ selectedAnnouncement.expired_in }}
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-12">
                <label class="col-md-2 col-form-label title-label"> Description :</label>
                <div class="col-md-10">
                    {{ selectedAnnouncement.description }}
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-6">
                <label class="col-md-4 col-form-label title-label">Creation Date :</label>
                <div class="col-md-8">
                    {{ selectedAnnouncement.created_at }}
                </div>
            </div>
            <div class="col-md-6 p-0">
                <label class="col-md-4 col-form-label title-label">Status :</label>
                <div class="col-md-8">
                    <button v-if="selectedAnnouncement.status==='Executed'" type="button"
                            class="el-button el-button--success"><span
                        style="color: white">{{ selectedAnnouncement.status }}</span></button>
                    <button v-else-if="selectedAnnouncement.status==='Cancelled'" type="button"
                            class="el-button el-button--danger"><span
                        style="color: white">{{ selectedAnnouncement.status }}</span></button>
                    <button v-else type="button" class="el-button el-button--warning"><span
                        style="color: white">{{ selectedAnnouncement.status }}</span></button>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <el-button class="clear-button"
                       @click="closeModel"> Close
            </el-button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedAnnouncement: {required: true}
    },
    methods: {
        closeModel() {
            this.$bvModal.hide('view_announcement_modal');
            this.$bvModal.hide('view_announcement_modal_home');
            this.$bvModal.hide('view_announcement_modal_top');
        }
    }
}
</script>