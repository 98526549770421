<template>
    <div class="col-md-12 p-0">
        <el-tree
            show-checkbox
            v-if="refreshTree"
            node-key="id"
            ref="tree"
            :data="roles"
            :props="defaultProps"
            :default-checked-keys="selected"
            @check-change="handleCheckChange">
        </el-tree>
        <div class="col-md-12">
            <el-button class="clear-button" @click="close">
                {{ $root.translate('close') }}</el-button>
            <el-button class="save-updates-button pull-right" @click="save">
                {{ $root.translate('save') }}</el-button>
        </div>
    </div>
</template>
<script>
import Vue from "vue";

export default {
    props: {
        user: {
            required: false
        }
    },
    data() {
        return {
            refreshTree: true,
            // data: [],
            defaultProps: {
                children: 'children',
                label: 'name',
                disabled: 'disabled',
            },
            roles: [],
            selected: []
        };
    },
    mounted() {
        let user = {}
        if (this.user) {
            user = this.user
        } else {
            user = Vue.$cookies.get('user');
        }
        let that = this
        if (user.role_info) {
            user.role_info.forEach((role) => {
                that.selected.push(role.id)
            })
        }
        let roles = JSON.parse(Vue.$cookies.get('roles-list'))
        if (!roles) {
            this.$store.dispatch('getAction', "/roles").then(response => {
                console.log(response)
                if (response.code === 200) {
                    this.roles = response.data
                    Vue.$cookies.set('roles-list', JSON.stringify(response.data))
                }
            })
        } else {
            this.roles = roles
        }

    },
    methods: {
        handleCheckChange(data, checked, indeterminate) {
            console.log(data, checked, indeterminate);
        },
        close() {
            this.$bvModal.hide('permission_modal_id');
        },
        save() {
            let user = {}
            if (this.user) {
                user = this.user
            } else {
                user = Vue.$cookies.get('user');
            }
            let selected = this.$refs.tree.getCheckedNodes()
            let roles = []
            for (let i = 0; i < selected.length; i++) {
                roles.push(selected[i].id)
            }
            let id = user.user_id ? user.user_id : user.id
            let roles_request = {
                roles: roles,
                api: '/user/' + id + '/roles',
                json_object: true
            };
            this.$store.dispatch('postAction', roles_request).then(response => {
                if (response.code === 200) {
                    this.$parent.$parent.$parent.$parent.users.forEach(usr => {
                        if (usr.user_id === user.user_id) {
                            usr.role_info = selected
                        }
                    })
                    this.$parent.$parent.$parent.$parent.gridOptions.api.redrawRows();
                    this.$forceUpdate()
                    this.$bvModal.hide('permission_modal_id');
                }
            });
            // this.$store.dispatch('getAction', "/roles").then(response => {
            //     console.log(response)
            //     if (response.code === 200) {
            //         this.roles = response.data
            //
            //     }
            // })
        }
    }
};
</script>