<template>
    <div>
    </div>
</template>

<script type="text/javascript">
import Vue from 'vue';

export default {
    name: 'logout',
    data() {
        return {}
    },
    mounted() {
        let cookies = Vue.$cookies.keys();
        cookies.forEach(value => {
            Vue.$cookies.remove(value, null, this.$store.state.domain);
        });
        this.$router.push({"name": "login"}).catch((err) => {
            throw new Error(`Problem handling something:${err}.`);
        });
    }
}
</script>
